export const LocalStorageService = {
    setDepartureChange: async (ap_id, ap_name) => {
        var departureChangeObj = { ap_id: ap_id, ap_name: ap_name };
        localStorage.setItem(
            "departureChange",
            JSON.stringify(departureChangeObj)
        );
    },
    getDepartureChange: async () => {
        return localStorage.getItem("departureChange");
    },
}

