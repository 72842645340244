//Add
export const CheckWarning = {
    wranningMedicalCheckup: (MedicalCheckupDayLeft) => {
        if (MedicalCheckupDayLeft != null) {
            if (MedicalCheckupDayLeft <= 90 && MedicalCheckupDayLeft >= 1) {
                var MedicalCheckupWranning =
                    "MedicalCheckup Less than " + MedicalCheckupDayLeft + " days.";

                return MedicalCheckupWranning;
            } else if (MedicalCheckupDayLeft <= 0) {
                var MedicalCheckupExpired = "MedicalCheckup Expired";

                return MedicalCheckupExpired;
            }
        } else if (MedicalCheckupDayLeft == null) {
            var noMedicalCheckup = "No MedicalCheckup Date";

            return noMedicalCheckup;
        }
    },
    wranningSafetyTraining: (SafetyTrainingDayLeft) => {
        if (SafetyTrainingDayLeft != null) {
            if (SafetyTrainingDayLeft <= 90 && SafetyTrainingDayLeft >= 1) {
                var SafetyTrainingWranning =
                    "SafetyTraining Less than " + SafetyTrainingDayLeft + " days.";

                return SafetyTrainingWranning;
            } else if (SafetyTrainingDayLeft <= 0) {
                var SafetyTrainingExpired = "SafetyTraining Expired";

                return SafetyTrainingExpired;
            }
        } else if (SafetyTrainingDayLeft == null) {
            var noSafetyTraining = "No SafetyTraining Date";

            return noSafetyTraining;
        }
    }
}