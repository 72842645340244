<template>
  <div class="TF B-manubar">
    <div class="B-profile">
      <div class="B-company">
        <div class="tag company">
          <!-- <span>{{userdata.c_name}}</span> -->
          <!-- Edit -->
          <span>{{ userdata.o_name }}</span>
        </div>
      </div>
      <div class="G-profile" @click="goToUserInfo()">
        <div class="B-display" :style="{
            backgroundImage: 'url(' + userdata.u_imageurl + ') !important',
          }"></div>
        <div class="B-display-name">
          <div class="T-size-20">{{ userdata.FullName }}</div>
          <div class="T-size-14">{{ userdata.r_name }}</div>
        </div>
      </div>
    </div>

    <div class="B-manu">
      <MenuList />
    </div>

    <div class="B-logout">
      <div class="line"></div>
      <div class="B-btn">
        <v-btn text class="ColorTheme-S white--text center btn-size-full theme-btn" @click="Logout()">
          <span class="I-logout"></span>
          <span>logout</span>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { addHours, format } from "date-fns";
import feathersClientUOA from "../plugins/feathers-client-uoa";
import MenuList from "./MenuList";
export default {
  name: "MenuBar",
  data: () => ({
    userdata: { o_name: "", r_id: "", cb: "" },
  }),
  components: {
    MenuList,
  },
  async mounted() {
    this.userdata = JSON.parse(localStorage.getItem("user"));
    //alert(JSON.stringify(this.userdata.u_imageurl));
    //Edit
    this.userdata = await this.$CurrentUser.GetCurrentUser();
  },
  methods: {
    goToUserInfo() {
      this.$router.push({ path: "/userinfo" });
    },
    gotoprofile(Id) {
      this.$router.push({ path: "/userprofile" });
    },
    Logout() {
      try {
        feathersClientUOA.logout();
        //SEQ LOG
        this.$log.Info('{action} by {username} ({role}) :', { action : "LogoutByMenuBar", username: this.userdata.u_username, role:this.userdata.r_name });

        localStorage.removeItem("user");
        // this.$router.push({ path: "/login" });
        if (this.userdata.cb == "OAuth2 Microsoft") {
          window.location.replace("https://login.microsoftonline.com/logout.srf");
        } else {
          window.location.replace("/login.html");
        }
      } catch (error) {
        //SEQ LOG
        this.$log.Error("LogoutByMenuBar, Error : {error}", { error });
        console.log("LogoutByMenuBar, Error : {error}" + error);
      }
    },
    async ResetPassword() {
      await feathersClientUOA.service("account").patch(92, { u_password: "P@ssw0rd" });
      alert("Reset Password Complete")
    }
  },
};
</script>

<style>
.B-display {
  background-image: url("../../public/img/user.png");
}
</style>