import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import VueSignaturePad from 'vue-signature-pad';
 
Vue.use(VueSignaturePad);

Vue.use(Vuetify);



export default new Vuetify({
});
