import axios from 'axios';

export const ProdigyFlyService = {
    GetPilotShift: async (month, year) => {
        return await axios.get('https://shiftuoa.theprodigygroup.co.th/api/Pilot/GetShift?month=' + month + '&year=' + year);
    },
    GetEngineerShift: async (month, year) => {
        return await axios.get('https://shiftuoa.theprodigygroup.co.th/api/Engineer/GetShift?month=' + month + '&year=' + year);
    },
    GetTraningExpireDate: async () => {
        return await axios.get('https://shiftuoa.theprodigygroup.co.th/api/Pilot/GetpilotExpireCertificatedate');
    },
    GetAlcoholPilot: async (code, Departuretime, depart_time) => {
        return await axios.get('https://shiftuoa.theprodigygroup.co.th/api/Pilot/AlcoholcheckingByPersonCode?PersonCode=' + code + '&Departuretime=' + Departuretime + ' ' + depart_time);
    },
    GetAlcoholAllDepartment: async (date) => {
        return await axios.get('https://shiftuoa.theprodigygroup.co.th/api/Pilot/GetAlcoholCheck?ShiftDate=' + date);
    }
}