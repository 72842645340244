import feathersClientUOA from "../plugins/feathers-client-uoa";
import { LogoService } from "./LogoService";
export const Monthlyreport = {
        getPdf: async (month, year, oid, monthname, ap_id, FullName) => {
                var res = []
                const q = {};
                q.month = month;
                q.year = year;
                q.customerid = oid;
                q.ap_id = ap_id
                var return_main
                var return_back
                var return_main_total
                var return_back_total
                if (oid == 1) //"UOA"
                {
                        var res = await feathersClientUOA
                                .service("monthly")
                                .find({ query: q });
                        return_main = await Monthlyreport.getdata(res, month, year, oid);
                        return_main_total = await Monthlyreport.gettotaldata(res);

                        var res_backup = await feathersClientUOA
                                .service("montylycpocbackup")
                                .find({ query: q });
                        return_back = await Monthlyreport.getdata(res_backup, month, year, oid);
                        return_back_total = await Monthlyreport.gettotaldata(res_backup);
                        await Monthlyreport.CreateOldPdf(month, monthname, year, oid, return_main, return_main_total, return_back, return_back_total, FullName)

                }
                if (oid == 2) //"CPOC"
                {
                        var res = await feathersClientUOA
                                .service("monthly")
                                .find({ query: q });
                        return_main = await Monthlyreport.getdataCpoc(res, month, year, true);
                        return_main_total = await Monthlyreport.gettotaldataCpoc(res);
                        var res_backup = await feathersClientUOA
                                .service("montylycpocbackup")
                                .find({ query: q });
                        return_back = await Monthlyreport.getdataCpoc(res_backup, month, year, false);
                        return_back_total = await Monthlyreport.gettotaldataCpoc(res_backup);
                        var uoaimage = await LogoService.GetLogoBaseurl64(1);
                        var customerlogo = await LogoService.GetLogoBaseurl64(oid);
                        var headerpagemain = [
                                [{
                                        image: uoaimage.logo,
                                        fit: [30, 30],
                                        margin: [50, 0, 0, 0],
                                        border: [false, false, false, false],
                                        colSpan: 3
                                },
                                {
                                        text: '',
                                        style: 'textfooter'
                                },
                                {
                                        text: '',
                                        style: 'textfooter'
                                },
                                {
                                        text: '\nUOA-CPOC Monthly Report in ' + monthname + ', ' + year + ' (HS-UOL)',
                                        margin: [0, 0, 0, 0],
                                        alignment: 'center',
                                        fontSize: 12,
                                        border: [true, true, true, true],
                                        colSpan: 16
                                },
                                {
                                        text: '',
                                        style: 'textfooter'
                                },
                                {
                                        text: '',
                                        style: 'textfooter'
                                },
                                {
                                        text: '',
                                        style: 'textfooter'
                                },
                                {
                                        text: '',
                                        style: 'textfooter'
                                },
                                {
                                        text: '',
                                        style: 'textfooter'
                                },
                                {
                                        text: '',
                                        style: 'textfooter'
                                },
                                {
                                        text: '',
                                        style: 'textfooter'
                                },
                                {
                                        text: '',
                                        style: 'textfooter'
                                },
                                {
                                        text: '',
                                        style: 'textfooter'
                                },
                                {
                                        text: '',
                                        style: 'textfooter'
                                },
                                {
                                        text: '',
                                        style: 'textfooter'
                                },
                                {
                                        text: '',
                                        style: 'textfooter'
                                },
                                {
                                        text: '',
                                        style: 'textfooter'
                                },

                                {
                                        text: '',
                                        style: 'textfooter'
                                },
                                {
                                        text: '',
                                        style: 'textfooter'
                                },
                                {
                                        image: customerlogo.logo,
                                        fit: [30, 30],
                                        margin: [25, 0, 0, 0],
                                        border: [false, false, false, false],
                                },

                                ],
                                [
                                        {
                                                text: '',
                                                style: 'textfooter',
                                                border: [false, false, false, false],
                                        },
                                        {
                                                text: '',
                                                style: 'textfooter',
                                                border: [false, false, false, false],
                                        },
                                        {
                                                text: '',
                                                style: 'textfooter',
                                                border: [false, false, false, false],
                                        },
                                        {
                                                text: '',
                                                style: 'textfooter',
                                                border: [false, false, false, false],
                                        },
                                        {
                                                text: '',
                                                style: 'textfooter',
                                                border: [false, false, false, false],
                                        },
                                        {
                                                text: '',
                                                style: 'textfooter',
                                                border: [false, false, false, false],
                                        },
                                        {
                                                text: '',
                                                style: 'textfooter',
                                                border: [false, false, false, false],
                                        },
                                        {
                                                text: '',
                                                style: 'textfooter',
                                                border: [false, false, false, false],
                                        },
                                        {
                                                text: '',
                                                style: 'textfooter',
                                                border: [false, false, false, false],
                                        },
                                        {
                                                text: '',
                                                style: 'textfooter',
                                                border: [false, false, false, false],
                                        },
                                        {
                                                text: '',
                                                style: 'textfooter',
                                                border: [false, false, false, false],
                                        },
                                        {
                                                text: '',
                                                style: 'textfooter',
                                                border: [false, false, false, false],
                                        },
                                        {
                                                text: '',
                                                style: 'textfooter',
                                                border: [false, false, false, false],
                                        },
                                        {
                                                text: '',
                                                style: 'textfooter',
                                                border: [false, false, false, false],
                                        },
                                        {
                                                text: '',
                                                style: 'textfooter',
                                                border: [false, false, false, false],
                                        },
                                        {
                                                text: '',
                                                style: 'textfooter',
                                                border: [false, false, false, false],
                                        },
                                        {
                                                text: '',
                                                style: 'textfooter',
                                                border: [false, false, false, false],
                                        },
                                        {
                                                text: '',
                                                style: 'textfooter',
                                                border: [false, false, false, false],
                                        },
                                        {
                                                text: '',
                                                style: 'textfooter',
                                                border: [false, false, false, false],
                                        },
                                        {
                                                text: '',
                                                style: 'textfooter',
                                                border: [false, false, false, false],
                                        },
                                ]

                        ]
                        var headertablemain = [
                                [
                                        {
                                                text: '\nDate',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                rowSpan: 2,
                                                border: [true, true, true, false]


                                        },
                                        {
                                                text: '\nAircraft',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                rowSpan: 2,
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: '\nFlt.Report No.',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                rowSpan: 2,
                                                colSpan: 2,
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: '',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: 'Flight Time',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                colSpan: 3

                                        },
                                        {
                                                text: '',
                                                style: 'tableheader',
                                                alignment: 'center'

                                        },
                                        {
                                                text: '',
                                                style: 'tableheader',
                                                alignment: 'center'

                                        },
                                        {
                                                text: 'Passenger',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                colSpan: 2

                                        },
                                        {
                                                text: '',
                                                style: 'tableheader',
                                                alignment: 'center'

                                        },
                                        {
                                                text: 'Fuel ',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                colSpan: 4

                                        },
                                        {
                                                text: '',
                                                style: 'tableheader',
                                                alignment: 'center'

                                        },
                                        {
                                                text: '',
                                                style: 'tableheader',
                                                alignment: 'center'

                                        },
                                        {
                                                text: '',
                                                style: 'tableheader',
                                                alignment: 'center'

                                        },
                                        {
                                                text: 'Payload Outbound',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                colSpan: 2

                                        },
                                        {
                                                text: '',
                                                style: 'tableheader',
                                                alignment: 'center'

                                        },
                                        {
                                                text: 'Payload Inbound ',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                colSpan: 3

                                        },
                                        {
                                                text: '',
                                                style: 'tableheader',
                                                alignment: 'center'

                                        },
                                        {
                                                text: '',
                                                style: 'tableheader',
                                                alignment: 'center'

                                        },
                                        {
                                                text: '\nRemark',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                rowSpan: 2,
                                                border: [true, true, true, false],
                                                colSpan: 2

                                        },
                                        {
                                                text: '',
                                                style: 'textfooter'
                                        },
                                ],
                                [
                                        {
                                                text: 'Date',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: 'A/C',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: 'Flt.Report No.',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: '',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: 'Revenue',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: 'Non-Revenue',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: 'Standby Time',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: 'Out',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: 'In',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: 'VTSH',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: 'MDLQ',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: 'AQD',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: 'Non-Revenue',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: 'Available',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: 'Actual',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: 'Available',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: 'Actual',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                colSpan: 2,
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: ' ',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: '\nRemark',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, true, true, false],
                                                colSpan: 2

                                        },
                                        {
                                                text: '',
                                                style: 'textfooter'
                                        },
                                ],


                        ]
                        var footertablemain = [
                                [
                                        {
                                                text: ' ',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, false, true, true]

                                        },
                                        {
                                                text: 'Total',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                bold: 'True',
                                                border: [true, false, true, true]

                                        },
                                        {
                                                text: return_main_total.flight + ' Flight',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                colSpan: 2,
                                                border: [true, false, true, true]

                                        },
                                        {
                                                text: ''
                                        },
                                        {
                                                text: return_main_total.time_revenue,
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, false, true, true]

                                        },
                                        {
                                                text: return_main_total.time_non_revenue,
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, false, true, true]

                                        },
                                        {
                                                text: return_main_total.time_stanby,
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, false, true, true]

                                        },
                                        {
                                                text: return_main_total.pax_out,
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, false, true, true]

                                        },
                                        {
                                                text: return_main_total.pax_in,
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, false, true, true]

                                        },
                                        {
                                                text: return_main_total.fuel_vtsh,
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, false, true, true]

                                        },
                                        {
                                                text: return_main_total.fuel_mdlq,
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, false, true, true]

                                        },
                                        {
                                                text: return_main_total.fuel_aqd,
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, false, true, true]

                                        },
                                        {
                                                text: return_main_total.fuel_non,
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, false, true, true]

                                        },
                                        {
                                                text: return_main_total.payload_out_available,
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, false, true, true]

                                        },
                                        {
                                                text: return_main_total.payload_out_actual,
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, false, true, true]

                                        },
                                        {
                                                text: return_main_total.payload_in_available,
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, false, true, true]

                                        },
                                        {
                                                text: return_main_total.payload_in_actual,
                                                style: 'tableheader',
                                                alignment: 'center',
                                                colSpan: 2,
                                                border: [true, false, true, true]

                                        },
                                        {
                                                text: '',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, false, true, true]

                                        },
                                        {
                                                text: ' ',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, false, true, true],
                                                colSpan: 2

                                        },
                                        {
                                                text: '',
                                                style: 'textfooter'
                                        },
                                ]
                        ]
                        var tablemain = []
                        headerpagemain.forEach(x => tablemain.push(x))
                        headertablemain.forEach(x => tablemain.push(x))
                        return_main.forEach(x => tablemain.push(x))
                        footertablemain.forEach(x => tablemain.push(x))

                        var tablebackup = []
                        var headerpagebackup = [
                                [{
                                        image: uoaimage.logo,
                                        fit: [30, 30],
                                        margin: [50, 0, 0, 0],
                                        border: [false, false, false, false],
                                        colSpan: 3
                                },
                                {
                                        text: '',
                                        style: 'textfooter'
                                },
                                {
                                        text: '',
                                        style: 'textfooter'
                                },
                                {
                                        text: '\nUOA-CPOC Monthly Report in ' + monthname + ', ' + year + ' (Back Up)',
                                        margin: [0, 0, 0, 0],
                                        alignment: 'center',
                                        fontSize: 12,
                                        border: [true, true, true, true],
                                        colSpan: 16
                                },
                                {
                                        text: '',
                                        style: 'textfooter'
                                },
                                {
                                        text: '',
                                        style: 'textfooter'
                                },
                                {
                                        text: '',
                                        style: 'textfooter'
                                },
                                {
                                        text: '',
                                        style: 'textfooter'
                                },
                                {
                                        text: '',
                                        style: 'textfooter'
                                },
                                {
                                        text: '',
                                        style: 'textfooter'
                                },
                                {
                                        text: '',
                                        style: 'textfooter'
                                },
                                {
                                        text: '',
                                        style: 'textfooter'
                                },
                                {
                                        text: '',
                                        style: 'textfooter'
                                },
                                {
                                        text: '',
                                        style: 'textfooter'
                                },
                                {
                                        text: '',
                                        style: 'textfooter'
                                },
                                {
                                        text: '',
                                        style: 'textfooter'
                                },
                                {
                                        text: '',
                                        style: 'textfooter'
                                },

                                {
                                        text: '',
                                        style: 'textfooter'
                                },
                                {
                                        text: '',
                                        style: 'textfooter'
                                },
                                {
                                        image: customerlogo.logo,
                                        fit: [30, 30],
                                        margin: [25, 0, 0, 0],
                                        border: [false, false, false, false],
                                },

                                ],
                                [
                                        {
                                                text: '',
                                                style: 'textfooter',
                                                border: [false, false, false, false],
                                        },
                                        {
                                                text: '',
                                                style: 'textfooter',
                                                border: [false, false, false, false],
                                        },
                                        {
                                                text: '',
                                                style: 'textfooter',
                                                border: [false, false, false, false],
                                        },
                                        {
                                                text: '',
                                                style: 'textfooter',
                                                border: [false, false, false, false],
                                        },
                                        {
                                                text: '',
                                                style: 'textfooter',
                                                border: [false, false, false, false],
                                        },
                                        {
                                                text: '',
                                                style: 'textfooter',
                                                border: [false, false, false, false],
                                        },
                                        {
                                                text: '',
                                                style: 'textfooter',
                                                border: [false, false, false, false],
                                        },
                                        {
                                                text: '',
                                                style: 'textfooter',
                                                border: [false, false, false, false],
                                        },
                                        {
                                                text: '',
                                                style: 'textfooter',
                                                border: [false, false, false, false],
                                        },
                                        {
                                                text: '',
                                                style: 'textfooter',
                                                border: [false, false, false, false],
                                        },
                                        {
                                                text: '',
                                                style: 'textfooter',
                                                border: [false, false, false, false],
                                        },
                                        {
                                                text: '',
                                                style: 'textfooter',
                                                border: [false, false, false, false],
                                        },
                                        {
                                                text: '',
                                                style: 'textfooter',
                                                border: [false, false, false, false],
                                        },
                                        {
                                                text: '',
                                                style: 'textfooter',
                                                border: [false, false, false, false],
                                        },
                                        {
                                                text: '',
                                                style: 'textfooter',
                                                border: [false, false, false, false],
                                        },
                                        {
                                                text: '',
                                                style: 'textfooter',
                                                border: [false, false, false, false],
                                        },
                                        {
                                                text: '',
                                                style: 'textfooter',
                                                border: [false, false, false, false],
                                        },
                                        {
                                                text: '',
                                                style: 'textfooter',
                                                border: [false, false, false, false],
                                        },
                                        {
                                                text: '',
                                                style: 'textfooter',
                                                border: [false, false, false, false],
                                        },
                                        {
                                                text: '',
                                                style: 'textfooter',
                                                border: [false, false, false, false],
                                        },
                                ]

                        ]
                        var headertablebackup = [
                                [
                                        {
                                                text: '\nDate',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                rowSpan: 2,
                                                border: [true, true, true, false]


                                        },
                                        {
                                                text: '\nAircraft',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                rowSpan: 2,
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: '\nFlt.Report No.',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                rowSpan: 2,
                                                colSpan: 2,
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: '',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: 'Flight Time',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                colSpan: 3

                                        },
                                        {
                                                text: '',
                                                style: 'tableheader',
                                                alignment: 'center'

                                        },
                                        {
                                                text: '',
                                                style: 'tableheader',
                                                alignment: 'center'

                                        },
                                        {
                                                text: 'Passenger',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                colSpan: 2

                                        },
                                        {
                                                text: '',
                                                style: 'tableheader',
                                                alignment: 'center'

                                        },
                                        {
                                                text: 'Fuel ',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                colSpan: 4

                                        },
                                        {
                                                text: '',
                                                style: 'tableheader',
                                                alignment: 'center'

                                        },
                                        {
                                                text: '',
                                                style: 'tableheader',
                                                alignment: 'center'

                                        },
                                        {
                                                text: '',
                                                style: 'tableheader',
                                                alignment: 'center'

                                        },
                                        {
                                                text: 'Payload Outbound',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                colSpan: 2

                                        },
                                        {
                                                text: '',
                                                style: 'tableheader',
                                                alignment: 'center'

                                        },
                                        {
                                                text: 'Payload Inbound ',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                colSpan: 3

                                        },
                                        {
                                                text: '',
                                                style: 'tableheader',
                                                alignment: 'center'

                                        },
                                        {
                                                text: '',
                                                style: 'tableheader',
                                                alignment: 'center'

                                        },
                                        {
                                                text: '\nRemark',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                rowSpan: 2,
                                                border: [true, true, true, false],
                                                colSpan: 2

                                        },
                                        {
                                                text: '',
                                                style: 'textfooter'
                                        },
                                ],
                                [
                                        {
                                                text: 'Date',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: 'A/C',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: 'Flt.Report No.',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: '',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: 'Revenue',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: 'Non-Revenue',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: 'Standby Time',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: 'Out',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: 'In',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: 'VTSH',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: 'MDLQ',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: 'AQD',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: 'Non-Revenue',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: 'Available',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: 'Actual',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: 'Available',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: 'Actual',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                colSpan: 2,
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: ' ',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: '\nRemark',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, true, true, false],
                                                colSpan: 2

                                        },
                                        {
                                                text: '',
                                                style: 'textfooter'
                                        },
                                ],


                        ]
                        var footertablebackup = [
                                [
                                        {
                                                text: ' ',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, false, true, true]

                                        },
                                        {
                                                text: 'Total',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                bold: 'True',
                                                border: [true, false, true, true]

                                        },
                                        {
                                                text: return_back_total.flight + ' Flight',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                colSpan: 2,
                                                border: [true, false, true, true]

                                        },
                                        {
                                                text: ''
                                        },
                                        {
                                                text: return_back_total.time_revenue,
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, false, true, true]

                                        },
                                        {
                                                text: return_back_total.time_non_revenue,
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, false, true, true]

                                        },
                                        {
                                                text: return_back_total.time_stanby,
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, false, true, true]

                                        },
                                        {
                                                text: return_back_total.pax_out,
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, false, true, true]

                                        },
                                        {
                                                text: return_back_total.pax_in,
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, false, true, true]

                                        },
                                        {
                                                text: return_back_total.fuel_vtsh,
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, false, true, true]

                                        },
                                        {
                                                text: return_back_total.fuel_mdlq,
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, false, true, true]

                                        },
                                        {
                                                text: return_back_total.fuel_aqd,
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, false, true, true]

                                        },
                                        {
                                                text: return_back_total.fuel_non,
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, false, true, true]

                                        },
                                        {
                                                text: return_back_total.payload_out_available,
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, false, true, true]

                                        },
                                        {
                                                text: return_back_total.payload_out_actual,
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, false, true, true]

                                        },
                                        {
                                                text: return_back_total.payload_in_available,
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, false, true, true]

                                        },
                                        {
                                                text: return_back_total.payload_in_actual,
                                                style: 'tableheader',
                                                alignment: 'center',
                                                colSpan: 2,
                                                border: [true, false, true, true]

                                        },
                                        {
                                                text: '',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, false, true, true]

                                        },
                                        {
                                                text: ' ',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, false, true, true],
                                                colSpan: 2

                                        },
                                        {
                                                text: '',
                                                style: 'textfooter'
                                        },
                                ]
                        ]
                        headerpagebackup.forEach(x => tablebackup.push(x))
                        headertablebackup.forEach(x => tablebackup.push(x))
                        return_back.forEach(x => tablebackup.push(x))
                        footertablebackup.forEach(x => tablebackup.push(x))
                        await Monthlyreport.CreateCpocPdf(month, monthname, year, oid, tablemain, tablebackup, FullName)

                }
                if (oid == 3) //"CHOC"
                {
                        var res = await feathersClientUOA
                                .service("monthly")
                                .find({ query: q });
                        return_main = await Monthlyreport.getdataChoc(res, month, year, true);
                        return_main_total = await Monthlyreport.gettotaldataChoc(res);

                        var res_backup = await feathersClientUOA
                                .service("montylycpocbackup")
                                .find({ query: q });
                        return_back = await Monthlyreport.getdataChoc(res_backup, month, year, false);
                        return_back_total = await Monthlyreport.gettotaldataChoc(res_backup);
                        var uoaimage = await LogoService.GetLogoBaseurl64(1);
                        var customerlogo = await LogoService.GetLogoBaseurl64(oid);
                        var headerpagemain = [
                                [{
                                        image: uoaimage.logo,
                                        fit: [30, 30],
                                        margin: [50, 0, 0, 0],
                                        border: [false, false, false, false],
                                        colSpan: 3
                                },
                                {
                                        text: '',
                                        style: 'textfooter'
                                },
                                {
                                        text: '',
                                        style: 'textfooter'
                                },
                                {
                                        text: '\nUOA-CHOC Monthly Report in ' + monthname + ', ' + year + ' (HS-UOL)',
                                        margin: [0, 0, 0, 0],
                                        alignment: 'center',
                                        fontSize: 12,
                                        border: [true, true, true, true],
                                        colSpan: 14
                                },

                                {
                                        text: '',
                                        style: 'textfooter'
                                },
                                {
                                        text: '',
                                        style: 'textfooter'
                                },
                                {
                                        text: '',
                                        style: 'textfooter'
                                },
                                {
                                        text: '',
                                        style: 'textfooter'
                                },
                                {
                                        text: '',
                                        style: 'textfooter'
                                },
                                {
                                        text: '',
                                        style: 'textfooter'
                                },
                                {
                                        text: '',
                                        style: 'textfooter'
                                },
                                {
                                        text: '',
                                        style: 'textfooter'
                                },
                                {
                                        text: '',
                                        style: 'textfooter'
                                },
                                {
                                        text: '',
                                        style: 'textfooter'
                                },
                                {
                                        text: '',
                                        style: 'textfooter'
                                },

                                {
                                        text: '',
                                        style: 'textfooter'
                                },
                                {
                                        text: '',
                                        style: 'textfooter'
                                },
                                {
                                        image: customerlogo.logo,
                                        fit: [100, 100],
                                        margin: [0, 0, 0, 0],
                                        border: [false, false, false, false],
                                },

                                ],
                                [

                                        {
                                                text: '',
                                                style: 'textfooter',
                                                border: [false, false, false, false],
                                        },
                                        {
                                                text: '',
                                                style: 'textfooter',
                                                border: [false, false, false, false],
                                        },
                                        {
                                                text: '',
                                                style: 'textfooter',
                                                border: [false, false, false, false],
                                        },
                                        {
                                                text: '',
                                                style: 'textfooter',
                                                border: [false, false, false, false],
                                        },
                                        {
                                                text: '',
                                                style: 'textfooter',
                                                border: [false, false, false, false],
                                        },
                                        {
                                                text: '',
                                                style: 'textfooter',
                                                border: [false, false, false, false],
                                        },
                                        {
                                                text: '',
                                                style: 'textfooter',
                                                border: [false, false, false, false],
                                        },
                                        {
                                                text: '',
                                                style: 'textfooter',
                                                border: [false, false, false, false],
                                        },
                                        {
                                                text: '',
                                                style: 'textfooter',
                                                border: [false, false, false, false],
                                        },
                                        {
                                                text: '',
                                                style: 'textfooter',
                                                border: [false, false, false, false],
                                        },
                                        {
                                                text: '',
                                                style: 'textfooter',
                                                border: [false, false, false, false],
                                        },
                                        {
                                                text: '',
                                                style: 'textfooter',
                                                border: [false, false, false, false],
                                        },
                                        {
                                                text: '',
                                                style: 'textfooter',
                                                border: [false, false, false, false],
                                        },
                                        {
                                                text: '',
                                                style: 'textfooter',
                                                border: [false, false, false, false],
                                        },
                                        {
                                                text: '',
                                                style: 'textfooter',
                                                border: [false, false, false, false],
                                        },
                                        {
                                                text: '',
                                                style: 'textfooter',
                                                border: [false, false, false, false],
                                        },
                                        {
                                                text: '',
                                                style: 'textfooter',
                                                border: [false, false, false, false],
                                        },
                                        {
                                                text: '',
                                                style: 'textfooter',
                                                border: [false, false, false, false],
                                        },
                                ]

                        ]
                        var headertablemain = [
                                [
                                        {
                                                text: '\nDate',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                rowSpan: 2,
                                                border: [true, true, true, false]


                                        },
                                        {
                                                text: '\nAircraft',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                rowSpan: 2,
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: '\nFlt.Report No.',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                rowSpan: 2,
                                                colSpan: 2,
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: '',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: 'Flight Time',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                colSpan: 3

                                        },
                                        {
                                                text: '',
                                                style: 'tableheader',
                                                alignment: 'center'

                                        },
                                        {
                                                text: '',
                                                style: 'tableheader',
                                                alignment: 'center'

                                        },
                                        {
                                                text: 'Passenger',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                colSpan: 2

                                        },
                                        {
                                                text: '',
                                                style: 'tableheader',
                                                alignment: 'center'

                                        },
                                        {
                                                text: 'Fuel ',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                colSpan: 2

                                        },
                                        {
                                                text: '',
                                                style: 'tableheader',
                                                alignment: 'center'

                                        },
                                        {
                                                text: 'Payload Outbound',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                colSpan: 2

                                        },
                                        {
                                                text: '',
                                                style: 'tableheader',
                                                alignment: 'center'

                                        },
                                        {
                                                text: 'Payload Inbound ',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                colSpan: 3

                                        },
                                        {
                                                text: '',
                                                style: 'tableheader',
                                                alignment: 'center'

                                        },
                                        {
                                                text: '',
                                                style: 'tableheader',
                                                alignment: 'center'

                                        },
                                        {
                                                text: '\nRemark',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                rowSpan: 2,
                                                border: [true, true, true, false],
                                                colSpan: 2

                                        },
                                        {
                                                text: '',
                                                style: 'textfooter'
                                        },
                                ],
                                [
                                        {
                                                text: 'Date',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: 'A/C',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: 'Flt.Report No.',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: '',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: 'Revenue',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: 'Non-Revenue',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: 'Standby Time',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: 'Out',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: 'In',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: 'VTSH',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: 'Non-Revenue',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: 'Available',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: 'Actual',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: 'Available',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: 'Actual',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                colSpan: 2,
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: ' ',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: '\nRemark',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, true, true, false],
                                                colSpan: 2

                                        },
                                        {
                                                text: '',
                                                style: 'textfooter'
                                        },
                                ],


                        ]
                        var footertablemain = [
                                [
                                        {
                                                text: ' ',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, false, true, true]

                                        },
                                        {
                                                text: 'Total',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                bold: 'True',
                                                border: [true, false, true, true]

                                        },
                                        {
                                                text: return_main_total.flight + ' Flight',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                colSpan: 2,
                                                border: [true, false, true, true]

                                        },
                                        {
                                                text: ''
                                        },
                                        {
                                                text: return_main_total.time_revenue,
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, false, true, true]

                                        },
                                        {
                                                text: return_main_total.time_non_revenue,
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, false, true, true]

                                        },
                                        {
                                                text: return_main_total.time_stanby,
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, false, true, true]

                                        },
                                        {
                                                text: return_main_total.pax_out,
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, false, true, true]

                                        },
                                        {
                                                text: return_main_total.pax_in,
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, false, true, true]

                                        },
                                        {
                                                text: return_main_total.fuel_vtsh,
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, false, true, true]

                                        },

                                        {
                                                text: return_main_total.fuel_non,
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, false, true, true]

                                        },
                                        {
                                                text: return_main_total.payload_out_available,
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, false, true, true]

                                        },
                                        {
                                                text: return_main_total.payload_out_actual,
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, false, true, true]

                                        },
                                        {
                                                text: return_main_total.payload_in_available,
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, false, true, true]

                                        },
                                        {
                                                text: return_main_total.payload_in_actual,
                                                style: 'tableheader',
                                                alignment: 'center',
                                                colSpan: 2,
                                                border: [true, false, true, true]

                                        },
                                        {
                                                text: '',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, false, true, true]

                                        },
                                        {
                                                text: ' ',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, false, true, true],
                                                colSpan: 2

                                        },
                                        {
                                                text: '',
                                                style: 'textfooter'
                                        },
                                ]
                        ]
                        var tablemain = []
                        headerpagemain.forEach(x => tablemain.push(x))
                        headertablemain.forEach(x => tablemain.push(x))
                        return_main.forEach(x => tablemain.push(x))
                        footertablemain.forEach(x => tablemain.push(x))

                        var tablebackup = []
                        var headerpagebackup = [
                                [{
                                        image: uoaimage.logo,
                                        fit: [30, 30],
                                        margin: [50, 0, 0, 0],
                                        border: [false, false, false, false],
                                        colSpan: 3
                                },
                                {
                                        text: '',
                                        style: 'textfooter'
                                },
                                {
                                        text: '',
                                        style: 'textfooter'
                                },
                                {
                                        text: '\nUOA-CHOC Monthly Report in ' + monthname + ', ' + year + ' (Back Up)',
                                        margin: [0, 0, 0, 0],
                                        alignment: 'center',
                                        fontSize: 12,
                                        border: [true, true, true, true],
                                        colSpan: 14
                                },
                                {
                                        text: '',
                                        style: 'textfooter'
                                },
                                {
                                        text: '',
                                        style: 'textfooter'
                                },
                                {
                                        text: '',
                                        style: 'textfooter'
                                },
                                {
                                        text: '',
                                        style: 'textfooter'
                                },
                                {
                                        text: '',
                                        style: 'textfooter'
                                },
                                {
                                        text: '',
                                        style: 'textfooter'
                                },
                                {
                                        text: '',
                                        style: 'textfooter'
                                },
                                {
                                        text: '',
                                        style: 'textfooter'
                                },
                                {
                                        text: '',
                                        style: 'textfooter'
                                },
                                {
                                        text: '',
                                        style: 'textfooter'
                                },
                                {
                                        text: '',
                                        style: 'textfooter'
                                },

                                {
                                        text: '',
                                        style: 'textfooter'
                                },
                                {
                                        text: '',
                                        style: 'textfooter'
                                },
                                {
                                        image: customerlogo.logo,
                                        fit: [100, 100],
                                        margin: [0, 0, 0, 0],
                                        border: [false, false, false, false],
                                },

                                ],
                                [
                                        {
                                                text: '',
                                                style: 'textfooter',
                                                border: [false, false, false, false],
                                        },
                                        {
                                                text: '',
                                                style: 'textfooter',
                                                border: [false, false, false, false],
                                        },
                                        {
                                                text: '',
                                                style: 'textfooter',
                                                border: [false, false, false, false],
                                        },
                                        {
                                                text: '',
                                                style: 'textfooter',
                                                border: [false, false, false, false],
                                        },
                                        {
                                                text: '',
                                                style: 'textfooter',
                                                border: [false, false, false, false],
                                        },
                                        {
                                                text: '',
                                                style: 'textfooter',
                                                border: [false, false, false, false],
                                        },
                                        {
                                                text: '',
                                                style: 'textfooter',
                                                border: [false, false, false, false],
                                        },
                                        {
                                                text: '',
                                                style: 'textfooter',
                                                border: [false, false, false, false],
                                        },
                                        {
                                                text: '',
                                                style: 'textfooter',
                                                border: [false, false, false, false],
                                        },
                                        {
                                                text: '',
                                                style: 'textfooter',
                                                border: [false, false, false, false],
                                        },
                                        {
                                                text: '',
                                                style: 'textfooter',
                                                border: [false, false, false, false],
                                        },
                                        {
                                                text: '',
                                                style: 'textfooter',
                                                border: [false, false, false, false],
                                        },
                                        {
                                                text: '',
                                                style: 'textfooter',
                                                border: [false, false, false, false],
                                        },
                                        {
                                                text: '',
                                                style: 'textfooter',
                                                border: [false, false, false, false],
                                        },
                                        {
                                                text: '',
                                                style: 'textfooter',
                                                border: [false, false, false, false],
                                        },
                                        {
                                                text: '',
                                                style: 'textfooter',
                                                border: [false, false, false, false],
                                        },
                                        {
                                                text: '',
                                                style: 'textfooter',
                                                border: [false, false, false, false],
                                        },
                                        {
                                                text: '',
                                                style: 'textfooter',
                                                border: [false, false, false, false],
                                        },

                                ]

                        ]
                        var headertablebackup = [
                                [
                                        {
                                                text: '\nDate',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                rowSpan: 2,
                                                border: [true, true, true, false]


                                        },
                                        {
                                                text: '\nAircraft',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                rowSpan: 2,
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: '\nFlt.Report No.',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                rowSpan: 2,
                                                colSpan: 2,
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: '',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: 'Flight Time',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                colSpan: 3

                                        },
                                        {
                                                text: '',
                                                style: 'tableheader',
                                                alignment: 'center'

                                        },
                                        {
                                                text: '',
                                                style: 'tableheader',
                                                alignment: 'center'

                                        },
                                        {
                                                text: 'Passenger',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                colSpan: 2

                                        },
                                        {
                                                text: '',
                                                style: 'tableheader',
                                                alignment: 'center'

                                        },
                                        {
                                                text: 'Fuel ',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                colSpan: 2

                                        },

                                        {
                                                text: '',
                                                style: 'tableheader',
                                                alignment: 'center'

                                        },
                                        {
                                                text: 'Payload Outbound',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                colSpan: 2

                                        },
                                        {
                                                text: '',
                                                style: 'tableheader',
                                                alignment: 'center'

                                        },
                                        {
                                                text: 'Payload Inbound ',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                colSpan: 3

                                        },
                                        {
                                                text: '',
                                                style: 'tableheader',
                                                alignment: 'center'

                                        },
                                        {
                                                text: '',
                                                style: 'tableheader',
                                                alignment: 'center'

                                        },
                                        {
                                                text: '\nRemark',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                rowSpan: 2,
                                                border: [true, true, true, false],
                                                colSpan: 2

                                        },
                                        {
                                                text: '',
                                                style: 'textfooter'
                                        },
                                ],
                                [
                                        {
                                                text: 'Date',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: 'A/C',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: 'Flt.Report No.',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: '',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: 'Revenue',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: 'Non-Revenue',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: 'Standby Time',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: 'Out',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: 'In',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: 'VTSH',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, true, true, false]

                                        },

                                        {
                                                text: 'Non-Revenue',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: 'Available',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: 'Actual',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: 'Available',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: 'Actual',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                colSpan: 2,
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: ' ',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: '\nRemark',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, true, true, false],
                                                colSpan: 2

                                        },
                                        {
                                                text: '',
                                                style: 'textfooter'
                                        },
                                ],


                        ]
                        var footertablebackup = [
                                [
                                        {
                                                text: ' ',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, false, true, true]

                                        },
                                        {
                                                text: 'Total',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                bold: 'True',
                                                border: [true, false, true, true]

                                        },
                                        {
                                                text: return_back_total.flight + ' Flight',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                colSpan: 2,
                                                border: [true, false, true, true]

                                        },
                                        {
                                                text: ''
                                        },
                                        {
                                                text: return_back_total.time_revenue,
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, false, true, true]

                                        },
                                        {
                                                text: return_back_total.time_non_revenue,
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, false, true, true]

                                        },
                                        {
                                                text: return_back_total.time_stanby,
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, false, true, true]

                                        },
                                        {
                                                text: return_back_total.pax_out,
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, false, true, true]

                                        },
                                        {
                                                text: return_back_total.pax_in,
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, false, true, true]

                                        },
                                        {
                                                text: return_back_total.fuel_vtsh,
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, false, true, true]

                                        },

                                        {
                                                text: return_back_total.fuel_non,
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, false, true, true]

                                        },
                                        {
                                                text: return_back_total.payload_out_available,
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, false, true, true]

                                        },
                                        {
                                                text: return_back_total.payload_out_actual,
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, false, true, true]

                                        },
                                        {
                                                text: return_back_total.payload_in_available,
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, false, true, true]

                                        },
                                        {
                                                text: return_back_total.payload_in_actual,
                                                style: 'tableheader',
                                                alignment: 'center',
                                                colSpan: 2,
                                                border: [true, false, true, true]

                                        },
                                        {
                                                text: '',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, false, true, true]

                                        },
                                        {
                                                text: ' ',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, false, true, true],
                                                colSpan: 2

                                        },
                                        {
                                                text: '',
                                                style: 'textfooter'
                                        },
                                ]
                        ]
                        headerpagebackup.forEach(x => tablebackup.push(x))
                        headertablebackup.forEach(x => tablebackup.push(x))
                        return_back.forEach(x => tablebackup.push(x))
                        footertablebackup.forEach(x => tablebackup.push(x))
                        await Monthlyreport.CreateChocPdf(month, monthname, year, oid, tablemain, tablebackup, FullName)

                }
                if (oid == 4 && ap_id == 1) //"MUBADALA VTSH"
                {
                        // alert("mubadala")
                        var res = await feathersClientUOA
                                .service("monthly")
                                .find({ query: q });
                        return_main = await Monthlyreport.getdataMUBADALAVTSH(res, month, year, oid);
                        return_main_total = await Monthlyreport.gettotaldataMUBADALAVTSH(res);
                        var rowspantotal  = 4
                        if(return_main_total.mileage_e115 != 0)
                        {
                                rowspantotal  = 5
                        }
                        var tabledata = [
                                [
                                        {
                                                text: '\nDate',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                rowSpan: 2,
                                                fillColor: '#ededed',
                                                border: [true, true, true, false],
                                                bold: true
                                        },
                                        {
                                                text: '\nAircraft',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                rowSpan: 2,
                                                border: [true, true, true, false],
                                                fillColor: '#ededed',
                                                bold: true
                                        },
                                        {
                                                text: '\nFlt.Report No.',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                rowSpan: 2,
                                                border: [true, true, true, false],
                                                fillColor: '#ededed',
                                                bold: true
                                        },
                                        {
                                                text: 'Flight Time',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                fillColor: '#ededed',
                                                colSpan: 3,
                                                bold: true
                                        },
                                        {
                                                text: '02:19',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                fillColor: '#ededed',
                                                bold: true
                                        },
                                        {
                                                text: '02:19',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                fillColor: '#ededed',
                                                bold: true
                                        },
                                        {
                                                text: 'Passenger',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                fillColor: '#ededed',
                                                colSpan: 2,
                                                bold: true
                                        },
                                        {
                                                text: '11',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                fillColor: '#ededed',
                                                bold: true
                                        },
                                        {
                                                text: 'Fuel ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                fillColor: '#ededed',
                                                colSpan: 2,
                                                bold: true
                                        },
                                        {
                                                text: '2000',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                fillColor: '#ededed',
                                                bold: true
                                        },


                                        {
                                                text: 'Payload Outbound',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                colSpan: 2,
                                                border: [true, true, true, false],
                                                fillColor: '#ededed',
                                                bold: true
                                        },
                                        {
                                                text: '',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, true, true, false],
                                                fillColor: '#ededed',
                                                bold: true
                                        },
                                        {
                                                text: 'Payload inbound',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                colSpan: 2,
                                                border: [true, true, true, false],
                                                fillColor: '#ededed',
                                                bold: true
                                        },
                                        {
                                                text: '',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, true, true, false],
                                                fillColor: '#ededed',
                                                bold: true
                                        },


                                        {
                                                text: 'Remark',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                colSpan: 2,
                                                border: [true, true, true, false],
                                                fillColor: '#ededed',
                                                bold: true
                                        },
                                        {
                                                text: '',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                rowSpan: 2,
                                                border: [true, true, true, false],
                                                fillColor: '#ededed',
                                                bold: true
                                        }
                                ],
                                [
                                        {
                                                text: 'Date',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, true, true, false],
                                                fillColor: '#ededed',
                                                bold: true
                                        },
                                        {
                                                text: 'A/C',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, true, true, false],
                                                fillColor: '#ededed',
                                                bold: true
                                        },
                                        {
                                                text: 'Flt.Report No.',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, true, true, false],
                                                fillColor: '#ededed',
                                                bold: true
                                        },
                                        {
                                                text: 'Revenue',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, true, true, false],
                                                fillColor: '#ededed',
                                                bold: true
                                        },
                                        {
                                                text: 'Non-Revenue',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, true, true, false],
                                                fillColor: '#ededed',
                                                bold: true
                                        },
                                        {
                                                text: 'Standby Time',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, true, true, false],
                                                fillColor: '#ededed',
                                                bold: true
                                        },
                                        {
                                                text: 'Out',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, true, true, false],
                                                fillColor: '#ededed',
                                                bold: true
                                        },
                                        {
                                                text: 'In',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, true, true, false],
                                                fillColor: '#ededed',
                                                bold: true
                                        },
                                        {
                                                text: 'Revenue',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, true, true, false],
                                                fillColor: '#ededed',
                                                bold: true
                                        },
                                        {
                                                text: 'Non-Revenue',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, true, true, false],
                                                fillColor: '#ededed',
                                                bold: true
                                        },
                                        {
                                                text: 'Available',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, true, true, false],
                                                fillColor: '#ededed',
                                                bold: true
                                        },
                                        {
                                                text: 'Actual',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, true, true, false],
                                                fillColor: '#ededed',
                                                bold: true
                                        },
                                        {
                                                text: 'Available',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, true, true, false],
                                                fillColor: '#ededed',
                                                bold: true
                                        },
                                        {
                                                text: 'Actual',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, true, true, false],
                                                fillColor: '#ededed',
                                                bold: true
                                        },
                                        {
                                                text: '\nROUTE',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, true, true, false],
                                                fillColor: '#ededed',
                                                bold: true
                                        },
                                        {
                                                text: 'MILEAGE\n(NM)',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, true, true, false],
                                                fillColor: '#ededed',
                                                bold: true
                                        }
                                ],
                        ]
                        var totaldataAFSO = [
                                [
                                {
                                        text: ' ',
                                        style: 'tabledata',
                                        alignment: 'center',
                                        border: [true, false, true, true],
                                        fillColor: '#ededed',
                                        rowSpan: rowspantotal
                                },
                                {
                                        text: ' ',
                                        style: 'tabledata',
                                        alignment: 'center',
                                        bold: 'True',
                                        fillColor: '#ededed',
                                        border: [true, false, true, true],
                                        rowSpan: rowspantotal
                                },
                                {
                                        text: '\n\nTOTAL',
                                        style: 'tabledata',
                                        alignment: 'center',
                                        border: [true, false, true, true],
                                        rowSpan: rowspantotal, fillColor: '#ededed',
                                        bold: true
                                },
                                {
                                        text: '\n\n' + return_main_total.time_revenue,
                                        style: 'tabledata',
                                        alignment: 'center',
                                        border: [true, false, true, true],
                                        rowSpan: rowspantotal,
                                        bold: 'True',
                                        fillColor: '#ededed',
                                },
                                {
                                        text: '\n\n' + return_main_total.time_non_revenue,
                                        style: 'tabledata',
                                        alignment: 'center',
                                        bold: 'True',
                                        fillColor: '#ededed',
                                        border: [true, false, true, true],
                                        rowSpan: rowspantotal
                                },
                                {
                                        text: '\n\n' + return_main_total.time_non_revenue,
                                        style: 'tabledata',
                                        alignment: 'center',
                                        bold: 'True',
                                        fillColor: '#ededed',
                                        border: [true, false, true, true],
                                        rowSpan: rowspantotal
                                },
                                {
                                        text: '\n\n' + return_main_total.pax_out,
                                        style: 'tabledata',
                                        alignment: 'center',
                                        bold: 'True',
                                        fillColor: '#ededed',
                                        border: [true, false, true, true],
                                        rowSpan: rowspantotal
                                },
                                {
                                        text: '\n\n' + return_main_total.pax_in,
                                        style: 'tabledata',
                                        alignment: 'center',
                                        bold: 'True',
                                        fillColor: '#ededed',
                                        border: [true, false, true, true],
                                        rowSpan: rowspantotal
                                },
                                {
                                        text: '\n\n' + return_main_total.fuel,
                                        style: 'tabledata',
                                        alignment: 'center',
                                        bold: 'True',
                                        fillColor: '#ededed',
                                        border: [true, false, true, true],
                                        rowSpan: rowspantotal
                                },
                                {
                                        text: '\n\n' + return_main_total.fuel_non,
                                        style: 'tabledata',
                                        alignment: 'center',
                                        bold: 'True',
                                        fillColor: '#ededed',
                                        border: [true, false, true, true],
                                        rowSpan: rowspantotal
                                },
                                {
                                        text: '\n\n' + return_main_total.payload_out_available,
                                        style: 'tabledata',
                                        alignment: 'center',
                                        bold: 'True',
                                        fillColor: '#ededed',
                                        border: [true, false, true, true],
                                        rowSpan: rowspantotal
                                },
                                {
                                        text: '\n\n' + return_main_total.payload_out_actual,
                                        style: 'tabledata',
                                        alignment: 'center',
                                        bold: 'True',
                                        fillColor: '#ededed',
                                        border: [true, false, true, true],
                                        rowSpan: rowspantotal
                                },
                                {
                                        text: '\n\n' + return_main_total.payload_in_available,
                                        style: 'tabledata',
                                        alignment: 'center',
                                        bold: 'True',
                                        fillColor: '#ededed',
                                        border: [true, false, true, true],
                                        rowSpan: rowspantotal
                                },
                                {
                                        text: '\n\n' + return_main_total.payload_in_actual,
                                        style: 'tabledata',
                                        alignment: 'center',
                                        bold: 'True',
                                        fillColor: '#ededed',
                                        border: [true, false, true, true],
                                        rowSpan: rowspantotal
                                },
                                {
                                        text: 'MILEAGE OF AFSO',
                                        style: 'tabledata',
                                        alignment: 'center',
                                        bold: 'True',
                                        fillColor: '#ededed',
                                        border: [true, false, true, true],
                                },
                                {
                                        text: return_main_total.mileage_afso,
                                        style: 'tabledata',
                                        alignment: 'center',
                                        bold: 'True',
                                        fillColor: '#ededed',
                                        border: [true, false, true, true],
                                }
                        ],
                        ]
                        var totaldataE115 = [
                                [
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                bold: 'True',
                                                fillColor: '#ededed',
                                                border: [true, false, true, true]
        
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                bold: 'True',
                                                fillColor: '#ededed',
                                                border: [true, false, true, true]
        
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
        
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
        
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
        
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
        
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
        
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
        
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
        
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
        
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
        
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
        
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
        
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
        
                                        },
                                        {
                                                text: 'MILEAGE OF E115',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                bold: 'True',
                                                fillColor: '#ededed',
                                                border: [true, false, true, true]
        
                                        },
                                        {
                                                text: return_main_total.mileage_e115,
                                                style: 'tabledata',
                                                alignment: 'center',
                                                bold: 'True',
                                                fillColor: '#ededed',
                                                border: [true, false, true, true]
        
                                        }
                                ],
                        ]
                        var totaldataanother =
                        [
                                [
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
        
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                bold: 'True',
                                                border: [true, false, true, true]
        
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
        
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
        
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
        
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
        
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
        
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
        
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
        
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
        
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
        
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
        
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
        
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
        
                                        },
                                        {
                                                text: 'MILEAGE MIST',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                bold: 'True',
                                                fillColor: '#ededed',
                                                border: [true, false, true, true]
        
                                        },
                                        {
                                                text: return_main_total.mileage_mist,
                                                style: 'tabledata',
                                                alignment: 'center',
                                                bold: 'True',
                                                fillColor: '#ededed',
                                                border: [true, false, true, true]
        
                                        }
                                ],
                                [
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
        
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                bold: 'True',
                                                border: [true, false, true, true]
        
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
        
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
        
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
        
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
        
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
        
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
        
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
        
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
        
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
        
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
        
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
        
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
        
                                        },
                                        {
                                                text: 'MILEAGE COMBINE',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                bold: 'True',
                                                fillColor: '#ededed',
                                                border: [true, false, true, true]
        
                                        },
                                        {
                                                text: return_main_total.mileage_combine,
                                                style: 'tabledata',
                                                alignment: 'center',
                                                bold: 'True',
                                                fillColor: '#ededed',
                                                border: [true, false, true, true]
        
                                        }
                                ],
                                [
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
        
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                bold: 'True',
                                                border: [true, false, true, true]
        
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
        
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
        
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
        
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
        
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
        
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
        
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
        
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
        
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
        
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
        
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
        
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
        
                                        },
                                        {
                                                text: 'TOTAL MILEAGE (NM)',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                bold: 'True',
                                                fillColor: '#ededed',
                                                border: [true, false, true, true],
        
                                        },
                                        {
                                                text: return_main_total.total_mileage,
                                                style: 'tabledata',
                                                alignment: 'center',
                                                bold: 'True',
                                                fillColor: '#ededed',
                                                border: [true, false, true, true]
        
                                        }
                                ]
                        ]
                        return_main.forEach(x => tabledata.push(x))
                        totaldataAFSO.forEach(x => tabledata.push(x))
                        if(return_main_total.mileage_e115 != 0)
                        {
                                totaldataE115.forEach(x => tabledata.push(x))
                        }
                        totaldataanother.forEach(x => tabledata.push(x))
                        await Monthlyreport.CreateMUBADALAPdfVTSH(month, monthname, year, oid, tabledata, return_main_total, FullName)

                }
                if (oid == 4 && ap_id == 2) //"MUBADALA VTBU"
                {
                        var res = await feathersClientUOA
                                .service("monthly")
                                .find({ query: q });
                        //return_main = await Monthlyreport.getdata(res, month, year, oid);
                        return_main = await Monthlyreport.getdataMUBADALAVTBU(res, month, year);
                        return_main_total = await Monthlyreport.gettotaldataMUBADALAVTBU(res);
                        var tabledata = [
                                [
                                        {
                                                text: '\nDate',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                rowSpan: 2,
                                                border: [true, true, true, false],
                                                fillColor: '#ededed',
                                                bold: true
                                        },
                                        {
                                                text: '\nAircraft',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                rowSpan: 2,
                                                border: [true, true, true, false],
                                                fillColor: '#ededed',
                                                bold: true
                                        },
                                        {
                                                text: '\nFlt.Report No.',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                rowSpan: 2,
                                                border: [true, true, true, false],
                                                fillColor: '#ededed',
                                                bold: true
                                        },
                                        {
                                                text: 'Air Time',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                fillColor: '#ededed',
                                                colSpan: 5,
                                                bold: true
                                        },
                                        {
                                                text: '02:19',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                fillColor: '#ededed',
                                                bold: true
                                        },
                                        {
                                                text: '02:19',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                fillColor: '#ededed',
                                                bold: true
                                        },
                                        {
                                                text: '02:19',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                fillColor: '#ededed',
                                                bold: true
                                        },
                                        {
                                                text: '02:19',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                fillColor: '#ededed',
                                                bold: true
                                        },
                                        {
                                                text: 'Passenger',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                fillColor: '#ededed',
                                                colSpan: 2,
                                                bold: true
                                        },
                                        {
                                                text: '11',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                fillColor: '#ededed',
                                                bold: true
                                        },
                                        {
                                                text: 'Fuel ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                fillColor: '#ededed',
                                                colSpan: 2,
                                                bold: true
                                        },
                                        {
                                                text: '2000',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                fillColor: '#ededed',
                                                bold: true
                                        },
                                        {
                                                text: 'Payload Outbound',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                colSpan: 2,
                                                border: [true, true, true, false],
                                                fillColor: '#ededed',
                                                bold: true
                                        },
                                        {
                                                text: 'Payload Outbound',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, true, true, false],
                                                fillColor: '#ededed',
                                                bold: true
                                        },
                                        {
                                                text: 'Payload inbound', 
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, true, true, false],
                                                fillColor: '#ededed',
                                                colSpan: 2,
                                                bold: true
                                        },
                                        {
                                                text: 'Payload inbound',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, true, true, false],
                                                fillColor: '#ededed',
                                                bold: true
                                        },
                                        {
                                                text: 'Remark',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                colSpan: 2,
                                                border: [true, true, true, false],
                                                fillColor: '#ededed',
                                                bold: true
                                        },
                                        {
                                                text: '',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, true, true, false],
                                                fillColor: '#ededed',
                                                bold: true
                                        }
                                ],
                                [
                                        {
                                                text: 'Date',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, true, true, false],
                                                fillColor: '#ededed',
                                                bold: true

                                        },
                                        {
                                                text: 'A/C',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, true, true, false],
                                                fillColor: '#ededed',
                                                bold: true

                                        },
                                        {
                                                text: 'Flt.Report No.',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, true, true, false],
                                                fillColor: '#ededed',
                                                bold: true
                                        },
                                        {
                                                text: 'Revenue\n(Hrs:Mins)',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, true, true, false],
                                                fillColor: '#ededed',
                                                bold: true
                                        },
                                        {
                                                text: 'Revenue\n(Hrs.Parts)',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, true, true, false],
                                                fillColor: '#ededed',
                                                bold: true
                                        },
                                        {
                                                text: 'Non-Revenue\n(Hrs:Mins)',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, true, true, false],
                                                fillColor: '#ededed',
                                                bold: true
                                        },
                                        {
                                                text: 'Non-Revenue\n(Hrs.Parts)',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, true, true, false],
                                                fillColor: '#ededed',
                                                bold: true
                                        },
                                        {
                                                text: 'Standby Time',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, true, true, false],
                                                fillColor: '#ededed',
                                                bold: true
                                        },
                                        {
                                                text: 'Out',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, true, true, false],
                                                fillColor: '#ededed',
                                                bold: true
                                        },
                                        {
                                                text: 'In',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, true, true, false],
                                                fillColor: '#ededed',
                                                bold: true
                                        },
                                        {
                                                text: 'Revenue',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, true, true, false],
                                                fillColor: '#ededed',
                                                bold: true
                                        },
                                        {
                                                text: 'Non-Revenue',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, true, true, false],
                                                fillColor: '#ededed',
                                                bold: true
                                        },
                                        {
                                                text: 'Available',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, true, true, false],
                                                fillColor: '#ededed',
                                                bold: true
                                        },
                                        {
                                                text: 'Actual',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, true, true, false],
                                                fillColor: '#ededed',
                                                bold: true
                                        },
                                        {
                                                text: 'Available',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, true, true, false],
                                                fillColor: '#ededed',
                                                bold: true
                                        },
                                        {
                                                text: 'Actual',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, true, true, false],
                                                fillColor: '#ededed',
                                                bold: true
                                        },
                                        {
                                                text: '\nRoute',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, true, true, false],
                                                fillColor: '#ededed',
                                                bold: true
                                        },
                                        {
                                                text: '\nMileage\n(NM)',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, true, true, false],
                                                fillColor: '#ededed',
                                                bold: true
                                        }
                                ],


                        ]
                        var rowtotale115 = [
                                [
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true],
                                                fillColor: '#ededed',
                                                bold: true,
                                                rowSpan: 6
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                bold: 'True',
                                                border: [true, false, true, true],
                                                fillColor: '#ededed',
                                                bold: true,
                                                rowSpan: 6
                                        },
                                        {
                                                text: '\n\n\nTOTAL',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true],
                                                fillColor: '#ededed',
                                                bold: true,
                                                bold: 'True',
                                                rowSpan: 6
                                        },
                                        {
                                                text: '\n\n\n' + return_main_total.time_revenue,
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true],
                                                fillColor: '#ededed',
                                                bold: true,
                                                rowSpan: 6
                                        },
                                        {
                                                text: '\n\n\n' + return_main_total.time_revenue_part,
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true],
                                                fillColor: '#ededed',
                                                bold: true,
                                                rowSpan: 6
                                        },
                                        {
                                                text: '\n\n\n' + return_main_total.time_non_revenue,
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true],
                                                fillColor: '#ededed',
                                                bold: true,
                                                rowSpan: 6
                                        },
                                        {
                                                text: '\n\n\n' + return_main_total.time_non_revenue_part,
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true],
                                                fillColor: '#ededed',
                                                bold: true,
                                                rowSpan: 6
                                        },
                                        {
                                                text: '\n\n\n' + return_main_total.time_non_revenue,
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true],
                                                fillColor: '#ededed',
                                                bold: true,
                                                rowSpan: 6
                                        },
                                        {
                                                text: '\n\n\n' + return_main_total.pax_out,
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true],
                                                fillColor: '#ededed',
                                                bold: true,
                                                rowSpan: 6
                                        },
                                        {
                                                text: '\n\n\n' + return_main_total.pax_in,
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true],
                                                fillColor: '#ededed',
                                                bold: true,
                                                rowSpan: 6
                                        },
                                        {
                                                text: '\n\n\n' + return_main_total.fuel,
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true],
                                                fillColor: '#ededed',
                                                bold: true,
                                                rowSpan: 6
                                        },
                                        {
                                                text: '\n\n\n' + return_main_total.fuel_non,
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true],
                                                fillColor: '#ededed',
                                                bold: true,
                                                rowSpan: 6
                                        },
                                        {
                                                text: '\n\n\n' + return_main_total.payload_out_available,
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true],
                                                fillColor: '#ededed',
                                                bold: true,
                                                rowSpan: 6
                                        },
                                        {
                                                text: '\n\n\n' + return_main_total.payload_out_actual,
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true],
                                                fillColor: '#ededed',
                                                bold: true,
                                                rowSpan: 6
                                        },
                                        {
                                                text: '\n\n\n' + return_main_total.payload_in_available,
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true],
                                                fillColor: '#ededed',
                                                bold: true,
                                                rowSpan: 6
                                        },
                                        {
                                                text: '\n\n\n' + return_main_total.payload_in_actual,
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true],
                                                fillColor: '#ededed',
                                                bold: true,
                                                rowSpan: 6
                                        },
                                        {
                                                text: 'MILEAGE OF E115',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true],
                                                fillColor: '#ededed',
                                                bold: true,
                                        },
                                        {
                                                text: return_main_total.mileage_e115,
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true],
                                                fillColor: '#ededed',
                                                bold: true,
                                        }
                                ],
                        ]
                        var rowtotal = [
                                [
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true],
                                                fillColor: '#ededed',
                                                bold: true,
                                                rowSpan: 5
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                bold: 'True',
                                                border: [true, false, true, true],
                                                fillColor: '#ededed',
                                                bold: true,
                                                rowSpan: 5
                                        },
                                        {
                                                text: '\n\n\nTOTAL',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true],
                                                fillColor: '#ededed',
                                                bold: true,
                                                bold: 'True',
                                                rowSpan: 5
                                        },
                                        {
                                                text: '\n\n\n' + return_main_total.time_revenue,
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true],
                                                fillColor: '#ededed',
                                                bold: true,
                                                rowSpan: 5
                                        },
                                        {
                                                text: '\n\n\n' + return_main_total.time_revenue_part,
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true],
                                                fillColor: '#ededed',
                                                bold: true,
                                                rowSpan: 5
                                        },
                                        {
                                                text: '\n\n\n' + return_main_total.time_non_revenue,
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true],
                                                fillColor: '#ededed',
                                                bold: true,
                                                rowSpan: 5
                                        },
                                        {
                                                text: '\n\n\n' + return_main_total.time_non_revenue_part,
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true],
                                                fillColor: '#ededed',
                                                bold: true,
                                                rowSpan: 5
                                        },
                                        {
                                                text: '\n\n\n' + return_main_total.time_non_revenue,
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true],
                                                fillColor: '#ededed',
                                                bold: true,
                                                rowSpan: 5
                                        },
                                        {
                                                text: '\n\n\n' + return_main_total.pax_out,
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true],
                                                fillColor: '#ededed',
                                                bold: true,
                                                rowSpan: 5
                                        },
                                        {
                                                text: '\n\n\n' + return_main_total.pax_in,
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true],
                                                fillColor: '#ededed',
                                                bold: true,
                                                rowSpan: 5
                                        },
                                        {
                                                text: '\n\n\n' + return_main_total.fuel,
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true],
                                                fillColor: '#ededed',
                                                bold: true,
                                                rowSpan: 5
                                        },
                                        {
                                                text: '\n\n\n' + return_main_total.fuel_non,
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true],
                                                fillColor: '#ededed',
                                                bold: true,
                                                rowSpan: 5
                                        },
                                        {
                                                text: '\n\n\n' + return_main_total.payload_out_available,
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true],
                                                fillColor: '#ededed',
                                                bold: true,
                                                rowSpan: 5
                                        },
                                        {
                                                text: '\n\n\n' + return_main_total.payload_out_actual,
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true],
                                                fillColor: '#ededed',
                                                bold: true,
                                                rowSpan: 5
                                        },
                                        {
                                                text: '\n\n\n' + return_main_total.payload_in_available,
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true],
                                                fillColor: '#ededed',
                                                bold: true,
                                                rowSpan: 5
                                        },
                                        {
                                                text: '\n\n\n' + return_main_total.payload_in_actual,
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true],
                                                fillColor: '#ededed',
                                                bold: true,
                                                rowSpan: 5
                                        },
                                        {
                                                text: 'MILEAGE OF MIST',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true],
                                                fillColor: '#ededed',
                                                bold: true,
                                        },
                                        {
                                                text: return_main_total.mileage_mist,
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true],
                                                fillColor: '#ededed',
                                                bold: true,
                                        }
                                ],
                                [
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
                                        },
                                        {
                                                text: 'MILEAGE OF FPF3 ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true],
                                                fillColor: '#ededed',
                                                bold: true,
                                        },
                                        {
                                                text: return_main_total.mileage_fpf3,
                                                style: 'tabledata',
                                                alignment: 'center',
                                                fillColor: '#ededed',
                                                bold: true,
                                                border: [true, false, true, true]
                                        }
                                ],
                                [
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
                                        },
                                        {
                                                text: 'MILEAGE OF XMAP ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                fillColor: '#ededed',
                                                bold: true,
                                                border: [true, false, true, true]
                                        },
                                        {
                                                text: return_main_total.mileage_xmap,
                                                style: 'tabledata',
                                                alignment: 'center',
                                                fillColor: '#ededed',
                                                bold: true,
                                                border: [true, false, true, true]
                                        }
                                ],
                                [
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
                                        },
                                        {
                                                text: 'MILEAGE COMBINE',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                fillColor: '#ededed',
                                                bold: true,
                                                border: [true, false, true, true]
                                        },
                                        {
                                                text: return_main_total.mileage_combine,
                                                style: 'tabledata',
                                                alignment: 'center',
                                                fillColor: '#ededed',
                                                bold: true,
                                                border: [true, false, true, true]
                                        }
                                ],
                                [
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
                                        },
                                        {
                                                text: ' ',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                border: [true, false, true, true]
                                        },
                                        {
                                                text: 'TOTAL MILEAGE (NM)',
                                                style: 'tabledata',
                                                alignment: 'center',
                                                fillColor: '#ededed',
                                                bold: true,
                                                border: [true, true, true, true],
                                        },
                                        {
                                                text: return_main_total.total_mileage,
                                                style: 'tabledata',
                                                alignment: 'center',
                                                fillColor: '#ededed',
                                                bold: true,
                                                border: [true, false, true, true],
                                        }
                                ]
                        ]
                        return_main.forEach(x => tabledata.push(x))
                        if(return_main_total.mileage_e115 != 0)
                        {
                        rowtotale115.forEach(x => tabledata.push(x))
                        }
                        rowtotal.forEach(x => tabledata.push(x))
                        await Monthlyreport.CreateMUBADALAPdfVTBU(month, monthname, year, oid, tabledata, FullName)

                }
                if (oid == 5) //"PTT (PRP)"
                {
                        var res = await feathersClientUOA
                                .service("monthly")
                                .find({ query: q });
                        return_main = await Monthlyreport.getdataPrp(res, month, year, oid);
                        return_main_total = await Monthlyreport.gettotaldataPrp(res);
                        var headertablemain = [
                                [
                                        {
                                                text: '\nDate',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                rowSpan: 2,
                                                border: [true, true, true, false]


                                        },
                                        {
                                                text: '\nHS-UOL',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                rowSpan: 2,
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: '\nFlt.Report No.',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                rowSpan: 2,
                                                colSpan: 2,
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: '',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: 'Block Time',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                colSpan: 3

                                        },
                                        {
                                                text: '',
                                                style: 'tableheader',
                                                alignment: 'center'

                                        },
                                        {
                                                text: '',
                                                style: 'tableheader',
                                                alignment: 'center'

                                        },
                                        {
                                                text: 'Passenger',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                colSpan: 2

                                        },
                                        {
                                                text: '',
                                                style: 'tableheader',
                                                alignment: 'center'

                                        },
                                        {
                                                text: 'Fuel ',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                colSpan: 2

                                        },
                                        {
                                                text: '',
                                                style: 'tableheader',
                                                alignment: 'center'

                                        },
                                        {
                                                text: 'Payload Outbound',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                colSpan: 2

                                        },
                                        {
                                                text: '',
                                                style: 'tableheader',
                                                alignment: 'center'

                                        },
                                        {
                                                text: 'Payload Inbound ',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                colSpan: 3

                                        },
                                        {
                                                text: '',
                                                style: 'tableheader',
                                                alignment: 'center'

                                        },
                                        {
                                                text: '',
                                                style: 'tableheader',
                                                alignment: 'center'

                                        },
                                        {
                                                text: '\nRemark',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                rowSpan: 2,
                                                border: [true, true, true, false],
                                                colSpan: 2

                                        },
                                        {
                                                text: '',
                                                style: 'textfooter'
                                        },
                                ],
                                [
                                        {
                                                text: 'Date',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: 'A/C',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: 'Flt.Report No.',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: '',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: 'Revenue',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: 'Non-Revenue',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: 'Standby Time',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: 'Out',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: 'In',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: 'VTSH',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: 'Non-Revenue',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: 'Available',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: 'Actual',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: 'Available',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: 'Actual',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                colSpan: 2,
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: ' ',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, true, true, false]

                                        },
                                        {
                                                text: '\nRemark',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, true, true, false],
                                                colSpan: 2

                                        },
                                        {
                                                text: '',
                                                style: 'textfooter'
                                        },
                                ],


                        ]
                        var footertablemain = [
                                [
                                        {
                                                text: ' ',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, false, true, true]

                                        },
                                        {
                                                text: 'Total',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                bold: 'True',
                                                border: [true, false, true, true]

                                        },
                                        {
                                                text: return_main_total.flight + ' Flight',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                colSpan: 2,
                                                border: [true, false, true, true]

                                        },
                                        {
                                                text: ''
                                        },
                                        {
                                                text: return_main_total.time_revenue,
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, false, true, true]

                                        },
                                        {
                                                text: return_main_total.time_non_revenue,
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, false, true, true]

                                        },
                                        {
                                                text: '00:00',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, false, true, true]

                                        },
                                        {
                                                text: return_main_total.pax_out,
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, false, true, true]

                                        },
                                        {
                                                text: return_main_total.pax_in,
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, false, true, true]

                                        },
                                        {
                                                text: return_main_total.fuel_vtsh,
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, false, true, true]

                                        },

                                        {
                                                text: return_main_total.fuel_non,
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, false, true, true]

                                        },
                                        {
                                                text: return_main_total.payload_out_available,
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, false, true, true]

                                        },
                                        {
                                                text: return_main_total.payload_out_actual,
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, false, true, true]

                                        },
                                        {
                                                text: return_main_total.payload_in_available,
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, false, true, true]

                                        },
                                        {
                                                text: return_main_total.payload_in_actual,
                                                style: 'tableheader',
                                                alignment: 'center',
                                                colSpan: 2,
                                                border: [true, false, true, true]

                                        },
                                        {
                                                text: '',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, false, true, true]

                                        },
                                        {
                                                text: ' ',
                                                style: 'tableheader',
                                                alignment: 'center',
                                                border: [true, false, true, true],
                                                colSpan: 2

                                        },
                                        {
                                                text: '',
                                                style: 'textfooter'
                                        },
                                ]
                        ]
                        var tablemain = []
                        headertablemain.forEach(x => tablemain.push(x))
                        return_main.forEach(x => tablemain.push(x))
                        footertablemain.forEach(x => tablemain.push(x))

                        await Monthlyreport.CreatePRPPdf(month, monthname, year, oid, tablemain, FullName)
                }
                if (oid == 6)//"PTTEP"
                {
                        var res = await feathersClientUOA
                                .service("monthlypttuoc")
                                .find({ query: q });
                        var return_uoc = await Monthlyreport.getdataPTTEP(res[0], month, year, oid);
                        var return_main =[
                                [
                                        {text:"Date",rowSpan:2,margin:[0,12,0,0]},
                                        {text:"Status to Upload",rowSpan:2,margin:[0,4,0,0]} ,
                                        {text:"Flt. NO.",rowSpan:2,margin:[0,12,0,0]} ,
                                        {text:"Flt. Report No.",rowSpan:2,margin:[0,12,0,0]} ,
                                      {text:"FlightTime",colSpan:2},"",{text:"Passenger",colSpan:2},"",{text:"Fuel",colSpan:3},"","",{text:"payload outbound",colSpan:2},"",{text:"Payload Inbound",colSpan:4},"","","",{text:"route",rowSpan:2,margin:[0,12,0,0]}
                                ],[
                                        "","","","",{text:"Revernue",margin:[0,6,0,0]},{text:"Non-Revernue",margin:[0,3,0,0]},{text:"Out",margin:[0,6,0,0]},{text:"In",margin:[0,6,0,0]},{text:"Revernue",margin:[0,6,0,0]},{text:"Non-Revernue",margin:[0,3,0,0]},"Fuel for Outbound Trip(Lts.)",{text:"Available",margin:[0,6,0,0]},{text:"Actual",margin:[0,6,0,0]},{text:"Available",margin:[0,6,0,0]},{text:"Actual",margin:[0,6,0,0]},"Pttep request avaliable","Different from Actual",""
                                ]
                        ]
                        
                        var return_main_total = []
                        await Monthlyreport.CreatePTTEPdf(month, monthname, year, oid, return_main, return_main_total, FullName)

                }

                return true

        },
        pad: (d) => {
                return (d < 10) ? '0' + d.toString() : d.toString();
        },
        getdata: async (res, month, year, oid) => {
                var datatable = [];
                var datarow = [];
                var servicename = "";
                var dayinmonth = Monthlyreport.DaysInMonth(month, year);
                var i = 0;
                var day = 1;
                var monthMMM = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
                        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
                var selectedMonthName = monthMMM[month - 1];

                var pax_out = 0;
                var pax_in = 0;
                var fuel = 0;
                var fuel_non = 0
                while (true) {

                        datarow = [];
                        var datadate = "";
                        var ac = "";
                        var flightno = "";
                        var revenue = "";
                        var nonrevenue = "";
                        var out = "";
                        var in1 = "";
                        var fuelrevenue = "";
                        var fuelnonrevenue = "";
                        var stringyear = year.toString();
                        var datadate = day + "-" + selectedMonthName + "-" + stringyear.substring(4, 2);
                        var ac = "";


                        if (i < res.length) {


                                if (day < res[i].this_day) {
                                        if (oid == 4) {
                                                flightno = "No Flight Operate";
                                                revenue = " ";
                                                nonrevenue = " ";
                                                out = " ";
                                                in1 = " ";
                                                fuelrevenue = " ";
                                                fuelnonrevenue = " ";
                                                all_location = "STANDBY";
                                                day = day + 1;
                                                ac = "N/A";
                                        }
                                        if (oid == 5) {
                                                flightno = "No Flight Operate";
                                                revenue = " ";
                                                nonrevenue = " ";
                                                out = " ";
                                                in1 = " ";
                                                fuelrevenue = " ";
                                                fuelnonrevenue = " ";
                                                all_location = " ";
                                                day = day + 1;
                                                ac = "N/A";
                                        }
                                        if (oid == 1 || oid == 3|| oid==6) {
                                                flightno = "No Flight Operate";
                                                revenue = " ";
                                                nonrevenue = " ";
                                                out = " ";
                                                in1 = " ";
                                                fuelrevenue = " ";
                                                fuelnonrevenue = " ";
                                                all_location = " ";
                                                day = day + 1
                                        }
                                        if (oid == 2) {
                                                flightno = "No Flight Operate";
                                                revenue = " ";
                                                nonrevenue = " ";
                                                out = " ";
                                                in1 = " ";
                                                fuelrevenue = " ";
                                                fuelnonrevenue = " ";
                                                all_location = " ";
                                                ac = "HS-UOL"
                                                day = day + 1
                                        }
                                        if (oid == 9999) {
                                                flightno = "No Flight Operate";
                                                revenue = " ";
                                                nonrevenue = " ";
                                                out = " ";
                                                in1 = " ";
                                                fuelrevenue = " ";
                                                fuelnonrevenue = " ";
                                                all_location = " ";
                                                ac = "Back Up"
                                                day = day + 1
                                        }

                                }
                                else {
                                        var start_location_arr = res[i].start_location.split("-");
                                        var start_location = start_location_arr[0]
                                        var all_location = ""
                                        if (oid == 5 || oid == 6) {
                                                all_location = start_location + " - " + res[i].end_location + ", STANDBY"
                                        }
                                        else {
                                                all_location = start_location + " - " + res[i].end_location
                                        }
                                        flightno = res[i].f_flight_no;
                                        revenue = res[i].revenue;
                                        nonrevenue = res[i].non_revenue;
                                        out = res[i].pax_out;
                                        in1 = res[i].pax_in;
                                        fuelrevenue = res[i].fuel_revenue;
                                        fuelnonrevenue = res[i].fuel_non_revenue;
                                        ac = res[i].ac_name;
                                        i = i + 1
                                        if (i < res.length) {
                                                if ((day < res[i].this_day)) {
                                                        day = day + 1;
                                                }
                                        }
                                        else {
                                                day = day + 1
                                        }

                                }
                        } else {
                                if (oid == 4) {
                                        flightno = "No Flight Operate";
                                        revenue = " ";
                                        nonrevenue = " ";
                                        out = " ";
                                        in1 = " ";
                                        fuelrevenue = " ";
                                        fuelnonrevenue = " ";
                                        all_location = "STANDBY";
                                        day = day + 1;
                                        ac = "N/A";
                                }
                                if (oid == 5) {
                                        flightno = "No Flight Operate";
                                        revenue = " ";
                                        nonrevenue = " ";
                                        out = " ";
                                        in1 = " ";
                                        fuelrevenue = " ";
                                        fuelnonrevenue = " ";
                                        all_location = " ";
                                        day = day + 1;
                                        ac = "N/A";
                                }
                                if (oid == 1 || oid == 3 || oid == 6) {
                                        flightno = "No Flight Operate";
                                        revenue = " ";
                                        nonrevenue = " ";
                                        out = " ";
                                        in1 = " ";
                                        fuelrevenue = " ";
                                        fuelnonrevenue = " ";
                                        all_location = " ";
                                        day = day + 1
                                }
                                if (oid == 2) {
                                        flightno = "No Flight Operate";
                                        revenue = " ";
                                        nonrevenue = " ";
                                        out = " ";
                                        in1 = " ";
                                        fuelrevenue = " ";
                                        fuelnonrevenue = " ";
                                        all_location = " ";
                                        ac = "HS-UOL"
                                        day = day + 1
                                }
                                if (oid == 9999) {
                                        flightno = "No Flight Operate";
                                        revenue = " ";
                                        nonrevenue = " ";
                                        out = " ";
                                        in1 = " ";
                                        fuelrevenue = " ";
                                        fuelnonrevenue = " ";
                                        all_location = " ";
                                        ac = "Back Up"
                                        day = day + 1
                                }
                        }
                        datarow.push(
                                {
                                        text: datadate,
                                        style: 'tabledata',
                                        alignment: 'center'

                                },
                                {
                                        text: ac,
                                        style: 'tabledata',
                                        alignment: 'center',
                                        bold: 'True'

                                },
                                {
                                        text: flightno,
                                        style: 'tabledata',
                                        alignment: 'center'

                                },
                                {
                                        text: revenue,
                                        style: 'tabledata',
                                        alignment: 'center',

                                },
                                {
                                        text: nonrevenue,
                                        style: 'tabledata',
                                        alignment: 'center'

                                },
                                {
                                        text: out,
                                        style: 'tabledata',
                                        alignment: 'center',

                                },
                                {
                                        text: in1,
                                        style: 'tabledata',
                                        alignment: 'center'

                                },
                                {
                                        text: fuelrevenue,
                                        style: 'tabledata',
                                        alignment: 'center',

                                },
                                {
                                        text: fuelnonrevenue,
                                        style: 'tabledata',
                                        alignment: 'center'

                                },
                                {
                                        text: all_location,
                                        style: 'tabledataremark',
                                        alignment: 'center'

                                }
                        );
                        datatable.push(datarow);
                        if (day >= dayinmonth + 1) {
                                break;
                        }

                }
                return datatable;
        },
        getdataCpoc: async (res, month, year, isMainAircarft) => {
                var datatable = [];
                var datarow = [];
                var dayinmonth = Monthlyreport.DaysInMonth(month, year);
                var i = 0;
                var day = 1;
                var monthMMM = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
                        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
                var selectedMonthName = monthMMM[month - 1];
                var fuel = 0;
                while (true) {

                        datarow = [];
                        var datadate = "";
                        var ac = "";
                        var flightno = "";
                        var revenue = "";
                        var nonrevenue = "";
                        var standby = "";
                        var out = "";
                        var in1 = "";
                        var fuelvtsh = "";
                        var fuelmdlq = ""
                        var fuelaqd = ""
                        var fuelnonrevenue = "";
                        var payload_out_available = "";
                        var payload_out_actual = "";
                        var payload_in_available = "";
                        var payload_in_actual = "";
                        var stringyear = year.toString();
                        var datadate = day + "-" + selectedMonthName + "-" + stringyear.substring(4, 2);
                        var ac = "";



                        if (i < res.length) {


                                if (day < res[i].this_day) {
                                        if (isMainAircarft) {
                                                flightno = "No Flight Operate";
                                                revenue = " ";
                                                nonrevenue = " ";
                                                standby = " ";
                                                out = " ";
                                                in1 = " ";
                                                fuelvtsh = " ";
                                                fuelmdlq = ""
                                                fuelaqd = ""
                                                fuelnonrevenue = " ";
                                                payload_out_available = "0";
                                                payload_out_actual = "0";
                                                payload_in_available = "0";
                                                payload_in_actual = "0";
                                                all_location = " ";
                                                ac = "HS-UOL"
                                                day = day + 1
                                        }
                                        if (!isMainAircarft) {
                                                flightno = "No Flight Operate";
                                                revenue = " ";
                                                nonrevenue = " ";
                                                standby = " ";
                                                out = " ";
                                                in1 = " ";
                                                fuelvtsh = " ";
                                                fuelmdlq = ""
                                                fuelaqd = ""
                                                fuelnonrevenue = " ";
                                                payload_out_available = "0";
                                                payload_out_actual = "0";
                                                payload_in_available = "0";
                                                payload_in_actual = "0";
                                                all_location = " ";
                                                ac = "Back Up"
                                                day = day + 1
                                        }

                                }
                                else {
                                        var start_location_arr = res[i].start_location.split("-");
                                        var start_location = start_location_arr[0]
                                        var all_location = ""

                                        all_location = start_location + " - " + res[i].end_location

                                        flightno = res[i].f_flight_no;
                                        revenue = res[i].revenue;
                                        nonrevenue = res[i].non_revenue;
                                        out = res[i].pax_out;
                                        in1 = res[i].pax_in;
                                        fuelvtsh = res[i].fuel_revenue_vtsh;
                                        fuelmdlq = res[i].fuel_revenue_mdlq;
                                        fuelaqd = res[i].fuel_revenue_aqd;
                                        fuelnonrevenue = res[i].fuel_non_revenue;
                                        payload_out_available = res[i].payload_out_available;
                                        payload_out_actual = res[i].payload_out_actual;
                                        payload_in_available = res[i].payload_in_available;
                                        payload_in_actual = res[i].payload_in_actual;
                                        ac = res[i].ac_name;
                                        if (res[i].standby_time != null) {
                                                standby = res[i].standby_time;
                                        }
                                        else {
                                                standby = "00:00"
                                        }
                                        i = i + 1
                                        if (i < res.length) {
                                                if ((day < res[i].this_day)) {
                                                        day = day + 1;
                                                }
                                        }
                                        else {
                                                day = day + 1
                                        }
                                        

                                }
                        } else {
                                if (isMainAircarft) {
                                        flightno = "No Flight Operate";
                                        revenue = " ";
                                        nonrevenue = " ";
                                        standby = " ";
                                        out = " ";
                                        in1 = " ";
                                        fuelvtsh = " ";
                                        fuelmdlq = ""
                                        fuelaqd = ""
                                        fuelnonrevenue = " ";
                                        all_location = " ";
                                        payload_out_available = "0";
                                        payload_out_actual = "0";
                                        payload_in_available = "0";
                                        payload_in_actual = "0";
                                        ac = "HS-UOL"
                                        day = day + 1
                                }
                                if (!isMainAircarft) {
                                        flightno = "No Flight Operate";
                                        revenue = " ";
                                        nonrevenue = " ";
                                        standby = " ";
                                        out = " ";
                                        in1 = " ";
                                        fuelvtsh = " ";
                                        fuelmdlq = ""
                                        fuelaqd = ""
                                        fuelnonrevenue = " ";
                                        all_location = " ";
                                        payload_out_available = "0";
                                        payload_out_actual = "0";
                                        payload_in_available = "0";
                                        payload_in_actual = "0";
                                        ac = "Back Up"
                                        day = day + 1
                                }
                        }
                        datarow.push(
                                {
                                        text: datadate,
                                        style: 'tabledata',
                                        alignment: 'center'

                                },
                                {
                                        text: ac,
                                        style: 'tabledata',
                                        alignment: 'center',
                                        bold: 'True'

                                },
                                {
                                        text: flightno,
                                        style: 'tabledata',
                                        alignment: 'center',
                                        colSpan: 2

                                },
                                {
                                        text: 'flightno',
                                        style: 'tabledata',
                                        alignment: 'center',

                                },
                                {
                                        text: revenue,
                                        style: 'tabledata',
                                        alignment: 'center',

                                },
                                {
                                        text: nonrevenue,
                                        style: 'tabledata',
                                        alignment: 'center'

                                },
                                {
                                        text: standby,
                                        style: 'tabledata',
                                        alignment: 'center'
                                },
                                {
                                        text: out,
                                        style: 'tabledata',
                                        alignment: 'center',

                                },
                                {
                                        text: in1,
                                        style: 'tabledata',
                                        alignment: 'center'

                                },
                                {
                                        text: fuelvtsh,
                                        style: 'tabledata',
                                        alignment: 'center',

                                },
                                {
                                        text: fuelmdlq,
                                        style: 'tabledata',
                                        alignment: 'center',

                                },
                                {
                                        text: fuelaqd,
                                        style: 'tabledata',
                                        alignment: 'center',

                                },
                                {
                                        text: fuelnonrevenue,
                                        style: 'tabledata',
                                        alignment: 'center'

                                },

                                {
                                        text: payload_out_available,
                                        style: 'tabledata',
                                        alignment: 'center'

                                },
                                {
                                        text: payload_out_actual,
                                        style: 'tabledata',
                                        alignment: 'center'

                                },
                                {
                                        text: payload_in_available,
                                        style: 'tabledata',
                                        alignment: 'center'

                                },
                                {
                                        text: payload_in_actual,
                                        style: 'tabledata',
                                        alignment: 'center',
                                        colSpan: 2

                                },
                                {
                                        text: '',
                                        style: 'tabledata',
                                        alignment: 'center',

                                },
                                {
                                        text: all_location,
                                        style: 'tabledataremark',
                                        alignment: 'center',
                                        colSpan: 2

                                },
                                {
                                        text: '',
                                        style: 'textfooter'
                                },
                        );
                        datatable.push(datarow);
                        if (day >= dayinmonth + 1) {
                                break;
                        }

                }
                return datatable;
        },
        getdataChoc: async (res, month, year, isMainAircarft) => {
                var datatable = [];
                var datarow = [];
                var dayinmonth = Monthlyreport.DaysInMonth(month, year);
                var i = 0;
                var day = 1;
                var monthMMM = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
                        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
                var selectedMonthName = monthMMM[month - 1];
                var fuel = 0;
                while (true) {

                        datarow = [];
                        var datadate = "";
                        var ac = "";
                        var flightno = "";
                        var revenue = "";
                        var nonrevenue = "";
                        var standby = "";
                        var out = "";
                        var in1 = "";
                        var fuelvtsh = "";
                        var fuelnonrevenue = "";
                        var payload_out_available = "";
                        var payload_out_actual = "";
                        var payload_in_available = "";
                        var payload_in_actual = "";
                        var stringyear = year.toString();
                        var datadate = day + "-" + selectedMonthName + "-" + stringyear.substring(4, 2);
                        var ac = "";



                        if (i < res.length) {


                                if (day < res[i].this_day) {
                                        if (isMainAircarft) {
                                                flightno = "No Flight Operate";
                                                revenue = " ";
                                                nonrevenue = " ";
                                                standby = " ";
                                                out = " ";
                                                in1 = " ";
                                                fuelvtsh = " ";
                                                fuelnonrevenue = " ";
                                                payload_out_available = "0";
                                                payload_out_actual = "0";
                                                payload_in_available = "0";
                                                payload_in_actual = "0";
                                                all_location = " ";
                                                ac = "HS-UOL"
                                                day = day + 1
                                        }
                                        if (!isMainAircarft) {
                                                flightno = "No Flight Operate";
                                                revenue = " ";
                                                nonrevenue = " ";
                                                standby = " ";
                                                out = " ";
                                                in1 = " ";
                                                fuelvtsh = " ";
                                                fuelnonrevenue = " ";
                                                payload_out_available = "0";
                                                payload_out_actual = "0";
                                                payload_in_available = "0";
                                                payload_in_actual = "0";
                                                all_location = " ";
                                                ac = "Back Up"
                                                day = day + 1
                                        }

                                }
                                else {
                                        var start_location_arr = res[i].start_location.split("-");
                                        var start_location = start_location_arr[0]
                                        var all_location = ""

                                        all_location = start_location + " - " + res[i].end_location

                                        flightno = res[i].f_flight_no;
                                        revenue = res[i].revenue;
                                        nonrevenue = res[i].non_revenue;
                                        out = res[i].pax_out;
                                        in1 = res[i].pax_in;
                                        fuelvtsh = res[i].fuel_revenue_vtsh;
                                        fuelnonrevenue = res[i].fuel_non_revenue;
                                        payload_out_available = res[i].payload_out_available;
                                        payload_out_actual = res[i].payload_out_actual;
                                        payload_in_available = res[i].payload_in_available;
                                        payload_in_actual = res[i].payload_in_actual;
                                        ac = res[i].ac_name;
                                        if (res[i].standby_time != null) {
                                                standby = res[i].standby_time;
                                        }
                                        else {
                                                standby = "00:00"
                                        }
                                        i = i + 1
                                        if (i < res.length) {
                                                if ((day < res[i].this_day)) {
                                                        day = day + 1;
                                                }
                                        }
                                        else {
                                                day = day + 1
                                        }
                                        

                                }
                        } else {
                                if (isMainAircarft) {
                                        flightno = "No Flight Operate";
                                        revenue = " ";
                                        nonrevenue = " ";
                                        standby = " ";
                                        out = " ";
                                        in1 = " ";
                                        fuelvtsh = " ";
                                        fuelnonrevenue = " ";
                                        all_location = " ";
                                        payload_out_available = "0";
                                        payload_out_actual = "0";
                                        payload_in_available = "0";
                                        payload_in_actual = "0";
                                        ac = "HS-UOL"
                                        day = day + 1
                                }
                                if (!isMainAircarft) {
                                        flightno = "No Flight Operate";
                                        revenue = " ";
                                        nonrevenue = " ";
                                        standby = " ";
                                        out = " ";
                                        in1 = " ";
                                        fuelvtsh = " ";
                                        fuelnonrevenue = " ";
                                        all_location = " ";
                                        payload_out_available = "0";
                                        payload_out_actual = "0";
                                        payload_in_available = "0";
                                        payload_in_actual = "0";
                                        ac = "Back Up"
                                        day = day + 1
                                }
                        }
                        datarow.push(
                                {
                                        text: datadate,
                                        style: 'tabledata',
                                        alignment: 'center'

                                },
                                {
                                        text: ac,
                                        style: 'tabledata',
                                        alignment: 'center',
                                        bold: 'True'

                                },
                                {
                                        text: flightno,
                                        style: 'tabledata',
                                        alignment: 'center',
                                        colSpan: 2

                                },
                                {
                                        text: 'flightno',
                                        style: 'tabledata',
                                        alignment: 'center',

                                },
                                {
                                        text: revenue,
                                        style: 'tabledata',
                                        alignment: 'center',

                                },
                                {
                                        text: nonrevenue,
                                        style: 'tabledata',
                                        alignment: 'center'

                                },
                                {
                                        text: standby,
                                        style: 'tabledata',
                                        alignment: 'center'
                                },
                                {
                                        text: out,
                                        style: 'tabledata',
                                        alignment: 'center',

                                },
                                {
                                        text: in1,
                                        style: 'tabledata',
                                        alignment: 'center'

                                },
                                {
                                        text: fuelvtsh,
                                        style: 'tabledata',
                                        alignment: 'center',

                                },

                                {
                                        text: fuelnonrevenue,
                                        style: 'tabledata',
                                        alignment: 'center'

                                },

                                {
                                        text: payload_out_available,
                                        style: 'tabledata',
                                        alignment: 'center'

                                },
                                {
                                        text: payload_out_actual,
                                        style: 'tabledata',
                                        alignment: 'center'

                                },
                                {
                                        text: payload_in_available,
                                        style: 'tabledata',
                                        alignment: 'center'

                                },
                                {
                                        text: payload_in_actual,
                                        style: 'tabledata',
                                        alignment: 'center',
                                        colSpan: 2

                                },
                                {
                                        text: '',
                                        style: 'tabledata',
                                        alignment: 'center',

                                },
                                {
                                        text: all_location,
                                        style: 'tabledataremark',
                                        alignment: 'center',
                                        colSpan: 2

                                },
                                {
                                        text: '',
                                        style: 'textfooter'
                                },
                        );
                        datatable.push(datarow);
                        if (day >= dayinmonth + 1) {
                                break;
                        }

                }
                return datatable;
        },
        getdataPrp: async (res, month, year, isMainAircarft) => {
                var datatable = [];
                var datarow = [];
                var dayinmonth = Monthlyreport.DaysInMonth(month, year);
                var i = 0;
                var day = 1;
                var monthMMM = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
                        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
                var selectedMonthName = monthMMM[month - 1];
                var fuel = 0;
                while (true) {

                        datarow = [];
                        var datadate = "";
                        var ac = "";
                        var flightno = "";
                        var revenue = "";
                        var nonrevenue = "";
                        var standby = "";
                        var out = "";
                        var in1 = "";
                        var fuelvtsh = "";
                        var fuelnonrevenue = "";
                        var payload_out_available = "";
                        var payload_out_actual = "";
                        var payload_in_available = "";
                        var payload_in_actual = "";
                        var stringyear = year.toString();
                        var datadate = day + "-" + selectedMonthName + "-" + stringyear.substring(4, 2);
                        var ac = "";



                        if (i < res.length) {


                                if (day < res[i].this_day) {

                                        day = day + 1;
                                        ac = "N/A";
                                        flightno = "No Flight Operate";
                                        revenue = " ";
                                        nonrevenue = " ";
                                        standby = " ";
                                        out = " ";
                                        in1 = " ";
                                        fuelvtsh = " ";
                                        fuelnonrevenue = " ";
                                        payload_out_available = "0";
                                        payload_out_actual = "0";
                                        payload_in_available = "0";
                                        payload_in_actual = "0";
                                        all_location = " ";


                                }
                                else {
                                        var start_location_arr = res[i].start_location.split("-");
                                        var start_location = start_location_arr[0]
                                        var all_location = ""

                                        all_location = start_location + " - " + res[i].end_location

                                        flightno = res[i].f_flight_no;
                                        revenue = res[i].revenue;
                                        nonrevenue = res[i].non_revenue;
                                        out = res[i].pax_out;
                                        in1 = res[i].pax_in;
                                        fuelvtsh = res[i].fuel_revenue;
                                        fuelnonrevenue = res[i].fuel_non_revenue;
                                        payload_out_available = res[i].payload_out_available;
                                        payload_out_actual = res[i].payload_out_actual;
                                        payload_in_available = res[i].payload_in_available;
                                        payload_in_actual = res[i].payload_in_actual;
                                        ac = res[i].ac_name;
                                        if (res[i].standby_time != null) {
                                                standby = res[i].standby_time;
                                        }
                                        else {
                                                standby = "00:00"
                                        }
                                        i = i + 1
                                        if (i < res.length) {
                                                if ((day < res[i].this_day)) {
                                                        day = day + 1;
                                                }
                                        }
                                        else {
                                                day = day + 1
                                        }
                                        

                                }
                        } else {
                                day = day + 1;
                                ac = "N/A";
                                flightno = "No Flight Operate";
                                revenue = " ";
                                nonrevenue = " ";
                                standby = " ";
                                out = " ";
                                in1 = " ";
                                fuelvtsh = " ";
                                fuelnonrevenue = " ";
                                payload_out_available = "0";
                                payload_out_actual = "0";
                                payload_in_available = "0";
                                payload_in_actual = "0";
                                all_location = " ";
                        }
                        datarow.push(
                                {
                                        text: datadate,
                                        style: 'tabledata',
                                        alignment: 'center'

                                },
                                {
                                        text: ac,
                                        style: 'tabledata',
                                        alignment: 'center',
                                        bold: 'True'

                                },
                                {
                                        text: flightno,
                                        style: 'tabledata',
                                        alignment: 'center',
                                        colSpan: 2

                                },
                                {
                                        text: 'flightno',
                                        style: 'tabledata',
                                        alignment: 'center',

                                },
                                {
                                        text: revenue,
                                        style: 'tabledata',
                                        alignment: 'center',

                                },
                                {
                                        text: nonrevenue,
                                        style: 'tabledata',
                                        alignment: 'center'

                                },
                                {
                                        text: standby,
                                        style: 'tabledata',
                                        alignment: 'center'
                                },
                                {
                                        text: out,
                                        style: 'tabledata',
                                        alignment: 'center',

                                },
                                {
                                        text: in1,
                                        style: 'tabledata',
                                        alignment: 'center'

                                },
                                {
                                        text: fuelvtsh,
                                        style: 'tabledata',
                                        alignment: 'center',

                                },

                                {
                                        text: fuelnonrevenue,
                                        style: 'tabledata',
                                        alignment: 'center'

                                },

                                {
                                        text: payload_out_available,
                                        style: 'tabledata',
                                        alignment: 'center'

                                },
                                {
                                        text: payload_out_actual,
                                        style: 'tabledata',
                                        alignment: 'center'

                                },
                                {
                                        text: payload_in_available,
                                        style: 'tabledata',
                                        alignment: 'center'

                                },
                                {
                                        text: payload_in_actual,
                                        style: 'tabledata',
                                        alignment: 'center',
                                        colSpan: 2

                                },
                                {
                                        text: '',
                                        style: 'tabledata',
                                        alignment: 'center',

                                },
                                {
                                        text: all_location,
                                        style: 'tabledataremark',
                                        alignment: 'center',
                                        colSpan: 2

                                },
                                {
                                        text: '',
                                        style: 'textfooter'
                                },
                        );
                        datatable.push(datarow);
                        if (day >= dayinmonth + 1) {
                                break;
                        }

                }
                return datatable;
        },
        getdataMUBADALAVTBU: async (res, month, year) => {
                var datatable = [];
                var datarow = [];
                var servicename = "";
                var dayinmonth = Monthlyreport.DaysInMonth(month, year);
                var i = 0;
                var day = 1;
                var monthMMM = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
                        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
                var selectedMonthName = monthMMM[month - 1];

                var pax_out = 0;
                var pax_in = 0;
                var fuel = 0;
                var fuel_non = 0
                while (true) {

                        datarow = [];
                        var datadate = "";
                        var ac = "";
                        var flightno = "";
                        var revenue = "";
                        var nonrevenue = "";
                        var out = "";
                        var in1 = "";
                        var fuelrevenue = "";
                        var fuelnonrevenue = "";
                        var stringyear = year.toString();
                        var datadate = day + "-" + selectedMonthName + "-" + stringyear.substring(4, 2);
                        var ac = "";
                        var revenue_part = "";
                        var non_revenue_part = "";
                        var payload_out_available = "";
                        var payload_out_actual = "";
                        var payload_in_available = "";
                        var payload_in_actual = "";
                        var mileage = ""



                        if (i < res.length) {


                                if (day < res[i].this_day) {
                                        flightno = "No Flight Operate";
                                        revenue = " ";
                                        revenue_part = "";
                                        nonrevenue = " ";
                                        non_revenue_part = "";
                                        out = " ";
                                        in1 = " ";
                                        payload_out_available = "0";
                                        payload_out_actual = "0";
                                        payload_in_available = "0";
                                        payload_in_actual = "0";
                                        fuelrevenue = " ";
                                        fuelnonrevenue = " ";
                                        all_location = "STANDBY";
                                        day = day + 1;
                                        ac = "N/A";
                                        mileage = "0"


                                }
                                else {
                                        var start_location_arr = res[i].start_location.split("-");
                                        var start_location = start_location_arr[0]
                                        var all_location = ""

                                        all_location = start_location + " - " + res[i].end_location
                                        flightno = res[i].f_flight_no;
                                        revenue = res[i].revenue;
                                        nonrevenue = res[i].non_revenue;
                                        out = res[i].pax_out;
                                        in1 = res[i].pax_in;
                                        fuelrevenue = res[i].fuel_revenue;
                                        fuelnonrevenue = res[i].fuel_non_revenue;
                                        revenue_part = res[i].revenue_part;
                                        non_revenue_part = res[i].non_revenue_part;
                                        payload_out_available = res[i].payload_out_available;
                                        payload_out_actual = res[i].payload_out_actual;
                                        payload_in_available = res[i].payload_in_available;
                                        payload_in_actual = res[i].payload_in_actual;
                                        mileage = res[i].mileage
                                        ac = res[i].ac_name;
                                        i = i + 1
                                        if (i < res.length) {
                                                if ((day < res[i].this_day)) {
                                                        day = day + 1;
                                                }
                                        }
                                        else {
                                                day = day + 1
                                        }
                                }
                        } else {
                                flightno = "No Flight Operate";
                                revenue = " ";
                                revenue_part = "";
                                nonrevenue = " ";
                                non_revenue_part = "";
                                out = " ";
                                in1 = " ";
                                payload_out_available = "0";
                                payload_out_actual = "0";
                                payload_in_available = "0";
                                payload_in_actual = "0";
                                fuelrevenue = " ";
                                fuelnonrevenue = " ";
                                all_location = "STANDBY";
                                day = day + 1;
                                ac = "N/A";
                                mileage = "0"
                        }
                        datarow.push(
                                {
                                        text: datadate,
                                        style: 'tabledata',
                                        alignment: 'center'

                                },
                                {
                                        text: ac,
                                        style: 'tabledata',
                                        alignment: 'center',
                                        bold: 'True'

                                },
                                {
                                        text: flightno,
                                        style: 'tabledata',
                                        alignment: 'center'

                                },
                                {
                                        text: revenue,
                                        style: 'tabledata',
                                        alignment: 'center',

                                },
                                {
                                        text: revenue_part,
                                        style: 'tabledata',
                                        alignment: 'center',

                                },
                                {
                                        text: nonrevenue,
                                        style: 'tabledata',
                                        alignment: 'center'

                                },
                                {
                                        text: non_revenue_part,
                                        style: 'tabledata',
                                        alignment: 'center'

                                },
                                {
                                        text: " ",
                                        style: 'tabledata',
                                        alignment: 'center',

                                },

                                {
                                        text: out,
                                        style: 'tabledata',
                                        alignment: 'center',

                                },
                                {
                                        text: in1,
                                        style: 'tabledata',
                                        alignment: 'center'

                                },
                                {
                                        text: fuelrevenue,
                                        style: 'tabledata',
                                        alignment: 'center',

                                },
                                {
                                        text: fuelnonrevenue,
                                        style: 'tabledata',
                                        alignment: 'center'

                                },
                                {
                                        text: payload_out_available,
                                        style: 'tabledata',
                                        alignment: 'center'

                                },
                                {
                                        text: payload_out_actual,
                                        style: 'tabledata',
                                        alignment: 'center'

                                },
                                {
                                        text: payload_in_available,
                                        style: 'tabledata',
                                        alignment: 'center'

                                },
                                {
                                        text: payload_in_actual,
                                        style: 'tabledata',
                                        alignment: 'center'

                                },
                                {
                                        text: all_location,
                                        style: 'tabledataremark',
                                        alignment: 'center'

                                },
                                {
                                        text: mileage,
                                        style: 'tabledata',
                                        alignment: 'center'

                                },
                        );
                        datatable.push(datarow);
                        if (day >= dayinmonth + 1) {
                                break;
                        }

                }
                return datatable;
        },
        getdataMUBADALAVTSH: async (res, month, year) => {
                var datatable = [];
                var datarow = [];
                var servicename = "";
                var dayinmonth = Monthlyreport.DaysInMonth(month, year);
                var i = 0;
                var day = 1;
                var monthMMM = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
                        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
                var selectedMonthName = monthMMM[month - 1];

                var pax_out = 0;
                var pax_in = 0;
                var fuel = 0;
                var fuel_non = 0
                while (true) {

                        datarow = [];
                        var datadate = "";
                        var ac = "";
                        var flightno = "";
                        var revenue = "";
                        var nonrevenue = "";
                        var out = "";
                        var in1 = "";
                        var fuelrevenue = "";
                        var fuelnonrevenue = "";
                        var stringyear = year.toString();
                        var datadate = day + "-" + selectedMonthName + "-" + stringyear.substring(4, 2);
                        var ac = "";
                        var revenue_part = "";
                        var non_revenue_part = "";
                        var payload_out_available = "";
                        var payload_out_actual = "";
                        var payload_in_available = "";
                        var payload_in_actual = "";
                        var mileage = ""



                        if (i < res.length) {


                                if (day < res[i].this_day) {
                                        flightno = "No Flight Operate";
                                        revenue = " ";
                                        nonrevenue = " ";
                                        out = " ";
                                        in1 = " ";
                                        payload_out_available = "0";
                                        payload_out_actual = "0";
                                        payload_in_available = "0";
                                        payload_in_actual = "0";
                                        fuelrevenue = " ";
                                        fuelnonrevenue = " ";
                                        all_location = "STANDBY";
                                        day = day + 1;
                                        ac = "N/A";
                                        mileage = "0"


                                }
                                else {
                                        var start_location_arr = res[i].start_location.split("-");
                                        var start_location = start_location_arr[0]
                                        var all_location = ""

                                        all_location = start_location + " - " + res[i].end_location
                                        flightno = res[i].f_flight_no;
                                        revenue = res[i].revenue;
                                        nonrevenue = res[i].non_revenue;
                                        out = res[i].pax_out;
                                        in1 = res[i].pax_in;
                                        fuelrevenue = res[i].fuel_revenue;
                                        fuelnonrevenue = res[i].fuel_non_revenue;
                                        payload_out_available = res[i].payload_out_available;
                                        payload_out_actual = res[i].payload_out_actual;
                                        payload_in_available = res[i].payload_in_available;
                                        payload_in_actual = res[i].payload_in_actual;
                                        mileage = res[i].mileage
                                        ac = res[i].ac_name;
                                        i = i + 1
                                        if (i < res.length) {
                                                if ((day < res[i].this_day)) {
                                                        day = day + 1;
                                                }
                                        }
                                        else {
                                                day = day + 1
                                        }

                                }
                        } else {
                                flightno = "No Flight Operate";
                                revenue = " ";
                                nonrevenue = " ";
                                out = " ";
                                in1 = " ";
                                payload_out_available = "0";
                                payload_out_actual = "0";
                                payload_in_available = "0";
                                payload_in_actual = "0";
                                fuelrevenue = " ";
                                fuelnonrevenue = " ";
                                all_location = "STANDBY";
                                day = day + 1;
                                ac = "N/A";
                                mileage = "0"
                        }
                        datarow.push(
                                {
                                        text: datadate,
                                        style: 'tabledata',
                                        alignment: 'center'

                                },
                                {
                                        text: ac,
                                        style: 'tabledata',
                                        alignment: 'center',
                                        bold: 'True'

                                },
                                {
                                        text: flightno,
                                        style: 'tabledata',
                                        alignment: 'center'

                                },
                                {
                                        text: revenue,
                                        style: 'tabledata',
                                        alignment: 'center',

                                },
                                {
                                        text: nonrevenue,
                                        style: 'tabledata',
                                        alignment: 'center'

                                },
                                {
                                        text: " ",
                                        style: 'tabledata',
                                        alignment: 'center',

                                },

                                {
                                        text: out,
                                        style: 'tabledata',
                                        alignment: 'center',

                                },
                                {
                                        text: in1,
                                        style: 'tabledata',
                                        alignment: 'center'

                                },
                                {
                                        text: fuelrevenue,
                                        style: 'tabledata',
                                        alignment: 'center',

                                },
                                {
                                        text: fuelnonrevenue,
                                        style: 'tabledata',
                                        alignment: 'center'

                                },
                                {
                                        text: payload_out_available,
                                        style: 'tabledata',
                                        alignment: 'center'

                                },
                                {
                                        text: payload_out_actual,
                                        style: 'tabledata',
                                        alignment: 'center'

                                },
                                {
                                        text: payload_in_available,
                                        style: 'tabledata',
                                        alignment: 'center'

                                },
                                {
                                        text: payload_in_actual,
                                        style: 'tabledata',
                                        alignment: 'center'

                                },
                                {
                                        text: all_location,
                                        style: 'tabledataremark',
                                        alignment: 'center'

                                },
                                {
                                        text: mileage,
                                        style: 'tabledata',
                                        alignment: 'center'

                                },
                        );
                        datatable.push(datarow);
                        if (day >= dayinmonth + 1) {
                                break;
                        }

                }
                return datatable;
        },
        getdataPTTEP: async (res, month, year) =>{
                var keys = Object.keys(res);
                var result = {}
        },
        gettotaldata: async (res) => {
                var pax_out = 0;
                var pax_in = 0;
                var fuel = 0;
                var fuel_non = 0
                var hour_time_revenue = 0
                var minute_time_revenue = 0
                var hour_time_non_revenue = 0
                var minute_time_non_revenue = 0;
                var flight = 0
                for (var i = 0; i < res.length; i = i + 1) {
                        pax_out = pax_out + res[i].pax_out;
                        pax_in = pax_in + res[i].pax_in;
                        fuel = fuel + res[i].fuel_revenue;
                        fuel_non = fuel_non + res[i].fuel_non_revenue;
                        hour_time_revenue = parseInt(res[0].time_revenue / 60);
                        minute_time_revenue = res[0].time_revenue % 60;
                        hour_time_non_revenue = parseInt(res[0].time_non_revenue / 60)
                        minute_time_non_revenue = res[0].time_non_revenue % 60;
                        if (res[i].revenue != null) {
                                flight = flight + 1;
                        }
                }

                var result = {
                        time_revenue: Monthlyreport.pad(hour_time_revenue) + ":" + Monthlyreport.pad(minute_time_revenue),
                        time_non_revenue: Monthlyreport.pad(hour_time_non_revenue) + ":" + Monthlyreport.pad(minute_time_non_revenue),
                        flight: flight,
                        pax_out: pax_out,
                        pax_in: pax_in,
                        fuel: fuel,
                        fuel_non: fuel_non
                }
                return result
        },
        gettotaldataCpoc: async (res) => {
                var pax_out = 0;
                var pax_in = 0;
                var fuel_vtsh = 0;
                var fuel_mdlq = 0;
                var fuel_aqd = 0;
                var fuel_non = 0
                var hour_time_revenue = 0
                var minute_time_revenue = 0

                var hour_time_non_revenue = 0
                var minute_time_non_revenue = 0

                var hour_time_stanby = 0
                var minute_time_stanby = 0;


                var payload_out_available = 0;
                var payload_out_actual = 0;
                var payload_in_actual = 0;
                var payload_in_available = 0
                var flight = 0
                for (var i = 0; i < res.length; i = i + 1) {
                        pax_out = pax_out + res[i].pax_out;
                        pax_in = pax_in + res[i].pax_in;
                        fuel_vtsh = fuel_vtsh + res[i].fuel_revenue_vtsh;
                        fuel_mdlq = fuel_mdlq + res[i].fuel_revenue_mdlq;
                        fuel_aqd = fuel_aqd + res[i].fuel_revenue_aqd;
                        fuel_non = fuel_non + res[i].fuel_non_revenue;
                        payload_out_available = payload_out_available + res[i].payload_out_available;
                        payload_out_actual = payload_out_actual + res[i].payload_out_actual;
                        payload_in_actual = payload_in_actual + res[i].payload_in_actual;
                        payload_in_available = payload_in_available + res[i].payload_in_available
                        if (res[i].revenue != null) {
                                flight = flight + 1;
                        }
                        if (res[i].time_revenue != null) {
                               
                        hour_time_revenue = hour_time_revenue+ parseInt(res[i].time_revenue / 60);
                        minute_time_revenue = minute_time_revenue+ res[i].time_revenue % 60;
                        }
                        if (res[i].time_non_revenue != null) {
                                hour_time_non_revenue = hour_time_non_revenue+parseInt(res[i].time_non_revenue / 60)
                                minute_time_non_revenue = minute_time_non_revenue+res[i].time_non_revenue % 60;
                        }
                        

                        if (res[0].time_stanby != null) {
                                hour_time_stanby = parseInt(res[i].time_stanby / 60)
                                minute_time_stanby = res[i].time_stanby % 60;
                        }
                        else {
                                hour_time_stanby = "00"
                                minute_time_stanby = "00"
                        }
                        hour_time_stanby = parseInt(res[0].time_stanby / 60)
                        minute_time_stanby = res[0].time_stanby % 60;
                }
                hour_time_revenue = hour_time_revenue+ parseInt(minute_time_revenue/ 60);
                minute_time_revenue = minute_time_revenue % 60;               
                hour_time_non_revenue = hour_time_non_revenue+ parseInt(minute_time_non_revenue/ 60);
                minute_time_non_revenue = minute_time_non_revenue % 60;          
                var result = {
                        time_revenue: Monthlyreport.pad(hour_time_revenue) + ":" + Monthlyreport.pad(minute_time_revenue),
                        time_non_revenue: Monthlyreport.pad(hour_time_non_revenue) + ":" + Monthlyreport.pad(minute_time_non_revenue),
                        time_stanby: Monthlyreport.pad(hour_time_stanby) + ":" + Monthlyreport.pad(minute_time_stanby),
                        flight: flight,
                        pax_out: pax_out,
                        pax_in: pax_in,
                        fuel_vtsh: fuel_vtsh,
                        fuel_mdlq: fuel_mdlq,
                        fuel_aqd: fuel_aqd,
                        fuel_non: fuel_non,
                        payload_out_available: payload_out_available,
                        payload_out_actual: payload_out_actual,
                        payload_in_actual: payload_in_actual,
                        payload_in_available: payload_in_available,
                }
                return result
        },
        gettotaldataChoc: async (res) => {
                var pax_out = 0;
                var pax_in = 0;
                var fuel_vtsh = 0;
                var fuel_mdlq = 0;
                var fuel_aqd = 0;
                var fuel_non = 0
                var hour_time_revenue = 0
                var minute_time_revenue = 0
                var hour_time_non_revenue = 0
                var minute_time_non_revenue = 0;

                var payload_out_available = 0;
                var payload_out_actual = 0;
                var payload_in_actual = 0;
                var payload_in_available = 0
                var flight = 0

                
                var hour_time_stanby = 0
                var minute_time_stanby = 0;
                for (var i = 0; i < res.length; i = i + 1) {
                        pax_out = pax_out + res[i].pax_out;
                        pax_in = pax_in + res[i].pax_in;
                        fuel_vtsh = fuel_vtsh + res[i].fuel_revenue_vtsh;
                        fuel_mdlq = fuel_mdlq + res[i].fuel_revenue_mdlq;
                        fuel_aqd = fuel_aqd + res[i].fuel_revenue_aqd;
                        fuel_non = fuel_non + res[i].fuel_non_revenue;
                        payload_out_available = payload_out_available + res[i].payload_out_available;
                        payload_out_actual = payload_out_actual + res[i].payload_out_actual;
                        payload_in_actual = payload_in_actual + res[i].payload_in_actual;
                        payload_in_available = payload_in_available + res[i].payload_in_available
                        if (res[i].revenue != null) {
                                flight = flight + 1;
                        }
                        hour_time_revenue = parseInt(res[0].time_revenue / 60);
                        minute_time_revenue = res[0].time_revenue % 60;
                        hour_time_non_revenue = parseInt(res[0].time_non_revenue / 60)
                        minute_time_non_revenue = res[0].time_non_revenue % 60;
                        hour_time_stanby = parseInt(res[0].time_stanby / 60)
                        minute_time_stanby = res[0].time_stanby % 60;
                }

                var result = {
                        time_revenue: Monthlyreport.pad(hour_time_revenue) + ":" + Monthlyreport.pad(minute_time_revenue),
                        time_non_revenue: Monthlyreport.pad(hour_time_non_revenue) + ":" + Monthlyreport.pad(minute_time_non_revenue),
                        time_stanby: Monthlyreport.pad(hour_time_stanby) + ":" + Monthlyreport.pad(minute_time_stanby),
                        flight: flight,
                        pax_out: pax_out,
                        pax_in: pax_in,
                        fuel_vtsh: fuel_vtsh,
                        fuel_mdlq: fuel_mdlq,
                        fuel_aqd: fuel_aqd,
                        fuel_non: fuel_non,
                        payload_out_available: payload_out_available,
                        payload_out_actual: payload_out_actual,
                        payload_in_actual: payload_in_actual,
                        payload_in_available: payload_in_available,
                }
                return result
        },
        gettotaldataPrp: async (res) => {
                var pax_out = 0;
                var pax_in = 0;
                var fuel_vtsh = 0;
                var fuel_non = 0
                var hour_time_revenue = 0
                var minute_time_revenue = 0
                var hour_time_non_revenue = 0
                var minute_time_non_revenue = 0;
                var payload_out_available = 0;
                var payload_out_actual = 0;
                var payload_in_actual = 0;
                var payload_in_available = 0
                var flight = 0
               
                var hour_time_stanby = 0
                var minute_time_stanby = 0;
                for (var i = 0; i < res.length; i = i + 1) {
                        pax_out = pax_out + res[i].pax_out;
                        pax_in = pax_in + res[i].pax_in;
                        fuel_vtsh = fuel_vtsh + res[i].fuel_revenue;
                        fuel_non = fuel_non + res[i].fuel_non_revenue;
                        payload_out_available = payload_out_available + res[i].payload_out_available;
                        payload_out_actual = payload_out_actual + res[i].payload_out_actual;
                        payload_in_actual = payload_in_actual + res[i].payload_in_actual;
                        payload_in_available = payload_in_available + res[i].payload_in_available
                        if (res[i].revenue != null) {
                                flight = flight + 1;
                        }
                        hour_time_revenue = parseInt(res[0].time_revenue / 60);
                        minute_time_revenue = res[0].time_revenue % 60;
                        hour_time_non_revenue = parseInt(res[0].time_non_revenue / 60)
                        minute_time_non_revenue = res[0].time_non_revenue % 60;
                        hour_time_stanby = parseInt(res[0].time_stanby / 60)
                        minute_time_stanby = res[0].time_stanby % 60;
                }

                var result = {
                        time_revenue: Monthlyreport.pad(hour_time_revenue) + ":" + Monthlyreport.pad(minute_time_revenue),
                        time_non_revenue: Monthlyreport.pad(hour_time_non_revenue) + ":" + Monthlyreport.pad(minute_time_non_revenue),
                        time_stanby: Monthlyreport.pad(hour_time_stanby) + ":" + Monthlyreport.pad(minute_time_stanby),

                        flight: flight,
                        pax_out: pax_out,
                        pax_in: pax_in,
                        fuel_vtsh: fuel_vtsh,
                        fuel_non: fuel_non,
                        payload_out_available: payload_out_available,
                        payload_out_actual: payload_out_actual,
                        payload_in_actual: payload_in_actual,
                        payload_in_available: payload_in_available,
                }
                return result
        },
        gettotaldataMUBADALAVTBU: async (res) => {
                var pax_out = 0;
                var pax_in = 0;
                var fuel = 0;
                var fuel_non = 0
                var hour_time_revenue = 0
                var minute_time_revenue = 0
                var minute_part_revenue = 0
                var hour_time_non_revenue = 0
                var minute_time_non_revenue = 0;
                var minute_part_non_revenue = 0
                var flight = 0;
                var payload_out_available = 0;
                var payload_out_actual = 0;
                var payload_in_actual = 0;
                var payload_in_available = 0
                var mileage_e115 = 0;
                var mileage_fpf3 = 0;
                var mileage_xmap = 0;
                var mileage_combine = 0;
                var mileage_mist = 0;
                var total_mileage = 0;
               
                var hour_time_stanby = 0
                var minute_time_stanby = 0;
                hour_time_revenue = parseInt(res[0].time_revenue / 60);
                minute_time_revenue = res[0].time_revenue % 60;
                minute_part_revenue = Math.round(minute_time_revenue / 0.6);
                hour_time_non_revenue = parseInt(res[0].time_non_revenue / 60)
                minute_time_non_revenue = res[0].time_non_revenue % 60;
                minute_part_non_revenue = Math.round(minute_time_non_revenue / 0.6);
                hour_time_stanby = parseInt(res[0].time_stanby / 60)
                minute_time_stanby = res[0].time_stanby % 60;
                for (var i = 0; i < res.length; i = i + 1) {
                        pax_out = pax_out + res[i].pax_out;
                        pax_in = pax_in + res[i].pax_in;
                        fuel = fuel + res[i].fuel_revenue;
                        fuel_non = fuel_non + res[i].fuel_non_revenue;
                        payload_out_available = payload_out_available + res[i].payload_out_available;
                        payload_out_actual = payload_out_actual + res[i].payload_out_actual;
                        payload_in_actual = payload_in_actual + res[i].payload_in_actual;
                        payload_in_available = payload_in_available + res[i].payload_in_available
                        mileage_e115 = mileage_e115 + res[i].mileage_e115
                        mileage_fpf3 = mileage_fpf3 + res[i].mileage_fpf3
                        mileage_xmap = mileage_xmap + res[i].mileage_xmap
                        mileage_combine = mileage_combine + res[i].mileage_combine
                        mileage_mist = mileage_mist + res[i].mileage_mist
                        total_mileage = total_mileage + res[i].mileage
                        if (res[i].revenue != null) {
                                flight = flight + 1;
                        }
                }

                var result = {
                        time_revenue: Monthlyreport.pad(hour_time_revenue) + ":" + Monthlyreport.pad(minute_time_revenue),
                        time_non_revenue: Monthlyreport.pad(hour_time_non_revenue) + ":" + Monthlyreport.pad(minute_time_non_revenue),
                        time_revenue_part: Monthlyreport.pad(hour_time_revenue) + "." + Monthlyreport.pad(minute_part_revenue),
                        time_non_revenue_part: Monthlyreport.pad(hour_time_non_revenue) + "." + Monthlyreport.pad(minute_part_non_revenue),
                        time_stanby: Monthlyreport.pad(hour_time_stanby) + ":" + Monthlyreport.pad(minute_time_stanby),

                        flight: flight,
                        pax_out: pax_out,
                        pax_in: pax_in,
                        fuel: fuel,
                        fuel_non: fuel_non,
                        payload_out_available: payload_out_available,
                        payload_out_actual: payload_out_actual,
                        payload_in_actual: payload_in_actual,
                        payload_in_available: payload_in_available,
                        mileage_e115: mileage_e115,
                        mileage_fpf3: mileage_fpf3,
                        mileage_xmap: mileage_xmap,
                        mileage_combine: mileage_combine,
                        mileage_mist:mileage_mist,
                        total_mileage: total_mileage
                }
                return result
        },
        gettotaldataMUBADALAVTSH: async (res) => {
                var pax_out = 0;
                var pax_in = 0;
                var fuel = 0;
                var fuel_non = 0
                var hour_time_revenue = 0
                var minute_time_revenue = 0
                var minute_part_revenue = 0
                var hour_time_non_revenue = 0
                var minute_time_non_revenue = 0;
                var minute_part_non_revenue = 0
                var flight = 0;
                var payload_out_available = 0;
                var payload_out_actual = 0;
                var payload_in_actual = 0;
                var payload_in_available = 0
                var mileage_e115 = 0;
                var mileage_afso = 0;
                var mileage_combine = 0;
                var total_mileage = 0;
                var mileage_mist = 0
                var hour_time_stanby = 0
                var minute_time_stanby = 0;
                hour_time_revenue = parseInt(res[0].time_revenue / 60);
                minute_time_revenue = res[0].time_revenue % 60;
                minute_part_revenue = parseInt(res[0].time_revenue / 0.6);
                hour_time_non_revenue = parseInt(res[0].time_non_revenue / 60)
                minute_time_non_revenue = res[0].time_non_revenue % 60;
                minute_part_non_revenue = parseInt(res[0].time_non_revenue / 60);
                hour_time_stanby = parseInt(res[0].time_stanby / 60)
                minute_time_stanby = res[0].time_stanby % 60;
                for (var i = 0; i < res.length; i = i + 1) {
                        pax_out = pax_out + res[i].pax_out;
                        pax_in = pax_in + res[i].pax_in;
                        fuel = fuel + res[i].fuel_revenue;
                        fuel_non = fuel_non + res[i].fuel_non_revenue;
                        payload_out_available = payload_out_available + res[i].payload_out_available;
                        payload_out_actual = payload_out_actual + res[i].payload_out_actual;
                        payload_in_actual = payload_in_actual + res[i].payload_in_actual;
                        payload_in_available = payload_in_available + res[i].payload_in_available
                        mileage_e115 = mileage_e115 + res[i].mileage_e115
                        mileage_afso = mileage_afso + res[i].mileage_afso
                        mileage_combine = mileage_combine + res[i].mileage_combine
                        mileage_mist = mileage_mist + res[i].mileage_mist
                        total_mileage = total_mileage + res[i].mileage
                        if (res[i].revenue != null) {
                                flight = flight + 1;
                        }
                }

                var result = {
                        time_revenue: Monthlyreport.pad(hour_time_revenue) + ":" + Monthlyreport.pad(minute_time_revenue),
                        time_non_revenue: Monthlyreport.pad(hour_time_non_revenue) + ":" + Monthlyreport.pad(minute_time_non_revenue),
                        time_stanby: Monthlyreport.pad(hour_time_stanby) + ":" + Monthlyreport.pad(minute_time_stanby),

                        flight: flight,
                        pax_out: pax_out,
                        pax_in: pax_in,
                        fuel: fuel,
                        fuel_non: fuel_non,
                        payload_out_available: payload_out_available,
                        payload_out_actual: payload_out_actual,
                        payload_in_actual: payload_in_actual,
                        payload_in_available: payload_in_available,
                        mileage_e115: mileage_e115,
                        mileage_afso: mileage_afso,
                        mileage_combine: mileage_combine,
                        mileage_mist : mileage_mist,
                        total_mileage: total_mileage
                }
                return result
        },
        DaysInMonth: (month, year) => {
                return new Date(year, month, 0).getDate();
        },
        CreateOldPdf: async (month, monthname, year, oid, datamain, totalmain, databackup, backuptotal, FullName) => {
                var uoaimage = await LogoService.GetLogoBaseurl64(1);
                var customerlogo = await LogoService.GetLogoBaseurl64(oid);
                var tablewidth = [50, 40, 80, 40, 40, 20, 20, 40, 41, 110]
                var pdfMake = require("../../public/js/pdfmake");
                if (pdfMake.vfs == undefined) {
                        var pdfFonts = require("../../public/js/vfs_fonts");
                        pdfMake.vfs = pdfFonts.pdfMake.vfs;
                }
                var dd = {
                        content: [
                                {
                                        style: 'tablehearder',
                                        table: {
                                                widths: [100, 310, 100],
                                                body: [
                                                        [{
                                                                image: uoaimage.logo,
                                                                fit: [30, 30],
                                                                margin: [0, 0, 0, 0],
                                                                alignment: 'center',
                                                                border: [false, false, false, false]
                                                        },
                                                        {
                                                                text: '\nUOA-CPOC Monthly Report in ' + monthname + ', ' + year + ' (HS-UOL)',
                                                                margin: [0, 0, 0, 0],
                                                                alignment: 'center',
                                                                border: [true, true, true, true]
                                                        },
                                                        {
                                                                image: customerlogo.logo,
                                                                fit: [30, 30],
                                                                margin: [0, 0, 0, 0],
                                                                alignment: 'center',
                                                                border: [false, false, false, false]
                                                        }
                                                        ],
                                                        [
                                                                {
                                                                        text: null,
                                                                        border: [false, false, false, true]
                                                                },
                                                                {
                                                                        text: null,
                                                                        border: [false, false, false, true]
                                                                },
                                                                {
                                                                        text: null,
                                                                        border: [false, false, false, true]
                                                                },
                                                        ]
                                                ]
                                        }
                                },
                                {
                                        style: 'newtable',
                                        table: {
                                                widths: tablewidth,
                                                body: [
                                                        [
                                                                {
                                                                        text: '\nDate',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        rowSpan: 2,
                                                                        border: [true, true, true, false]


                                                                },
                                                                {
                                                                        text: '\nHS-UOL',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        rowSpan: 2,
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: '\nFlt.Report No.',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        rowSpan: 2,
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: 'Flight Time',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        colSpan: 2

                                                                },
                                                                {
                                                                        text: '02:19',
                                                                        style: 'tabledata',
                                                                        alignment: 'center'

                                                                },
                                                                {
                                                                        text: 'Passenger',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        colSpan: 2

                                                                },
                                                                {
                                                                        text: '11',
                                                                        style: 'tabledata',
                                                                        alignment: 'center'

                                                                },
                                                                {
                                                                        text: 'Fuel ',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        colSpan: 2

                                                                },
                                                                {
                                                                        text: '2000',
                                                                        style: 'tabledata',
                                                                        alignment: 'center'

                                                                },
                                                                {
                                                                        text: '\nRemark',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        rowSpan: 2,
                                                                        border: [true, true, true, false]

                                                                }
                                                        ],
                                                        [
                                                                {
                                                                        text: 'Date',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: 'A/C',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: 'Flt.Report No.',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: 'Revenue',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: 'Non-Revenue',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: 'Out',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: 'In',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: 'VTSH',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: 'Non-Revenue',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: '\nRemark',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, true, true, false]

                                                                }
                                                        ],


                                                ]
                                        }
                                },
                                {
                                        style: 'table0',
                                        table: {

                                                widths: tablewidth,
                                                body: datamain

                                        }
                                },
                                {
                                        style: 'table0',
                                        table: {

                                                widths: tablewidth,
                                                body: [
                                                        [
                                                                {
                                                                        text: ' ',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, false, true, true]

                                                                },
                                                                {
                                                                        text: 'Total',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        bold: 'True',
                                                                        border: [true, false, true, true]

                                                                },
                                                                {
                                                                        text: totalmain.flight + ' Flight',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, false, true, true]

                                                                },
                                                                {
                                                                        text: totalmain.time_revenue,
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, false, true, true]

                                                                },
                                                                {
                                                                        text: totalmain.time_non_revenue,
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, false, true, true]

                                                                },
                                                                {
                                                                        text: totalmain.pax_out,
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, false, true, true]

                                                                },
                                                                {
                                                                        text: totalmain.pax_in,
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, false, true, true]

                                                                },
                                                                {
                                                                        text: totalmain.fuel,
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, false, true, true]

                                                                },
                                                                {
                                                                        text: totalmain.fuel_non,
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, false, true, true]

                                                                },
                                                                {
                                                                        text: ' ',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, false, true, true]

                                                                }
                                                        ]
                                                ]
                                        }
                                },
                                '\n',
                                {
                                        text: 'CPOC Representstive: ..............................',
                                        style: 'textfooter'
                                },
                                {
                                        text: 'UOA Flight Operations Manager :  ..............................',
                                        style: 'textfooter'
                                },
                                {
                                        text: 'UOA Commercial Representative:    ..............................',
                                        style: 'textfooter'
                                },
                                {
                                        text: 'Issued by: ' + FullName,
                                        style: 'textfooter'
                                },
                                {
                                        text: 'Issued date: ' + Monthlyreport.DaysInMonth(month, year) + ' ' + monthname + ' ' + year,
                                        style: 'textfooter',
                                        pageBreak: 'after'
                                },
                                {
                                        style: 'tablehearder',
                                        table: {
                                                widths: [100, 310, 100],
                                                body: [
                                                        [{
                                                                image: uoaimage.logo,
                                                                fit: [30, 30],
                                                                margin: [0, 0, 0, 0],
                                                                alignment: 'center',
                                                                border: [false, false, false, false]
                                                        },
                                                        {
                                                                text: '\nUOA-CPOC Monthly Report in ' + monthname + ', ' + year + ' (Back Up)',
                                                                margin: [0, 0, 0, 0],
                                                                alignment: 'center',
                                                                border: [true, true, true, true]
                                                        },
                                                        {
                                                                image: customerlogo.logo,
                                                                fit: [30, 30],
                                                                margin: [0, 0, 0, 0],
                                                                alignment: 'center',
                                                                border: [false, false, false, false]
                                                        }
                                                        ],
                                                        [
                                                                {
                                                                        text: null,
                                                                        border: [false, false, false, true]
                                                                },
                                                                {
                                                                        text: null,
                                                                        border: [false, false, false, true]
                                                                },
                                                                {
                                                                        text: null,
                                                                        border: [false, false, false, true]
                                                                },
                                                        ]
                                                ]
                                        }
                                },
                                {
                                        style: 'newtable',
                                        table: {
                                                widths: tablewidth,
                                                body: [
                                                        [
                                                                {
                                                                        text: '\nDate',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        rowSpan: 2,
                                                                        border: [true, true, true, false]


                                                                },
                                                                {
                                                                        text: '\nHS-UOL',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        rowSpan: 2,
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: '\nFlt.Report No.',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        rowSpan: 2,
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: 'Flight Time',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        colSpan: 2

                                                                },
                                                                {
                                                                        text: '02:19',
                                                                        style: 'tabledata',
                                                                        alignment: 'center'

                                                                },
                                                                {
                                                                        text: 'Passenger',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        colSpan: 2

                                                                },
                                                                {
                                                                        text: '11',
                                                                        style: 'tabledata',
                                                                        alignment: 'center'

                                                                },
                                                                {
                                                                        text: 'Fuel ',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        colSpan: 2

                                                                },
                                                                {
                                                                        text: '2000',
                                                                        style: 'tabledata',
                                                                        alignment: 'center'

                                                                },
                                                                {
                                                                        text: '\nRemark',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        rowSpan: 2,
                                                                        border: [true, true, true, false]

                                                                }
                                                        ],
                                                        [
                                                                {
                                                                        text: 'Date',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: 'A/C',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: 'Flt.Report No.',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: 'Revenue',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: 'Non-Revenue',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: 'Out',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: 'In',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: 'VTSH',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: 'Non-Revenue',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: '\nRemark',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, true, true, false]

                                                                }
                                                        ],


                                                ]
                                        }
                                },
                                {
                                        style: 'table0',
                                        table: {

                                                widths: tablewidth,
                                                body: databackup

                                        }
                                },
                                {
                                        style: 'table0',
                                        table: {

                                                widths: tablewidth,
                                                body: [
                                                        [
                                                                {
                                                                        text: ' ',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, false, true, true]

                                                                },
                                                                {
                                                                        text: 'Total',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        bold: 'True',
                                                                        border: [true, false, true, true]

                                                                },
                                                                {
                                                                        text: backuptotal.flight + ' Flight',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, false, true, true]

                                                                },
                                                                {
                                                                        text: backuptotal.time_revenue,
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, false, true, true]

                                                                },
                                                                {
                                                                        text: backuptotal.time_non_revenue,
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, false, true, true]

                                                                },
                                                                {
                                                                        text: backuptotal.pax_out,
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, false, true, true]

                                                                },
                                                                {
                                                                        text: backuptotal.pax_in,
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, false, true, true]

                                                                },
                                                                {
                                                                        text: backuptotal.fuel,
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, false, true, true]

                                                                },
                                                                {
                                                                        text: backuptotal.fuel_non,
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, false, true, true]

                                                                },
                                                                {
                                                                        text: ' ',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, false, true, true]

                                                                }
                                                        ]
                                                ]
                                        }
                                },
                                '\n',
                                {
                                        text: 'CPOC Representstive: ..............................',
                                        style: 'textfooter'
                                },
                                {
                                        text: 'UOA Flight Operations Manager :  ..............................',
                                        style: 'textfooter'
                                },
                                {
                                        text: 'UOA Commercial Representative:    ..............................',
                                        style: 'textfooter'
                                },
                                {
                                        text: 'Issued by: ' + FullName,
                                        style: 'textfooter'
                                },
                                {
                                        text: 'Issued date: ' + Monthlyreport.DaysInMonth(month, year) + ' ' + monthname + ' ' + year,
                                        style: 'textfooter'
                                },
                        ],
                        styles: {
                                header: {
                                        fontSize: 18,
                                        bold: true,
                                        alignment: 'right',
                                        margin: [0, 190, 0, 80]
                                },
                                subheader: {
                                        fontSize: 14
                                },
                                superMargin: {
                                        margin: [20, 0, 40, 0],
                                        fontSize: 15
                                },
                                newtable: {
                                        margin: [-30, 10, 0, 0],
                                },
                                table0: {
                                        margin: [-30, 0, 0, 0],
                                        fontSize: 8
                                },
                                tabledata: {
                                        fontSize: 10
                                },
                                tabledataremark: { fontSize: 8 },

                                tablehearder: { margin: [-10, -20, 0, 0], },
                                textfooter: {
                                        margin: [225, 0, 0, 5],
                                        fontSize: 10
                                }
                        },


                };
                pdfMake.createPdf(dd).open({});
        },
        CreateCpocPdf: async (month, monthname, year, oid, datamain, databackup, FullName) => {
                var tablewidth1 = [35, 35, 13, 53, 35, 35, 30, 20, 20, 30, 30, 30, 35, 35, 35, 35, 25, 1, 20, 95]
                var pdfMake = require("../../public/js/pdfmake");
                if (pdfMake.vfs == undefined) {
                        var pdfFonts = require("../../public/js/vfs_fonts");
                        pdfMake.vfs = pdfFonts.pdfMake.vfs;
                }
                var dd = {
                        pageMargins: [37, 20, 37, 70],
                        pageOrientation: 'landscape',
                        content: [
                                {
                                        style: 'table0',
                                        table: {
                                                headerRows: 4,
                                                widths: tablewidth1,
                                                body: datamain

                                        }
                                },
                                '\n',
                                {
                                        text: 'CPOC Representstive: ..............................',
                                        style: 'textfooter'
                                },
                                {
                                        text: 'UOA Flight Operations Manager :  ..............................',
                                        style: 'textfooter'
                                },
                                {
                                        text: 'UOA Commercial Representative:    ..............................',
                                        style: 'textfooter'
                                },
                                {
                                        text: 'Issued by: ' + FullName,
                                        style: 'textfooter'
                                },
                                {
                                        text: 'Issued date: ' + Monthlyreport.DaysInMonth(month, year) + ' ' + monthname + ' ' + year,
                                        style: 'textfooter',
                                        pageBreak: 'after'
                                },

                                {
                                        style: 'table0',
                                        table: {
                                                headerRows: 4,
                                                widths: tablewidth1,
                                                body: databackup

                                        }
                                },

                                '\n',
                                {
                                        text: 'CPOC Representstive: ..............................',
                                        style: 'textfooter'
                                },
                                {
                                        text: 'UOA Flight Operations Manager :  ..............................',
                                        style: 'textfooter'
                                },
                                {
                                        text: 'UOA Commercial Representative:    ..............................',
                                        style: 'textfooter'
                                },
                                {
                                        text: 'Issued by: ' + FullName,
                                        style: 'textfooter'
                                },
                                {
                                        text: 'Issued date: ' + Monthlyreport.DaysInMonth(month, year) + ' ' + monthname + ' ' + year,
                                        style: 'textfooter'
                                },
                        ],
                        styles: {
                                header: {
                                        fontSize: 18,
                                        bold: true,
                                        alignment: 'right',
                                        margin: [0, 190, 0, 80]
                                },
                                subheader: {
                                        fontSize: 14
                                },
                                superMargin: {
                                        margin: [20, 0, 40, 0],
                                        fontSize: 15
                                },
                                newtable: {
                                        margin: [-30, 10, 0, 0],
                                },
                                table0: {
                                        margin: [-30, 0, 0, 0],
                                        fontSize: 8
                                },
                                tabledata: {
                                        fontSize: 7
                                },
                                tabledataremark: { fontSize: 8 },

                                tableheader: {
                                        fillColor: '#ededed',
                                        bold: true
                                },
                                textfooter: {
                                        margin: [450, 0, 0, 5],
                                        fontSize: 10
                                }
                        },


                };
                pdfMake.createPdf(dd).open({});
        },
        CreateChocPdf: async (month, monthname, year, oid, datamain, databackup, FullName) => {
                var tablewidth1 = [40, 40, 13, 55, 37, 37, 32, 22, 22, 37, 37, 37, 37, 37, 27, 1, 22, 95]
                var pdfMake = require("../../public/js/pdfmake");
                if (pdfMake.vfs == undefined) {
                        var pdfFonts = require("../../public/js/vfs_fonts");
                        pdfMake.vfs = pdfFonts.pdfMake.vfs;
                }
                var dd = {
                        pageMargins: [55, 20, 55, 65],
                        pageOrientation: 'landscape',
                        content: [
                                {
                                        style: 'table0',
                                        table: {
                                                headerRows: 4,
                                                widths: tablewidth1,
                                                body: datamain

                                        }
                                },
                                '\n',
                                {
                                        text: 'CHOC Representstive: ..............................',
                                        style: 'textfooter'
                                },
                                {
                                        text: 'UOA Flight Operations Manager :  ..............................',
                                        style: 'textfooter'
                                },
                                {
                                        text: 'UOA Commercial Representative:    ..............................',
                                        style: 'textfooter'
                                },
                                {
                                        text: 'Issued by: ' + FullName,
                                        style: 'textfooter'
                                },
                                {
                                        text: 'Issued date: ' + Monthlyreport.DaysInMonth(month, year) + ' ' + monthname + ' ' + year,
                                        style: 'textfooter',
                                        pageBreak: 'after'
                                },
                                {
                                        style: 'table0',
                                        table: {
                                                headerRows: 4,
                                                widths: tablewidth1,
                                                body: databackup

                                        }
                                },
                                '\n',
                                {
                                        text: 'CHOC Representstive: ..............................',
                                        style: 'textfooter'
                                },
                                {
                                        text: 'UOA Flight Operations Manager :  ..............................',
                                        style: 'textfooter'
                                },
                                {
                                        text: 'UOA Commercial Representative:    ..............................',
                                        style: 'textfooter'
                                },
                                {
                                        text: 'Issued by: ' + FullName,
                                        style: 'textfooter'
                                },
                                {
                                        text: 'Issued date: ' + Monthlyreport.DaysInMonth(month, year) + ' ' + monthname + ' ' + year,
                                        style: 'textfooter'
                                },
                        ],
                        styles: {
                                header: {
                                        fontSize: 18,
                                        bold: true,
                                        alignment: 'right',
                                        margin: [0, 190, 0, 80]
                                },
                                subheader: {
                                        fontSize: 14
                                },
                                superMargin: {
                                        margin: [20, 0, 40, 0],
                                        fontSize: 15
                                },
                                newtable: {
                                        margin: [-30, 10, 0, 0],
                                },
                                table0: {
                                        margin: [-30, 0, 0, 0],
                                        fontSize: 8
                                },
                                tabledata: {
                                        fontSize: 8
                                },
                                tabledataremark: { fontSize: 8 },

                                tableheader: {
                                        fillColor: '#ededed',
                                        bold: true
                                },
                                textfooter: {
                                        margin: [450, 0, 0, 5],
                                        fontSize: 10
                                }
                        },


                };
                pdfMake.createPdf(dd).open({});
        },
        CreateMUBADALAPdfVTSH: async (month, monthname, year, oid, datamain, totalmain, FullName) => {
                var uoaimage = await LogoService.GetLogoBaseurl64(1);
                var customerlogo = await LogoService.GetLogoBaseurl64(4);
                var tablewidth = [35, 35, 80, 35, 35, 35, 30, 30, 35, 35, 35, 35, 35, 35, 120, 33]
                var pdfMake = require("../../public/js/pdfmake");
                if (pdfMake.vfs == undefined) {
                        var pdfFonts = require("../../public/js/vfs_fonts");
                        pdfMake.vfs = pdfFonts.pdfMake.vfs;
                }
                var dd = {
                        pageOrientation: 'landscape',
                        pageMargins: [40, 60, 40, 60],
                        header: {
                                style: 'tablehearder',
                                table: {
                                        widths: [80, 600, 80],
                                        body: [
                                                [{
                                                        image: uoaimage.logo,
                                                        fit: [30, 30],
                                                        margin: [0, 0, 0, 0],
                                                        alignment: 'center',
                                                        border: [false, false, false, false]
                                                },
                                                {
                                                        text: '\nUOA Monthly Report in ' + monthname + ', ' + year + ' (Valeura Energy)',
                                                        margin: [0, 0, 0, 0],
                                                        alignment: 'center',
                                                        border: [true, true, true, true]
                                                },
                                                {
                                                        image: customerlogo.logo,
                                                        fit: [80, 80],
                                                        margin: [0, 10, 0, 0],
                                                        alignment: 'center',
                                                        border: [false, false, false, false]
                                                }
                                                ],
                                                [
                                                        {
                                                                text: null,
                                                                border: [false, false, false, true]
                                                        },
                                                        {
                                                                text: null,
                                                                border: [false, false, false, true]
                                                        },
                                                        {
                                                                text: null,
                                                                border: [false, false, false, true]
                                                        },
                                                ]
                                        ]
                                }
                        },
                        content: [

                                {
                                        style: 'newtable',
                                        table: {
                                                headerRows: 2,
                                                widths: tablewidth,
                                                body: datamain
                                        }
                                },
                                '\n',
                                {
                                        text: 'Valeura Energy Representative: ..............................',
                                        style: 'textfooter'
                                },
                                {
                                        text: 'UOA Flight Operations Representative :  ..............................',
                                        style: 'textfooter'
                                },
                                {
                                        text: 'UOA Commercial Representative:    ..............................',
                                        style: 'textfooter'
                                },
                                {
                                        text: 'Issued by: ' + FullName,
                                        style: 'textfooter'
                                },
                                {
                                        text: 'Issued date: ' + Monthlyreport.DaysInMonth(month, year) + ' ' + monthname + ' ' + year,
                                        style: 'textfooter'
                                },
                        ],
                        footer: function (currentPage, pageCount) {
                                var footer = {
                                  text:
                                    "OPS-FM-14 Issue 02 Revision 00",
                                  fontSize: 8,
                                  font: "Roboto",
                                  alignment: "right",
                                  margin:[0,40,20,0]
                                };
                      
                                return footer;
                              },
                        styles: {
                                header: {
                                        fontSize: 18,
                                        bold: true,
                                        alignment: 'right',
                                        margin: [0, 190, 0, 80]
                                },
                                subheader: {
                                        fontSize: 14
                                },
                                superMargin: {
                                        margin: [20, 0, 40, 0],
                                        fontSize: 15
                                },
                                newtable: {
                                        margin: [-30, 0, 0, 0],
                                },
                                table0: {
                                        margin: [-30, 0, 0, 0],
                                        fontSize: 8
                                },
                                tabledata: {
                                        fontSize: 7
                                },
                                tabledataremark: { fontSize: 8 },

                                tablehearder: { margin: [25, 15, 0, 0] },
                                textfooter: {
                                        margin: [400, 0, 0, 5],
                                        fontSize: 10
                                }
                        },

                };
                pdfMake.createPdf(dd).open({});
        },
        CreateMUBADALAPdfVTBU: async (month, monthname, year, oid, datamain, FullName) => {
                var uoaimage = await LogoService.GetLogoBaseurl64(1);
                var customerlogo = await LogoService.GetLogoBaseurl64(4);
                // var tablewidth = [50, 40, 80, 40, 40, 20, 20, 40, 41, 110]
                var tablewidth = [33, 30, 60, 35, 35, 35, 35, 35, 18, 18, 30, 30, 30, 30, 30, 30, 120, 28]
                var pdfMake = require("../../public/js/pdfmake");
                if (pdfMake.vfs == undefined) {
                        var pdfFonts = require("../../public/js/vfs_fonts");
                        pdfMake.vfs = pdfFonts.pdfMake.vfs;
                }
                var dd = {
                        pageOrientation: 'landscape',
                        pageMargins: [40, 60, 40, 60],
                        header: {
                                style: 'tablehearder',
                                table: {
                                        widths: [80, 600, 80],
                                        body: [
                                                [{
                                                        image: uoaimage.logo,
                                                        fit: [30, 30],
                                                        margin: [0, 0, 0, 0],
                                                        alignment: 'center',
                                                        border: [false, false, false, false]
                                                },
                                                {
                                                        text: '\nUOA Monthly Report in ' + monthname + ' ' + year + ' (Valeura Energy)',
                                                        margin: [0, 0, 0, 0],
                                                        alignment: 'center',
                                                        border: [true, true, true, true]
                                                },
                                                {
                                                        image: customerlogo.logo,
                                                        fit: [50, 50],
                                                        margin: [0, 10, 0, 0],
                                                        alignment: 'center',
                                                        border: [false, false, false, false]
                                                }
                                                ],
                                                [
                                                        {
                                                                text: null,
                                                                border: [false, false, false, true]
                                                        },
                                                        {
                                                                text: null,
                                                                border: [false, false, false, true]
                                                        },
                                                        {
                                                                text: null,
                                                                border: [false, false, false, true]
                                                        },
                                                ]
                                        ]
                                }
                        },
                        content: [


                                {
                                        style: 'table0',
                                        table: {
                                                headerRows: 2,
                                                widths: tablewidth,
                                                body: datamain

                                        }
                                },
                                '\n',
                                {
                                        text: 'Valeura Energy Representative: ..............................',
                                        style: 'textfooter'
                                },
                                {
                                        text: 'UOA Flight Operations Representative :  ..............................',
                                        style: 'textfooter'
                                },
                                {
                                        text: 'UOA Commercial Representative:    ..............................',
                                        style: 'textfooter'
                                },
                                {
                                        text: 'Issued by: ' + FullName,
                                        style: 'textfooter'
                                },
                                {
                                        text: 'Issued date: ' + Monthlyreport.DaysInMonth(month, year) + ' ' + monthname + ' ' + year,
                                        style: 'textfooter'
                                },
                        ],
                        footer: function (currentPage, pageCount) {
                                var footer = {
                                  text:
                                    "OPS-FM-14 Issue 02 Revision 00",
                                  fontSize: 8,
                                  font: "Roboto",
                                  alignment: "right",
                                  margin:[0,40,20,0]
                                };
                      
                                return footer;
                              },
                        styles: {
                                header: {
                                        fontSize: 18,
                                        bold: true,
                                        alignment: 'right',
                                        margin: [0, 190, 0, 80]
                                },
                                subheader: {
                                        fontSize: 14
                                },
                                superMargin: {
                                        margin: [20, 0, 40, 0],
                                        fontSize: 15
                                },
                                newtable: {
                                        margin: [-30, 0, 0, 0],
                                },
                                table0: {
                                        margin: [-30, 0, 0, 0],
                                        fontSize: 8
                                },
                                tabledata: {
                                        fontSize: 7
                                },
                                tabledataremark: { fontSize: 8 },

                                tablehearder: { margin: [25, 15, 0, 0], },
                                textfooter: {
                                        margin: [400, 0, 0, 5],
                                        fontSize: 10
                                }
                        },
                };
                pdfMake.createPdf(dd).open({});
        },
        CreatePRPPdf: async (month, monthname, year, oid, datamain, totalmain, FullName) => {
                var uoaimage = await LogoService.GetLogoBaseurl64(1);
                var customerlogo = await LogoService.GetLogoBaseurl64(oid);
                var tablewidth = [40, 40, 13, 55, 37, 37, 32, 22, 22, 37, 37, 37, 37, 37, 27, 1, 22, 95]
                var pdfMake = require("../../public/js/pdfmake");
                if (pdfMake.vfs == undefined) {
                        var pdfFonts = require("../../public/js/vfs_fonts");
                        pdfMake.vfs = pdfFonts.pdfMake.vfs;
                }
                var dd = {
                        pageOrientation: 'landscape',
                        pageMargins: [55, 60, 55, 60],
                        header: {
                                style: 'headerpage',
                                table: {
                                        widths: [80, 600, 80],
                                        body: [
                                                [{
                                                        image: uoaimage.logo,
                                                        fit: [30, 30],
                                                        margin: [0, 0, 0, 0],
                                                        alignment: 'center',
                                                        border: [false, false, false, false]
                                                },
                                                {
                                                        text: '\nUOA Monthly Report in ' + monthname + ', ' + year + ' (PRP)',
                                                        margin: [0, 0, 0, 0],
                                                        alignment: 'center',
                                                        border: [true, true, true, true]
                                                },
                                                {
                                                        image: customerlogo.logo,
                                                        fit: [30, 30],
                                                        margin: [0, 0, 0, 0],
                                                        alignment: 'center',
                                                        border: [false, false, false, false]
                                                }
                                                ],

                                        ]
                                }
                        },
                        content: [
                                {
                                        style: 'table0',
                                        table: {
                                                headerRows: 2,
                                                widths: tablewidth,
                                                body: datamain

                                        }
                                },

                                '\n',
                                {
                                        text: 'PTT Representstive: ..............................',
                                        style: 'textfooter'
                                },
                                {
                                        text: 'UOA Flight Operations Manager :  ..............................',
                                        style: 'textfooter'
                                },
                                {
                                        text: 'UOA Commercial Representative:    ..............................',
                                        style: 'textfooter'
                                },
                                {
                                        text: 'Issued by: ' + FullName,
                                        style: 'textfooter'
                                },
                                {
                                        text: 'Issued date: ' + Monthlyreport.DaysInMonth(month, year) + ' ' + monthname + ' ' + year,
                                        style: 'textfooter'
                                },
                        ],
                        styles: {
                                header: {
                                        fontSize: 18,
                                        bold: true,
                                        alignment: 'right',
                                        margin: [0, 190, 0, 80]
                                },
                                subheader: {
                                        fontSize: 14
                                },
                                superMargin: {
                                        margin: [20, 0, 40, 0],
                                        fontSize: 15
                                },
                                newtable: {
                                        margin: [-30, 10, 0, 0],
                                },
                                table0: {
                                        margin: [-30, 0, 0, 0],
                                        fontSize: 8
                                },
                                tabledata: {
                                        fontSize: 8
                                },
                                tabledataremark: { fontSize: 8 },
                                headerpage: { margin: [25, 15, 0, 0] },
                                tableheader: {
                                        fillColor: '#ededed',
                                        bold: true
                                },
                                textfooter: {
                                        margin: [400, 0, 0, 5],
                                        fontSize: 10
                                }
                        },


                };
                pdfMake.createPdf(dd).open({});
        },
        CreatePTTPdf: async (month, monthname, year, oid, datauoc, totaluoc, datauod, totaluod, datauom, totaluom, datauok, totaluok, datauol, totaluol, datamu, totalmu, dataprp, totalprp, FullName) => {
                var uoaimage = await LogoService.GetLogoBaseurl64(1);
                var customerlogo = await LogoService.GetLogoBaseurl64(oid);
                var tablewidth = [50, 40, 80, 40, 40, 20, 20, 40, 41, 110]
                var pdfMake = require("../../public/js/pdfmake");
                if (pdfMake.vfs == undefined) {
                        var pdfFonts = require("../../public/js/vfs_fonts");
                        pdfMake.vfs = pdfFonts.pdfMake.vfs;
                }
                var dd = {
                        content: [
                                {
                                        style: 'tablehearder',
                                        table: {
                                                widths: [100, 310, 100],
                                                body: [
                                                        [{
                                                                image: uoaimage.logo,
                                                                fit: [30, 30],
                                                                margin: [0, 0, 0, 0],
                                                                alignment: 'center',
                                                                border: [false, false, false, false]
                                                        },
                                                        {
                                                                text: '\nUOA-CPOC Monthly Report in ' + monthname + ', ' + year + ' (HS-UOC)',
                                                                margin: [0, 0, 0, 0],
                                                                alignment: 'center',
                                                                border: [true, true, true, true]
                                                        },
                                                        {
                                                                image: customerlogo.logo,
                                                                fit: [30, 30],
                                                                margin: [0, 0, 0, 0],
                                                                alignment: 'center',
                                                                border: [false, false, false, false]
                                                        }
                                                        ],
                                                        [
                                                                {
                                                                        text: null,
                                                                        border: [false, false, false, true]
                                                                },
                                                                {
                                                                        text: null,
                                                                        border: [false, false, false, true]
                                                                },
                                                                {
                                                                        text: null,
                                                                        border: [false, false, false, true]
                                                                },
                                                        ]
                                                ]
                                        }
                                },
                                {
                                        style: 'newtable',
                                        table: {
                                                widths: tablewidth,
                                                body: [
                                                        [
                                                                {
                                                                        text: '\nDate',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        rowSpan: 2,
                                                                        border: [true, true, true, false]


                                                                },
                                                                {
                                                                        text: '\nHS-UOL',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        rowSpan: 2,
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: '\nFlt.Report No.',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        rowSpan: 2,
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: 'Flight Time',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        colSpan: 2

                                                                },
                                                                {
                                                                        text: '02:19',
                                                                        style: 'tabledata',
                                                                        alignment: 'center'

                                                                },
                                                                {
                                                                        text: 'Passenger',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        colSpan: 2

                                                                },
                                                                {
                                                                        text: '11',
                                                                        style: 'tabledata',
                                                                        alignment: 'center'

                                                                },
                                                                {
                                                                        text: 'Fuel ',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        colSpan: 2

                                                                },
                                                                {
                                                                        text: '2000',
                                                                        style: 'tabledata',
                                                                        alignment: 'center'

                                                                },
                                                                {
                                                                        text: '\nRemark',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        rowSpan: 2,
                                                                        border: [true, true, true, false]

                                                                }
                                                        ],
                                                        [
                                                                {
                                                                        text: 'Date',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: 'A/C',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: 'Flt.Report No.',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: 'Revenue',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: 'Non-Revenue',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: 'Out',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: 'In',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: 'VTSH',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: 'Non-Revenue',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: '\nRemark',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, true, true, false]

                                                                }
                                                        ],


                                                ]
                                        }
                                },
                                {
                                        style: 'table0',
                                        table: {

                                                widths: tablewidth,
                                                body: datauoc

                                        }
                                },
                                {
                                        style: 'table0',
                                        table: {

                                                widths: tablewidth,
                                                body: [
                                                        [
                                                                {
                                                                        text: ' ',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, false, true, true]

                                                                },
                                                                {
                                                                        text: 'Total',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        bold: 'True',
                                                                        border: [true, false, true, true]

                                                                },
                                                                {
                                                                        text: totaluoc.flight + ' Flight',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, false, true, true]

                                                                },
                                                                {
                                                                        text: totaluoc.time_revenue,
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, false, true, true]

                                                                },
                                                                {
                                                                        text: totaluoc.time_non_revenue,
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, false, true, true]

                                                                },
                                                                {
                                                                        text: totaluoc.pax_out,
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, false, true, true]

                                                                },
                                                                {
                                                                        text: totaluoc.pax_in,
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, false, true, true]

                                                                },
                                                                {
                                                                        text: totaluoc.fuel,
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, false, true, true]

                                                                },
                                                                {
                                                                        text: totaluoc.fuel_non,
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, false, true, true]

                                                                },
                                                                {
                                                                        text: ' ',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, false, true, true]

                                                                }
                                                        ]
                                                ]
                                        }
                                },
                                '\n',
                                {
                                        text: 'CPOC Representstive: ..............................',
                                        style: 'textfooter'
                                },
                                {
                                        text: 'UOA Flight Operations Manager :  ..............................',
                                        style: 'textfooter'
                                },
                                {
                                        text: 'UOA Commercial Representative:    ..............................',
                                        style: 'textfooter'
                                },
                                {
                                        text: 'Issued by: ' + FullName,
                                        style: 'textfooter'
                                },
                                {
                                        text: 'Issued date: ' + Monthlyreport.DaysInMonth(month, year) + ' ' + monthname + ' ' + year,
                                        style: 'textfooter',
                                        pageBreak: 'after'
                                },
                                {
                                        style: 'tablehearder',
                                        table: {
                                                widths: [100, 310, 100],
                                                body: [
                                                        [{
                                                                image: uoaimage.logo,
                                                                fit: [30, 30],
                                                                margin: [0, 0, 0, 0],
                                                                alignment: 'center',
                                                                border: [false, false, false, false]
                                                        },
                                                        {
                                                                text: '\nUOA-CPOC Monthly Report in ' + monthname + ', ' + year + ' (HS-UOD)',
                                                                margin: [0, 0, 0, 0],
                                                                alignment: 'center',
                                                                border: [true, true, true, true]
                                                        },
                                                        {
                                                                image: customerlogo.logo,
                                                                fit: [30, 30],
                                                                margin: [0, 0, 0, 0],
                                                                alignment: 'center',
                                                                border: [false, false, false, false]
                                                        }
                                                        ],
                                                        [
                                                                {
                                                                        text: null,
                                                                        border: [false, false, false, true]
                                                                },
                                                                {
                                                                        text: null,
                                                                        border: [false, false, false, true]
                                                                },
                                                                {
                                                                        text: null,
                                                                        border: [false, false, false, true]
                                                                },
                                                        ]
                                                ]
                                        }
                                },
                                {
                                        style: 'newtable',
                                        table: {
                                                widths: tablewidth,
                                                body: [
                                                        [
                                                                {
                                                                        text: '\nDate',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        rowSpan: 2,
                                                                        border: [true, true, true, false]


                                                                },
                                                                {
                                                                        text: '\nHS-UOL',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        rowSpan: 2,
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: '\nFlt.Report No.',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        rowSpan: 2,
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: 'Flight Time',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        colSpan: 2

                                                                },
                                                                {
                                                                        text: '02:19',
                                                                        style: 'tabledata',
                                                                        alignment: 'center'

                                                                },
                                                                {
                                                                        text: 'Passenger',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        colSpan: 2

                                                                },
                                                                {
                                                                        text: '11',
                                                                        style: 'tabledata',
                                                                        alignment: 'center'

                                                                },
                                                                {
                                                                        text: 'Fuel ',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        colSpan: 2

                                                                },
                                                                {
                                                                        text: '2000',
                                                                        style: 'tabledata',
                                                                        alignment: 'center'

                                                                },
                                                                {
                                                                        text: '\nRemark',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        rowSpan: 2,
                                                                        border: [true, true, true, false]

                                                                }
                                                        ],
                                                        [
                                                                {
                                                                        text: 'Date',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: 'A/C',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: 'Flt.Report No.',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: 'Revenue',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: 'Non-Revenue',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: 'Out',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: 'In',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: 'VTSH',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: 'Non-Revenue',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: '\nRemark',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, true, true, false]

                                                                }
                                                        ],


                                                ]
                                        }
                                },
                                {
                                        style: 'table0',
                                        table: {

                                                widths: tablewidth,
                                                body: datauod

                                        }
                                },
                                {
                                        style: 'table0',
                                        table: {

                                                widths: tablewidth,
                                                body: [
                                                        [
                                                                {
                                                                        text: ' ',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, false, true, true]

                                                                },
                                                                {
                                                                        text: 'Total',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        bold: 'True',
                                                                        border: [true, false, true, true]

                                                                },
                                                                {
                                                                        text: totaluod.flight + ' Flight',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, false, true, true]

                                                                },
                                                                {
                                                                        text: totaluod.time_revenue,
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, false, true, true]

                                                                },
                                                                {
                                                                        text: totaluod.time_non_revenue,
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, false, true, true]

                                                                },
                                                                {
                                                                        text: totaluod.pax_out,
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, false, true, true]

                                                                },
                                                                {
                                                                        text: totaluod.pax_in,
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, false, true, true]

                                                                },
                                                                {
                                                                        text: totaluod.fuel,
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, false, true, true]

                                                                },
                                                                {
                                                                        text: totaluod.fuel_non,
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, false, true, true]

                                                                },
                                                                {
                                                                        text: ' ',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, false, true, true]

                                                                }
                                                        ]
                                                ]
                                        }
                                },
                                '\n',
                                {
                                        text: 'CPOC Representstive: ..............................',
                                        style: 'textfooter'
                                },
                                {
                                        text: 'UOA Flight Operations Manager :  ..............................',
                                        style: 'textfooter'
                                },
                                {
                                        text: 'UOA Commercial Representative:    ..............................',
                                        style: 'textfooter'
                                },
                                {
                                        text: 'Issued by: ' + FullName,
                                        style: 'textfooter'
                                },
                                {
                                        text: 'Issued date: ' + Monthlyreport.DaysInMonth(month, year) + ' ' + monthname + ' ' + year,
                                        style: 'textfooter',
                                        pageBreak: 'after'
                                },
                                {
                                        style: 'tablehearder',
                                        table: {
                                                widths: [100, 310, 100],
                                                body: [
                                                        [{
                                                                image: uoaimage.logo,
                                                                fit: [30, 30],
                                                                margin: [0, 0, 0, 0],
                                                                alignment: 'center',
                                                                border: [false, false, false, false]
                                                        },
                                                        {
                                                                text: '\nUOA-CPOC Monthly Report in ' + monthname + ', ' + year + ' (HS-UOM)',
                                                                margin: [0, 0, 0, 0],
                                                                alignment: 'center',
                                                                border: [true, true, true, true]
                                                        },
                                                        {
                                                                image: customerlogo.logo,
                                                                fit: [30, 30],
                                                                margin: [0, 0, 0, 0],
                                                                alignment: 'center',
                                                                border: [false, false, false, false]
                                                        }
                                                        ],
                                                        [
                                                                {
                                                                        text: null,
                                                                        border: [false, false, false, true]
                                                                },
                                                                {
                                                                        text: null,
                                                                        border: [false, false, false, true]
                                                                },
                                                                {
                                                                        text: null,
                                                                        border: [false, false, false, true]
                                                                },
                                                        ]
                                                ]
                                        }
                                },
                                {
                                        style: 'newtable',
                                        table: {
                                                widths: tablewidth,
                                                body: [
                                                        [
                                                                {
                                                                        text: '\nDate',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        rowSpan: 2,
                                                                        border: [true, true, true, false]


                                                                },
                                                                {
                                                                        text: '\nHS-UOL',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        rowSpan: 2,
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: '\nFlt.Report No.',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        rowSpan: 2,
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: 'Flight Time',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        colSpan: 2

                                                                },
                                                                {
                                                                        text: '02:19',
                                                                        style: 'tabledata',
                                                                        alignment: 'center'

                                                                },
                                                                {
                                                                        text: 'Passenger',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        colSpan: 2

                                                                },
                                                                {
                                                                        text: '11',
                                                                        style: 'tabledata',
                                                                        alignment: 'center'

                                                                },
                                                                {
                                                                        text: 'Fuel ',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        colSpan: 2

                                                                },
                                                                {
                                                                        text: '2000',
                                                                        style: 'tabledata',
                                                                        alignment: 'center'

                                                                },
                                                                {
                                                                        text: '\nRemark',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        rowSpan: 2,
                                                                        border: [true, true, true, false]

                                                                }
                                                        ],
                                                        [
                                                                {
                                                                        text: 'Date',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: 'A/C',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: 'Flt.Report No.',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: 'Revenue',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: 'Non-Revenue',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: 'Out',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: 'In',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: 'VTSH',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: 'Non-Revenue',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: '\nRemark',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, true, true, false]

                                                                }
                                                        ],


                                                ]
                                        }
                                },
                                {
                                        style: 'table0',
                                        table: {

                                                widths: tablewidth,
                                                body: datauom

                                        }
                                },
                                {
                                        style: 'table0',
                                        table: {

                                                widths: tablewidth,
                                                body: [
                                                        [
                                                                {
                                                                        text: ' ',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, false, true, true]

                                                                },
                                                                {
                                                                        text: 'Total',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        bold: 'True',
                                                                        border: [true, false, true, true]

                                                                },
                                                                {
                                                                        text: totaluom.flight + ' Flight',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, false, true, true]

                                                                },
                                                                {
                                                                        text: totaluom.time_revenue,
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, false, true, true]

                                                                },
                                                                {
                                                                        text: totaluom.time_non_revenue,
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, false, true, true]

                                                                },
                                                                {
                                                                        text: totaluom.pax_out,
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, false, true, true]

                                                                },
                                                                {
                                                                        text: totaluom.pax_in,
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, false, true, true]

                                                                },
                                                                {
                                                                        text: totaluom.fuel,
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, false, true, true]

                                                                },
                                                                {
                                                                        text: totaluom.fuel_non,
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, false, true, true]

                                                                },
                                                                {
                                                                        text: ' ',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, false, true, true]

                                                                }
                                                        ]
                                                ]
                                        }
                                },
                                '\n',
                                {
                                        text: 'CPOC Representstive: ..............................',
                                        style: 'textfooter'
                                },
                                {
                                        text: 'UOA Flight Operations Manager :  ..............................',
                                        style: 'textfooter'
                                },
                                {
                                        text: 'UOA Commercial Representative:    ..............................',
                                        style: 'textfooter'
                                },
                                {
                                        text: 'Issued by: ' + FullName,
                                        style: 'textfooter'
                                },
                                {
                                        text: 'Issued date: ' + Monthlyreport.DaysInMonth(month, year) + ' ' + monthname + ' ' + year,
                                        style: 'textfooter',
                                        pageBreak: 'after'
                                },
                                {
                                        style: 'tablehearder',
                                        table: {
                                                widths: [100, 310, 100],
                                                body: [
                                                        [{
                                                                image: uoaimage.logo,
                                                                fit: [30, 30],
                                                                margin: [0, 0, 0, 0],
                                                                alignment: 'center',
                                                                border: [false, false, false, false]
                                                        },
                                                        {
                                                                text: '\nUOA-CPOC Monthly Report in ' + monthname + ', ' + year + ' (HS-UOK)',
                                                                margin: [0, 0, 0, 0],
                                                                alignment: 'center',
                                                                border: [true, true, true, true]
                                                        },
                                                        {
                                                                image: customerlogo.logo,
                                                                fit: [30, 30],
                                                                margin: [0, 0, 0, 0],
                                                                alignment: 'center',
                                                                border: [false, false, false, false]
                                                        }
                                                        ],
                                                        [
                                                                {
                                                                        text: null,
                                                                        border: [false, false, false, true]
                                                                },
                                                                {
                                                                        text: null,
                                                                        border: [false, false, false, true]
                                                                },
                                                                {
                                                                        text: null,
                                                                        border: [false, false, false, true]
                                                                },
                                                        ]
                                                ]
                                        }
                                },
                                {
                                        style: 'newtable',
                                        table: {
                                                widths: tablewidth,
                                                body: [
                                                        [
                                                                {
                                                                        text: '\nDate',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        rowSpan: 2,
                                                                        border: [true, true, true, false]


                                                                },
                                                                {
                                                                        text: '\nHS-UOL',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        rowSpan: 2,
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: '\nFlt.Report No.',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        rowSpan: 2,
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: 'Flight Time',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        colSpan: 2

                                                                },
                                                                {
                                                                        text: '02:19',
                                                                        style: 'tabledata',
                                                                        alignment: 'center'

                                                                },
                                                                {
                                                                        text: 'Passenger',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        colSpan: 2

                                                                },
                                                                {
                                                                        text: '11',
                                                                        style: 'tabledata',
                                                                        alignment: 'center'

                                                                },
                                                                {
                                                                        text: 'Fuel ',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        colSpan: 2

                                                                },
                                                                {
                                                                        text: '2000',
                                                                        style: 'tabledata',
                                                                        alignment: 'center'

                                                                },
                                                                {
                                                                        text: '\nRemark',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        rowSpan: 2,
                                                                        border: [true, true, true, false]

                                                                }
                                                        ],
                                                        [
                                                                {
                                                                        text: 'Date',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: 'A/C',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: 'Flt.Report No.',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: 'Revenue',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: 'Non-Revenue',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: 'Out',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: 'In',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: 'VTSH',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: 'Non-Revenue',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: '\nRemark',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, true, true, false]

                                                                }
                                                        ],


                                                ]
                                        }
                                },
                                {
                                        style: 'table0',
                                        table: {

                                                widths: tablewidth,
                                                body: datauok

                                        }
                                },
                                {
                                        style: 'table0',
                                        table: {

                                                widths: tablewidth,
                                                body: [
                                                        [
                                                                {
                                                                        text: ' ',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, false, true, true]

                                                                },
                                                                {
                                                                        text: 'Total',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        bold: 'True',
                                                                        border: [true, false, true, true]

                                                                },
                                                                {
                                                                        text: totaluok.flight + ' Flight',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, false, true, true]

                                                                },
                                                                {
                                                                        text: totaluok.time_revenue,
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, false, true, true]

                                                                },
                                                                {
                                                                        text: totaluok.time_non_revenue,
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, false, true, true]

                                                                },
                                                                {
                                                                        text: totaluok.pax_out,
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, false, true, true]

                                                                },
                                                                {
                                                                        text: totaluok.pax_in,
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, false, true, true]

                                                                },
                                                                {
                                                                        text: totaluok.fuel,
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, false, true, true]

                                                                },
                                                                {
                                                                        text: totaluok.fuel_non,
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, false, true, true]

                                                                },
                                                                {
                                                                        text: ' ',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, false, true, true]

                                                                }
                                                        ]
                                                ]
                                        }
                                },
                                '\n',
                                {
                                        text: 'CPOC Representstive: ..............................',
                                        style: 'textfooter'
                                },
                                {
                                        text: 'UOA Flight Operations Manager :  ..............................',
                                        style: 'textfooter'
                                },
                                {
                                        text: 'UOA Commercial Representative:    ..............................',
                                        style: 'textfooter'
                                },
                                {
                                        text: 'Issued by: ' + FullName,
                                        style: 'textfooter'
                                },
                                {
                                        text: 'Issued date: ' + Monthlyreport.DaysInMonth(month, year) + ' ' + monthname + ' ' + year,
                                        style: 'textfooter',
                                        pageBreak: 'after'
                                },
                                {
                                        style: 'tablehearder',
                                        table: {
                                                widths: [100, 310, 100],
                                                body: [
                                                        [{
                                                                image: uoaimage.logo,
                                                                fit: [30, 30],
                                                                margin: [0, 0, 0, 0],
                                                                alignment: 'center',
                                                                border: [false, false, false, false]
                                                        },
                                                        {
                                                                text: '\nUOA-CPOC Monthly Report in ' + monthname + ', ' + year + ' (HS-UOL)',
                                                                margin: [0, 0, 0, 0],
                                                                alignment: 'center',
                                                                border: [true, true, true, true]
                                                        },
                                                        {
                                                                image: customerlogo.logo,
                                                                fit: [30, 30],
                                                                margin: [0, 0, 0, 0],
                                                                alignment: 'center',
                                                                border: [false, false, false, false]
                                                        }
                                                        ],
                                                        [
                                                                {
                                                                        text: null,
                                                                        border: [false, false, false, true]
                                                                },
                                                                {
                                                                        text: null,
                                                                        border: [false, false, false, true]
                                                                },
                                                                {
                                                                        text: null,
                                                                        border: [false, false, false, true]
                                                                },
                                                        ]
                                                ]
                                        }
                                },
                                {
                                        style: 'newtable',
                                        table: {
                                                widths: tablewidth,
                                                body: [
                                                        [
                                                                {
                                                                        text: '\nDate',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        rowSpan: 2,
                                                                        border: [true, true, true, false]


                                                                },
                                                                {
                                                                        text: '\nHS-UOL',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        rowSpan: 2,
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: '\nFlt.Report No.',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        rowSpan: 2,
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: 'Flight Time',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        colSpan: 2

                                                                },
                                                                {
                                                                        text: '02:19',
                                                                        style: 'tabledata',
                                                                        alignment: 'center'

                                                                },
                                                                {
                                                                        text: 'Passenger',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        colSpan: 2

                                                                },
                                                                {
                                                                        text: '11',
                                                                        style: 'tabledata',
                                                                        alignment: 'center'

                                                                },
                                                                {
                                                                        text: 'Fuel ',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        colSpan: 2

                                                                },
                                                                {
                                                                        text: '2000',
                                                                        style: 'tabledata',
                                                                        alignment: 'center'

                                                                },
                                                                {
                                                                        text: '\nRemark',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        rowSpan: 2,
                                                                        border: [true, true, true, false]

                                                                }
                                                        ],
                                                        [
                                                                {
                                                                        text: 'Date',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: 'A/C',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: 'Flt.Report No.',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: 'Revenue',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: 'Non-Revenue',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: 'Out',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: 'In',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: 'VTSH',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: 'Non-Revenue',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: '\nRemark',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, true, true, false]

                                                                }
                                                        ],


                                                ]
                                        }
                                },
                                {
                                        style: 'table0',
                                        table: {

                                                widths: tablewidth,
                                                body: datauol

                                        }
                                },
                                {
                                        style: 'table0',
                                        table: {

                                                widths: tablewidth,
                                                body: [
                                                        [
                                                                {
                                                                        text: ' ',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, false, true, true]

                                                                },
                                                                {
                                                                        text: 'Total',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        bold: 'True',
                                                                        border: [true, false, true, true]

                                                                },
                                                                {
                                                                        text: totaluol.flight + ' Flight',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, false, true, true]

                                                                },
                                                                {
                                                                        text: totaluol.time_revenue,
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, false, true, true]

                                                                },
                                                                {
                                                                        text: totaluol.time_non_revenue,
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, false, true, true]

                                                                },
                                                                {
                                                                        text: totaluol.pax_out,
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, false, true, true]

                                                                },
                                                                {
                                                                        text: totaluol.pax_in,
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, false, true, true]

                                                                },
                                                                {
                                                                        text: totaluol.fuel,
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, false, true, true]

                                                                },
                                                                {
                                                                        text: totaluol.fuel_non,
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, false, true, true]

                                                                },
                                                                {
                                                                        text: ' ',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, false, true, true]

                                                                }
                                                        ]
                                                ]
                                        }
                                },
                                '\n',
                                {
                                        text: 'CPOC Representstive: ..............................',
                                        style: 'textfooter'
                                },
                                {
                                        text: 'UOA Flight Operations Manager :  ..............................',
                                        style: 'textfooter'
                                },
                                {
                                        text: 'UOA Commercial Representative:    ..............................',
                                        style: 'textfooter'
                                },
                                {
                                        text: 'Issued by: ' + FullName,
                                        style: 'textfooter'
                                },
                                {
                                        text: 'Issued date: ' + Monthlyreport.DaysInMonth(month, year) + ' ' + monthname + ' ' + year,
                                        style: 'textfooter',
                                        pageBreak: 'after'
                                },
                                {
                                        style: 'tablehearder',
                                        table: {
                                                widths: [100, 310, 100],
                                                body: [
                                                        [{
                                                                image: uoaimage.logo,
                                                                fit: [30, 30],
                                                                margin: [0, 0, 0, 0],
                                                                alignment: 'center',
                                                                border: [false, false, false, false]
                                                        },
                                                        {
                                                                text: '\nUOA-CPOC Monthly Report in ' + monthname + ', ' + year + ' (Valeura Energy)',
                                                                margin: [0, 0, 0, 0],
                                                                alignment: 'center',
                                                                border: [true, true, true, true]
                                                        },
                                                        {
                                                                image: customerlogo.logo,
                                                                fit: [30, 30],
                                                                margin: [0, 0, 0, 0],
                                                                alignment: 'center',
                                                                border: [false, false, false, false]
                                                        }
                                                        ],
                                                        [
                                                                {
                                                                        text: null,
                                                                        border: [false, false, false, true]
                                                                },
                                                                {
                                                                        text: null,
                                                                        border: [false, false, false, true]
                                                                },
                                                                {
                                                                        text: null,
                                                                        border: [false, false, false, true]
                                                                },
                                                        ]
                                                ]
                                        }
                                },
                                {
                                        style: 'newtable',
                                        table: {
                                                widths: tablewidth,
                                                body: [
                                                        [
                                                                {
                                                                        text: '\nDate',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        rowSpan: 2,
                                                                        border: [true, true, true, false]


                                                                },
                                                                {
                                                                        text: '\nHS-UOL',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        rowSpan: 2,
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: '\nFlt.Report No.',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        rowSpan: 2,
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: 'Flight Time',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        colSpan: 2

                                                                },
                                                                {
                                                                        text: '02:19',
                                                                        style: 'tabledata',
                                                                        alignment: 'center'

                                                                },
                                                                {
                                                                        text: 'Passenger',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        colSpan: 2

                                                                },
                                                                {
                                                                        text: '11',
                                                                        style: 'tabledata',
                                                                        alignment: 'center'

                                                                },
                                                                {
                                                                        text: 'Fuel ',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        colSpan: 2

                                                                },
                                                                {
                                                                        text: '2000',
                                                                        style: 'tabledata',
                                                                        alignment: 'center'

                                                                },
                                                                {
                                                                        text: '\nRemark',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        rowSpan: 2,
                                                                        border: [true, true, true, false]

                                                                }
                                                        ],
                                                        [
                                                                {
                                                                        text: 'Date',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: 'A/C',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: 'Flt.Report No.',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: 'Revenue',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: 'Non-Revenue',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: 'Out',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: 'In',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: 'VTSH',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: 'Non-Revenue',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: '\nRemark',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, true, true, false]

                                                                }
                                                        ],


                                                ]
                                        }
                                },
                                {
                                        style: 'table0',
                                        table: {

                                                widths: tablewidth,
                                                body: datamu

                                        }
                                },
                                {
                                        style: 'table0',
                                        table: {

                                                widths: tablewidth,
                                                body: [
                                                        [
                                                                {
                                                                        text: ' ',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, false, true, true]

                                                                },
                                                                {
                                                                        text: 'Total',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        bold: 'True',
                                                                        border: [true, false, true, true]

                                                                },
                                                                {
                                                                        text: totalmu.flight + ' Flight',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, false, true, true]

                                                                },
                                                                {
                                                                        text: totalmu.time_revenue,
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, false, true, true]

                                                                },
                                                                {
                                                                        text: totalmu.time_non_revenue,
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, false, true, true]

                                                                },
                                                                {
                                                                        text: totalmu.pax_out,
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, false, true, true]

                                                                },
                                                                {
                                                                        text: totalmu.pax_in,
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, false, true, true]

                                                                },
                                                                {
                                                                        text: totalmu.fuel,
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, false, true, true]

                                                                },
                                                                {
                                                                        text: totalmu.fuel_non,
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, false, true, true]

                                                                },
                                                                {
                                                                        text: ' ',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, false, true, true]

                                                                }
                                                        ]
                                                ]
                                        }
                                },
                                '\n',
                                {
                                        text: 'CPOC Representstive: ..............................',
                                        style: 'textfooter'
                                },
                                {
                                        text: 'UOA Flight Operations Manager :  ..............................',
                                        style: 'textfooter'
                                },
                                {
                                        text: 'UOA Commercial Representative:    ..............................',
                                        style: 'textfooter'
                                },
                                {
                                        text: 'Issued by: ' + FullName,
                                        style: 'textfooter'
                                },
                                {
                                        text: 'Issued date: ' + Monthlyreport.DaysInMonth(month, year) + ' ' + monthname + ' ' + year,
                                        style: 'textfooter',
                                        pageBreak: 'after'
                                },
                                {
                                        style: 'tablehearder',
                                        table: {
                                                widths: [100, 310, 100],
                                                body: [
                                                        [{
                                                                image: uoaimage.logo,
                                                                fit: [30, 30],
                                                                margin: [0, 0, 0, 0],
                                                                alignment: 'center',
                                                                border: [false, false, false, false]
                                                        },
                                                        {
                                                                text: '\nUOA-CPOC Monthly Report in ' + monthname + ', ' + year + ' (PRP)',
                                                                margin: [0, 0, 0, 0],
                                                                alignment: 'center',
                                                                border: [true, true, true, true]
                                                        },
                                                        {
                                                                image: customerlogo.logo,
                                                                fit: [30, 30],
                                                                margin: [0, 0, 0, 0],
                                                                alignment: 'center',
                                                                border: [false, false, false, false]
                                                        }
                                                        ],
                                                        [
                                                                {
                                                                        text: null,
                                                                        border: [false, false, false, true]
                                                                },
                                                                {
                                                                        text: null,
                                                                        border: [false, false, false, true]
                                                                },
                                                                {
                                                                        text: null,
                                                                        border: [false, false, false, true]
                                                                },
                                                        ]
                                                ]
                                        }
                                },
                                {
                                        style: 'newtable',
                                        table: {
                                                widths: tablewidth,
                                                body: [
                                                        [
                                                                {
                                                                        text: '\nDate',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        rowSpan: 2,
                                                                        border: [true, true, true, false]


                                                                },
                                                                {
                                                                        text: '\nHS-UOL',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        rowSpan: 2,
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: '\nFlt.Report No.',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        rowSpan: 2,
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: 'Flight Time',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        colSpan: 2

                                                                },
                                                                {
                                                                        text: '02:19',
                                                                        style: 'tabledata',
                                                                        alignment: 'center'

                                                                },
                                                                {
                                                                        text: 'Passenger',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        colSpan: 2

                                                                },
                                                                {
                                                                        text: '11',
                                                                        style: 'tabledata',
                                                                        alignment: 'center'

                                                                },
                                                                {
                                                                        text: 'Fuel ',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        colSpan: 2

                                                                },
                                                                {
                                                                        text: '2000',
                                                                        style: 'tabledata',
                                                                        alignment: 'center'

                                                                },
                                                                {
                                                                        text: '\nRemark',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        rowSpan: 2,
                                                                        border: [true, true, true, false]

                                                                }
                                                        ],
                                                        [
                                                                {
                                                                        text: 'Date',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: 'A/C',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: 'Flt.Report No.',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: 'Revenue',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: 'Non-Revenue',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: 'Out',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: 'In',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: 'VTSH',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: 'Non-Revenue',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, true, true, false]

                                                                },
                                                                {
                                                                        text: '\nRemark',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, true, true, false]

                                                                }
                                                        ],


                                                ]
                                        }
                                },
                                {
                                        style: 'table0',
                                        table: {

                                                widths: tablewidth,
                                                body: dataprp

                                        }
                                },
                                {
                                        style: 'table0',
                                        table: {

                                                widths: tablewidth,
                                                body: [
                                                        [
                                                                {
                                                                        text: ' ',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, false, true, true]

                                                                },
                                                                {
                                                                        text: 'Total',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        bold: 'True',
                                                                        border: [true, false, true, true]

                                                                },
                                                                {
                                                                        text: totalprp.flight + ' Flight',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, false, true, true]

                                                                },
                                                                {
                                                                        text: totalprp.time_revenue,
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, false, true, true]

                                                                },
                                                                {
                                                                        text: totalprp.time_non_revenue,
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, false, true, true]

                                                                },
                                                                {
                                                                        text: totalprp.pax_out,
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, false, true, true]

                                                                },
                                                                {
                                                                        text: totalprp.pax_in,
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, false, true, true]

                                                                },
                                                                {
                                                                        text: totalprp.fuel,
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, false, true, true]

                                                                },
                                                                {
                                                                        text: totalprp.fuel_non,
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, false, true, true]

                                                                },
                                                                {
                                                                        text: ' ',
                                                                        style: 'tabledata',
                                                                        alignment: 'center',
                                                                        border: [true, false, true, true]

                                                                }
                                                        ]
                                                ]
                                        }
                                },
                                '\n',
                                {
                                        text: 'CPOC Representstive: ..............................',
                                        style: 'textfooter'
                                },
                                {
                                        text: 'UOA Flight Operations Manager :  ..............................',
                                        style: 'textfooter'
                                },
                                {
                                        text: 'UOA Commercial Representative:    ..............................',
                                        style: 'textfooter'
                                },
                                {
                                        text: 'Issued by: ' + FullName,
                                        style: 'textfooter'
                                },
                                {
                                        text: 'Issued date: ' + Monthlyreport.DaysInMonth(month, year) + ' ' + monthname + ' ' + year,
                                        style: 'textfooter',
                                        pageBreak: 'after'
                                },

                        ],
                        styles: {
                                header: {
                                        fontSize: 18,
                                        bold: true,
                                        alignment: 'right',
                                        margin: [0, 190, 0, 80]
                                },
                                subheader: {
                                        fontSize: 14
                                },
                                superMargin: {
                                        margin: [20, 0, 40, 0],
                                        fontSize: 15
                                },
                                newtable: {
                                        margin: [-30, 10, 0, 0],
                                },
                                table0: {
                                        margin: [-30, 0, 0, 0],
                                        fontSize: 8
                                },
                                tabledata: {
                                        fontSize: 10
                                },
                                tabledataremark: { fontSize: 8 },

                                tablehearder: { margin: [-10, -20, 0, 0], },
                                textfooter: {
                                        margin: [225, 0, 0, 5],
                                        fontSize: 10
                                }
                        },


                };
                pdfMake.createPdf(dd).open({});
        },
        CreatePTTEPdf: async (month, monthname, year, oid, datamain, totalmain, FullName) => {
                var uoaimage = await LogoService.GetLogoBaseurl64(1);
                var customerlogo = await LogoService.GetLogoBaseurl64(6);
                var tablewidth = [30, 20, 25, 45, 25, 25, 15, 15, 25, 25, 28, 25, 25, 25, 25,28,28, 80]
                var pdfMake = require("../../public/js/pdfmake");
                if (pdfMake.vfs == undefined) {
                        var pdfFonts = require("../../public/js/vfs_fonts");
                        pdfMake.vfs = pdfFonts.pdfMake.vfs;
                }

                var dd = {
                        
                        pageMargins: [40, 60, 40, 60],
                        header: {
                                style: 'tablehearder',
                                table: {
                                        widths: [80, 350, 80],
                                        body: [
                                                [{
                                                        image: uoaimage.logo,
                                                        fit: [30, 30],
                                                        margin: [0, 0, 0, 0],
                                                        alignment: 'center',
                                                        border: [false, false, false, false]
                                                },
                                                {
                                                        text: '\nUOA Monthly Report in ' + monthname + ', ' + year + ' (Valeura Energy)',
                                                        margin: [0, 0, 0, 0],
                                                        alignment: 'center',
                                                        border: [true, true, true, true]
                                                },
                                                {
                                                        image: customerlogo.logo,
                                                        fit: [50, 50],
                                                        margin: [30, 0, 0, 0],
                                                        alignment: 'center',
                                                        border: [false, false, false, false]
                                                }
                                                ],
                                                [
                                                        {
                                                                text: null,
                                                                border: [false, false, false, true]
                                                        },
                                                        {
                                                                text: null,
                                                                border: [false, false, false, true]
                                                        },
                                                        {
                                                                text: null,
                                                                border: [false, false, false, true]
                                                        },
                                                ]
                                        ]
                                }
                        },
                        content: [

                                {
                                        style: 'newtable',
                                        table: {
                                                widths: tablewidth,
                                                body:  datamain
                                        },layout: {
                                                paddingLeft: function (i, node) { return 1; },
                                                paddingRight: function (i, node) { return 1; },
                                                paddingTop: function (i, node) { return 1; },
                                                paddingBottom: function (i, node) { return 1; },
                                                }
                                },
                                '\n',
                                {
                                        text: 'PTTEP Representstive: ..............................',
                                        style: 'textfooter'
                                },
                                {
                                        text: 'UOA Chief Pilot :  ..............................',
                                        style: 'textfooter'
                                },
                                {
                                        text: 'Issued by: ' + FullName,
                                        style: 'textfooter'
                                },
                                {
                                        text: 'Issued date: ' + Monthlyreport.DaysInMonth(month, year) + ' ' + monthname + ' ' + year,
                                        style: 'textfooter'
                                },
                        ],
                        styles: {
                                header: {
                                        fontSize: 18,
                                        bold: true,
                                        alignment: 'right',
                                        margin: [0, 190, 0, 80]
                                },
                                subheader: {
                                        fontSize: 14
                                },
                                superMargin: {
                                        margin: [20, 0, 40, 0],
                                        fontSize: 15
                                },
                                newtable: {
                                        margin: [-30, 0, 0, 0],
                                        fontSize: 6,
                                        alignment:'center'
                                },
                                table0: {
                                        margin: [-30, 0, 0, 0],
                                        fontSize: 8
                                },
                                tabledata: {
                                        fontSize: 7
                                },
                                tabledataremark: { fontSize: 8 },

                                tablehearder: { margin: [25, 15, 0, 0] ,alignment:'center'},
                                textfooter: {
                                        margin: [400, 0, 0, 5],
                                        fontSize: 10
                                }
                        },

                };
                pdfMake.createPdf(dd).open({});
        }


}