
export const ConvertService = {
    ConvertMinutesToHoursMinutes(minutes) {
        var hours = Math.floor(minutes / 60);
        var remainingMinutes = minutes % 60;
        return {
            hours: hours,
            minutes: remainingMinutes
        };
    },
    //Get current user from local storage
    MinToHHmm: (minutes) => {
        //convert minute to hour and minute
        if (minutes != null && minutes != 0) {
            var hours = parseInt(minutes / 60);
            var minutes = minutes % 60;
            if (hours.toString().length == 1) {
                hours = "0" + hours;
            }else{
                hours = parseInt(hours);
            }
            if (parseInt(minutes).toString().length == 1) {
                minutes = "0" + parseInt(minutes);
            }else{
                minutes = parseInt(minutes);
            }
            return hours + ":" + minutes;
        } else {
            return "00:00";
        }
    },

    MinToHH: (minutes) => {
        //convert minute to hour and minute
        if (minutes != null && minutes != 0) {
            var hours = parseInt(minutes / 60);
            var minutes = minutes % 60;
            //return ;
            return hours+(minutes/60);
        } else {
            return 0.00;
        }
    },


    HHmmToMin: (HHmm) => {
        // if (HHmm == null || HHmm == "" || HHmm.length < 5) {
        if (HHmm == null && HHmm == "" && text.includes(":") && text.contran(":") == 1) {
            return 0;
        } else {
            var parts = HHmm.split(":"); // Split the time string into hours and minutes
            var hours = parseInt(parts[0]); // Extract the hours part and parse it as an integer
            var minutes = parseInt(parts[1]); // Extract the minutes part and parse it as an integer 
            return hours * 60 + minutes; // Convert hours to minutes and add the minutes
        }
    },
}
