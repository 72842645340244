import Vue from 'vue'
import Router from 'vue-router'
import feathersClientUOA from "./plugins/feathers-client-uoa";
Vue.use(Router)
const router = new Router({
  routes: [
    {
      path: '/login',
      name: 'Login',
      component: () => import('@/views/Login.vue'),
    },
    {
      path: '/about',
      name: 'About',
      component: () => import('@/views/About.vue'),
    },
    {
      path: '/home',
      name: 'Home',
      component: () => import('@/views/Home.vue'),
    },
    {
      path: '/userinfo',
      name: 'UserInfo',
      component: () => import('@/views/UserInfo.vue'),
    },
    {
      path: '/realtime',
      name: 'Realtime',
      component: () => import('@/views/Realtime.vue'),
    },
    {
      path: '/vehicle',
      name: 'Vehicle',
      component: () => import('@/views/Vehicle.vue'),
    },
    {
      path: '/driver',
      name: 'Driver',
      component: () => import('@/views/Driver.vue'),
    },
    {
      path: '/history',
      name: 'History',
      component: () => import('@/views/History.vue'),
    },
    {
      path: '/dashboard',
      name: 'Dashboard',
      component: () => import('@/views/Dashboard.vue'),
    },
    {
      path: '/flight',
      name: 'Flight',
      component: () => import('@/views/Flight.vue'),
    },
    {
      path: '/flightdetail/:mode/:id',
      name: 'FlightDetail',
      component: () => import('@/views/FlightDetail.vue'),
    },
    {
      path: '/flightlog',
      name: 'FlightLog',
      component: () => import('@/views/FlightLog.vue'),
    },
    {
      path: '/flightlogdetail/:mode/:id',
      name: 'FlightLogDetail',
      component: () => import('@/views/FlightLogDetail.vue'),
    },
    {
      path: '/booking',
      name: 'Booking',
      component: () => import('@/views/Booking.vue'),
    },
    {
      path: '/bookingdetail/:mode/:id',
      name: 'BookingDetail',
      component: () => import('@/views/BookingDetail.vue'),
    },
    {
      path: '/passenger',
      name: 'Passenger',
      component: () => import('@/views/Passenger.vue'),
    },
    {
      path: '/checkinactive',
      name: 'CheckinActive',
      component: () => import('@/views/CheckinActive.vue'),
    },
    {
      path: '/checkinclosed',
      name: 'CheckinClosed',
      component: () => import('@/views/CheckinClosed.vue'),
    },
    {
      path: '/checkintakeoff',
      name: 'CheckinTakeoff',
      component: () => import('@/views/CheckinTakeoff.vue'),
    },
    {
      path: '/checkindetail/:id',
      name: 'CheckinDetail',
      component: () => import('@/views/CheckinDetail.vue'),
    },
    {
      path: '/personalcheckin/:id/:o_name/:destination/:skip_pdpa/:o_id/:f_id',
      name: 'PersonalCheckin',
      component: () => import('@/views/PersonalCheckin.vue'),
    },
    {
      path: '/expiredexport',
      name: 'ExpiredExport',
      component: () => import('@/views/ExpiredExport.vue'),
    },
    {
      path: '/expired',
      name: 'Expired',
      component: () => import('@/views/Expired.vue'),
    },
    {
      path: '/manageuser',
      name: 'ManageUser',
      component: () => import('@/views/ManageUser.vue'),
    },
    {
      path: '/adduser',
      name: 'AddUser',
      component: () => import('@/views/AddUser.vue'),
    },
    {
      path: '/userprofile',
      name: 'UserProfile',
      component: () => import('@/views/UserProfile.vue'),
    },{
      path: '/passengerhistory',
      name: 'PassengerHistory',
      component: () => import('@/views/PassengerHistory.vue'),
    },
    {
      path: '/managemicrosoftrole',
      name: 'ManageMicrosoftRole',
      component: () => import('@/views/ManageMicrosoftRole.vue'),
    },
    {
      path: '/manageowner',
      name: 'ManageOwner',
      component: () => import('@/views/ManageOwner.vue'),
    },
    {
      path: '/managecompany',
      name: 'ManageCompany',
      component: () => import('@/views/ManageCompany.vue'),
    },
    {
      path: '/managecompanysync',
      name: 'ManageCompanySync',
      component: () => import('@/views/ManageCompanySync.vue'),
    },
    {
      path: '/managerole',
      name: 'ManageRole',
      component: () => import('@/views/ManageRole.vue'),
    },
    {
      path: '/manageterminal',
      name: 'ManageTerminal',
      component: () => import('@/views/ManageTerminal.vue'),
    },
    {
      path: '/manageaircraft/:mode/:idflight',
      name: 'ManageAircraft',
      component: () => import('@/views/ManageAircraft.vue'),
    },
    {
      path: '/aircraftdetail/:mode/:idflight/:idaircraft',
      name: 'AircraftDetail',
      component: () => import('@/views/AircraftDetail.vue'),
    },
    {
      path: '/manageairport',
      name: 'ManageAirport',
      component: () => import('@/views/ManageAirport.vue'),
    },
    {
      path: '/managedriver',
      name: 'ManageDriver',
      component: () => import('@/views/ManageDriver.vue'),
    },
    {
      path: '/manageflightstatus',
      name: 'ManageFlightStatus',
      component: () => import('@/views/ManageFlightStatus.vue'),
    },
    {
      path: '/managealcoholstatus',
      name: 'ManageAlcoholStatus',
      component: () => import('@/views/ManageAlcoholStatus.vue'),
    },
    {
      path: '/managetransportlocation',
      name: 'ManageTransportLocation',
      component: () => import('@/views/ManageTransportLocation.vue'),
    },
    {
      path: '/managepilot',
      name: 'ManagePilot',
      component: () => import('@/views/ManagePilot.vue'),
    },
    {
      path: '/managepilotedit/:id',
      name: 'ManagePilotEdit',
      component: () => import('@/views/ManagePilotEdit.vue'),
    },
    {
      path: '/test',
      name: 'Test',
      component: () => import('@/views/Test.vue'),
    },
    {
      path: '/realtimeheli',
      name: 'RealtimeHeli',
      component: () => import('@/views/RealtimeHeli.vue'),
    },
    {
      path: '/historyheli',
      name: 'HistoryHeli',
      component: () => import('@/views/HistoryHeli.vue'),
    },
    {
      path: '/margetracking',
      name: 'MargeTracking',
      component: () => import('@/views/MargeTracking.vue'),
    },
    {
      path: '/alldialogs',
      name: 'All Dialogs',
      component: () => import('@/views/Alldialogs.vue'),
    },
    {
      path: '/transport',
      name: 'Transport',
      component: () => import('@/views/Transport.vue'),
    },
    {
      path: '/transportdetail/:mode/:id/:b_id',
      name: 'TransportDetail',
      component: () => import('@/views/TransportDetail.vue'),
    },
    {
      path: '/flightdelayreport',
      name: 'FlightDelayReport',
      component: () => import('@/views/FlightDelayReport.vue'),
    },
    {
      path: '/workinprogress',
      name: 'WorkInProgress',
      component: () => import('@/views/WorkInProgress.vue'),
    },
    {
      path: '/monthlychange',
      name: 'MonthlyChange',
      component: () => import('@/views/MonthlyChange.vue'),
    },
    {
      path: '/feedaily',
      name: 'FeeDaily',
      component: () => import('@/views/FeeDaily.vue'),
    },
    {
      path: '/briefscan/:id',
      name: 'BriefScan',
      component: () => import('@/views/BriefScan.vue'),
    },
    {
      path: '/accessdenied',
      name: 'accessdenied',
      component: () => import('@/views/AccessDenied.vue'),
    },
    {
      path: '/scheduleperson',
      name: 'SchedulePerson',
      component: () => import('@/views/SchedulePerson.vue'),
    },
    {
      path: '/scheduleselect',
      name: 'ScheduleSelect',
      component: () => import('@/views/ScheduleSelect.vue'),
    },
    {
      path: '/endofdayreport',
      name: 'End of Day Report',
      component: () => import('@/views/EndofDayReport.vue'),
    },
    {
      path: '/crewschedule',
      name: 'CrewSchedule',
      component: () => import('@/views/CrewSchedule.vue'),
    },
    {
      path: '/trainingtrack',
      name: 'TrainingTrack',
      component: () => import('@/views/TrainingTrack.vue'),
    },
    {
      path: '/flightdashboard',
      name: 'FlightDashBoard',
      component: () => import('@/views/FlightDashBoard.vue'),
    },
    {
      path: '/passengerreport',
      name: 'PassengerReport',
      component: () => import('@/views/PassengerReport.vue'),
    },


    {
      path: '/dailyflightreport',
      name: 'DailyFlightReport',
      component: () => import('@/views/DailyFlightReport.vue'),
    },
    {
      path: '/monthlyflightreport',
      name: 'MonthlyFlightReport',
      component: () => import('@/views/MonthlyFlightReport.vue'),
    },
    /*{
      path: '/draftinvoice',
      name: 'DraftInvoice',
      component: () => import('@/views/DraftInvoice.vue'),
    },*/
    {
      path: '/seatlayout/:mode/:idflight',
      name: 'SeatLayout',
      component: () => import('@/views/SeatLayout.vue'),
    },
    {
      path: '/fuel',
      name: 'Fuel',
      component: () => import('@/views/Fuel.vue'),
    },
    {
      path: '/managefuel/:idflight',
      name: 'ManageFuel',
      component: () => import('@/views/ManageFuel.vue'),
    },
    {
      path: '/managefuelstation',
      name: 'ManageFuelStation',
      component: () => import('@/views/ManageFuelStation.vue'),
    },
    {
      path: '/fuelreport',
      name: 'FuelReport',
      component: () => import('@/views/FuelReport.vue'),
    },
    {
      path: '/alcoholcheckandtimeattendance',
      name: 'AlcoholCheckAndTimeAttendance',
      component: () => import('@/views/AlcoholCheckAndTimeAttendance.vue'),
    },
    {
      path: '/EngineerSchedule',
      name: 'EngineerSchedule',
      component: () => import('@/views/EngineerSchedule.vue'),
    },
    {
      path: '/managevideofile',
      name: 'ManageVideoFile',
      component: () => import('@/views/ManageVideoFile.vue'),
    },
    {
      path: '/onbehalfdetail',
      name: 'OnBehalfDetail',
      component: () => import('@/views/OnBehalfDetail.vue'),
    },
    {
      path: '/realtimemonitor',
      name: 'RealTimeMonitor',
      component: () => import('@/views/RealTimeMonitor.vue'),
    },
    {
      path: '/rfidscanner',
      name: 'RFIDScanner',
      component: () => import('@/views/RFIDScanner.vue'),
    },
    {
      path: '*',
      redirect: '/home',
    },
    {
      path: '/mubadala',
      name: 'Mubadala',
      component: () => import('@/views/Mubadala.vue'),
    },
    {
      path: '/mubadaladetail/:mode/:id',
      name: 'MubadalaDetail',
      component: () => import('@/views/MubadalaDetail.vue'),
    },
    {
      path: '/formnurse/:id/:customerName/:companyName/:destination/:customerId/:f_id',
      name: 'FormNurse',
      component: () => import('@/views/FormNurse.vue'),
    },
    {
      path: '/privacynotice/:id/:idPass/:o_name/:destination/:idflight/:o_id/:f_id',
      name: 'PrivacyNotice',
      component: () => import('@/views/PrivacyNotice.vue'),
    },
    {
      path: '/flightreport1',
      name: 'FlightReport1',
      component: () => import('@/views/FlightReport1.vue'),
    },
    {
      path: '/flightreport2',
      name: 'FlightReport2',
      component: () => import('@/views/FlightReport2.vue'),
    },
    {
      path: '/flightreport3',
      name: 'FlightReport3',
      component: () => import('@/views/FlightReport3.vue'),
    },
    {
      path: '/mubadalaforcustomer/:id',
      name: 'MubadalaForCustomer',
      component: () => import('@/views/MubadalaForCustomer.vue'),
    },
    {
      path: '/mubadalaforcustomerdetail/:id',
      name: 'MubadalaForCustomerDetail',
      component: () => import('@/views/MubadalaForCustomerDetail.vue'),
    },
    {
      path: '/mubadalaforcustomereform1/:id',
      name: 'MubadalaForCustomerEForm1',
      component: () => import('@/views/MubadalaForCustomerEForm1.vue'),
    },
    {
      path: '/mubadalaforcustomereform2/:id',
      name: 'MubadalaForCustomerEForm2',
      component: () => import('@/views/MubadalaForCustomerEForm2.vue'),
    },
    {
      path: '/mubadalaforcustomereform3/:id',
      name: 'MubadalaForCustomerEForm3',
      component: () => import('@/views/MubadalaForCustomerEForm3.vue'),
    },
    {
      path: '/mubadalaforcustomereformsignature/:id',
      name: 'MubadalaForCustomerEFormSignature',
      component: () => import('@/views/MubadalaForCustomerEFormSignature.vue'),
    },
    {
      path: '/mubadalaforcustomereformthankyou',
      name: 'MubadalaForCustomerEFormThankYou',
      component: () => import('@/views/MubadalaForCustomerEFormThankYou.vue'),
    },

    {
      path: '/performancereport',
      name: 'PerformanceReport',
      component: () => import('@/views/PerformanceReport.vue'),
    },
    {
      path: '/totalflighttimereport',
      name: 'TotalFlightTimeReport',
      component: () => import('@/views/TotalFlightTimeReport.vue'),
    },
  ]
})

export default router