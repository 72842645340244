<template>
  <div id="toolbar">
    <div class="Content-Page">
      <div class="wrap-Main TF">
        <div class="B-toolbar">
          <div class="b-btnMenu" v-on:click="activemenu">
            <a class="menutoolbar"><span> Menu</span></a>
          </div>
          <div class="b-logo"></div>
          <!-- <div class="toolbar-tag ColorTheme-S T-size-18 SemiBold"><span>{{userdata.c_name}}</span></div> -->
          <!-- Edit -->
          <div class="toolbar-tag ColorTheme-S T-size-18 SemiBold">
            <span>{{ userdata.o_name }}</span>
          </div>
        </div>
      </div>
    </div>

    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark>
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <!-- IPAD -->
          <v-toolbar-title>{{ userdata.u_firstname }} {{ userdata.u_lastname }}<br>({{ userdata.r_name }})</v-toolbar-title>

          <v-spacer></v-spacer>
          <v-btn icon dark @click="Logout()">
            <v-icon>mdi-logout</v-icon>
          </v-btn>
        </v-toolbar>
        <MenuList v-on:click="dialog = false" />
      </v-card>
    </v-dialog>
  </div>
</template>


<script>
import MenuList from "./MenuList";
import { addHours, format } from "date-fns";
import feathersClientUOA from "../plugins/feathers-client-uoa";

export default {
  data: () => ({
    userdata: { o_name: "", r_id: "" },
    dialog: false,
  }),
  components: {
    MenuList,
  },
  async mounted() {
    this.userdata = this.$CurrentUser.GetCurrentUser();
  },
  methods: {
    activemenu: function () {
      this.dialog = true;
    },
    gotoprofile(Id) {
      this.$router.push({ path: "/userprofile" });
    },
    Logout() {
      try {
        feathersClientUOA.logout();
        //SEQ LOG
        this.$log.Info('{action} by {username} ({role}) :', { action: "LogoutByToolbar", username: this.userdata.u_username, role: this.userdata.r_name });

        localStorage.removeItem("user");
        // this.$router.push({ path: "/login" });
        window.location.replace("/login.html");
      } catch (error) {
        //SEQ LOG
        this.$log.Error("LogoutByToolbar, Error : {error}", { error });
        console.log("LogoutByToolbar, Error : {error}" + error);
      }
    },
  },
};
</script>

