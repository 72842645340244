
<!-- expand => multiple-->
<template>
  <div>
    <!-- Menu Admin -->
    <div class="menu" v-if="userdata.r_id == 1">
      <!-- <v-expansion-panels
        expandaccordion
        expand
        class="b-accod"
        v-model="panel"
      > -->
      <v-expansion-panels expandaccordion multiple class="b-accod">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <span class="panal-title T-size-20">System Management</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <router-link
              class="a-link"
              v-for="(item, i) in menupaths_Admin"
              :key="i"
              :to="item.path"
              @click.native="$emit('click')"
              >{{ item.text }}</router-link
            >
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <span class="panal-title T-size-20">Transport Management</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <router-link
              class="a-link"
              v-for="(item, i) in menupaths_Admin2"
              :key="i"
              :to="item.path"
              @click.native="$emit('click')"
              >{{ item.text }}</router-link
            >
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <!-- <v-expansion-panels
        expandaccordion
        expand
        class="b-accod"
        v-model="panel"
      > -->
      <v-expansion-panels expandaccordion multiple class="b-accod">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <span class="panal-title T-size-20">Fuel Management</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <router-link
              class="a-link"
              v-for="(item, i) in menupaths_Admin3"
              :key="i"
              :to="item.path"
              @click.native="$emit('click')"
              >{{ item.text }}</router-link
            >
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <!-- Menu Shore -->
    <div class="menu" v-if="userdata.r_id == 2">
      <!-- <v-expansion-panels
        expandaccordion
        expand
        class="b-accod"
        v-model="panel"
      > -->
      <v-expansion-panels expandaccordion multiple class="b-accod">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <span class="panal-title T-size-20">Flight Management</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <router-link
              class="a-link"
              v-for="(item, i) in menupaths_Shore_Flight_Management"
              :key="i"
              :to="item.path"
              @click.native="$emit('click')"
              >{{ item.text }}</router-link
            >
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <!-- <v-expansion-panels
        expandaccordion
        expand
        class="b-accod"
        v-model="panel"
      > -->
      <v-expansion-panels expandaccordion multiple class="b-accod">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <span class="panal-title T-size-20">Passenger Management</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <router-link
              class="a-link"
              v-for="(item, i) in menupaths_Shore_Passenger_Management"
              :key="i"
              :to="item.path"
              @click.native="$emit('click')"
              >{{ item.text }}</router-link
            >
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <!-- <v-expansion-panels
        expandaccordion
        expand
        class="b-accod"
        v-model="panel"
      > -->
      <v-expansion-panels expandaccordion multiple class="b-accod">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <span class="panal-title T-size-20">Ground Transportation</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <router-link
              class="a-link"
              v-for="(item, i) in menupaths_Shore_Ground_Transportation"
              :key="i"
              :to="item.path"
              @click.native="$emit('click')"
              >{{ item.text }}</router-link
            >
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <!-- <v-expansion-panels
        expandaccordion
        expand
        class="b-accod"
        v-model="panel"
      > -->
      <v-expansion-panels expandaccordion multiple class="b-accod">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <span class="panal-title T-size-20">Fuel Management</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <router-link
              class="a-link"
              v-for="(item, i) in menupaths_Flight_Fuel_Management"
              :key="i"
              :to="item.path"
              @click.native="$emit('click')"
              >{{ item.text }}</router-link
            >
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <!-- <v-expansion-panels
        expandaccordion
        expand
        class="b-accod"
        v-model="panel"
      > -->
      <v-expansion-panels expandaccordion multiple class="b-accod">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <span class="panal-title T-size-20">Customer Flight Report</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <router-link
              class="a-link"
              v-for="(item, i) in menupaths_Flight_Dis_Report"
              :key="i"
              :to="item.path"
              @click.native="$emit('click')"
              >{{ item.text }}</router-link
            >
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <!-- <v-expansion-panels
        expandaccordion
        expand
        class="b-accod"
        v-model="panel"
      > -->
      <v-expansion-panels expandaccordion multiple class="b-accod">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <span class="panal-title T-size-20">General Management</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <router-link
              class="a-link"
              v-for="(
                item, i
              ) in menupaths_Flight_Dis_Alcohol_Check_And_Time_Attendance"
              :key="i"
              :to="item.path"
              @click.native="$emit('click')"
              >{{ item.text }}</router-link
            >
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <!-- <v-expansion-panels
        expandaccordion
        expand
        class="b-accod"
        v-model="panel"
      > -->
      <v-expansion-panels expandaccordion multiple class="b-accod">
      </v-expansion-panels>
    </div>

    <!-- Menu Flight -->
    <div class="menu" v-if="userdata.r_id == 4">
      <v-expansion-panels expandaccordion multiple class="b-accod">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <span class="panal-title T-size-20">Flight Management</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <router-link
              class="a-link"
              v-for="(item, i) in menupaths_Flight_Dis_Flight_Management"
              :key="i"
              :to="item.path"
              @click.native="$emit('click')"
              >{{ item.text }}</router-link
            >
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-expansion-panels expandaccordion multiple class="b-accod">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <span class="panal-title T-size-20">Passenger Management</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <router-link
              class="a-link"
              v-for="(item, i) in menupaths_Flight_Dis_Crew_Management"
              :key="i"
              :to="item.path"
              @click.native="$emit('click')"
              >{{ item.text }}</router-link
            >
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-expansion-panels expandaccordion multiple class="b-accod">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <span class="panal-title T-size-20">Ground Transportation</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <router-link
              class="a-link"
              v-for="(item, i) in menupaths_Flight_Ground_Transportation"
              :key="i"
              :to="item.path"
              @click.native="$emit('click')"
              >{{ item.text }}</router-link
            >
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-expansion-panels expandaccordion multiple class="b-accod">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <span class="panal-title T-size-20">Fuel Management</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <router-link
              class="a-link"
              v-for="(item, i) in menupaths_Flight_Fuel_Management"
              :key="i"
              :to="item.path"
              @click.native="$emit('click')"
              >{{ item.text }}</router-link
            >
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-expansion-panels expandaccordion multiple class="b-accod">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <span class="panal-title T-size-20">Customer Flight Report</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <router-link
              class="a-link"
              v-for="(item, i) in menupaths_Flight_Dis_Report"
              :key="i"
              :to="item.path"
              @click.native="$emit('click')"
              >{{ item.text }}</router-link
            >
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-expansion-panels expandaccordion multiple class="b-accod">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <span class="panal-title T-size-20">General Management</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <router-link
              class="a-link"
              v-for="(
                item, i
              ) in menupaths_Flight_Dis_Alcohol_Check_And_Time_Attendance"
              :key="i"
              :to="item.path"
              @click.native="$emit('click')"
              >{{ item.text }}</router-link
            >
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <!-- Menu Flight -->

    <!-- Menu Super Flight -->
    <div class="menu" v-if="userdata.r_id == 21">
      <v-expansion-panels expandaccordion multiple class="b-accod">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <span class="panal-title T-size-20">Flight Management</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <router-link
              class="a-link"
              v-for="(item, i) in menupaths_Flight_Dis_Flight_Management"
              :key="i"
              :to="item.path"
              @click.native="$emit('click')"
              >{{ item.text }}</router-link
            >
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-expansion-panels expandaccordion multiple class="b-accod">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <span class="panal-title T-size-20">Check-In</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <router-link
              class="a-link"
              v-for="(item, i) in menupaths_Checkin_CheckIn"
              :key="i"
              :to="item.path"
              @click.native="$emit('click')"
              >{{ item.text }}</router-link
            >
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-expansion-panels expandaccordion multiple class="b-accod">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <span class="panal-title T-size-20">Passenger Management</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <router-link
              class="a-link"
              v-for="(item, i) in menupaths_Flight_Dis_Crew_Management"
              :key="i"
              :to="item.path"
              @click.native="$emit('click')"
              >{{ item.text }}</router-link
            >
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-expansion-panels expandaccordion multiple class="b-accod">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <span class="panal-title T-size-20">Ground Transportation</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <router-link
              class="a-link"
              v-for="(item, i) in menupaths_Flight_Ground_Transportation"
              :key="i"
              :to="item.path"
              @click.native="$emit('click')"
              >{{ item.text }}</router-link
            >
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-expansion-panels expandaccordion multiple class="b-accod">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <span class="panal-title T-size-20">Fuel Management</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <router-link
              class="a-link"
              v-for="(item, i) in menupaths_Flight_Fuel_Management"
              :key="i"
              :to="item.path"
              @click.native="$emit('click')"
              >{{ item.text }}</router-link
            >
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-expansion-panels expandaccordion multiple class="b-accod">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <span class="panal-title T-size-20">Customer Flight Report</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <router-link
              class="a-link"
              v-for="(item, i) in menupaths_Flight_Dis_Report"
              :key="i"
              :to="item.path"
              @click.native="$emit('click')"
              >{{ item.text }}</router-link
            >
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-expansion-panels expandaccordion multiple class="b-accod">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <span class="panal-title T-size-20">General Management</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <router-link
              class="a-link"
              v-for="(
                item, i
              ) in menupaths_Flight_Dis_Alcohol_Check_And_Time_Attendance"
              :key="i"
              :to="item.path"
              @click.native="$emit('click')"
              >{{ item.text }}</router-link
            >
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <!-- Menu Super Flight -->

    <!-- Menu Engineer -->
    <div class="menu" v-if="userdata.r_id == 9">
      <!-- <v-expansion-panels
        expandaccordion
        expand
        class="b-accod"
        v-model="panel"
      > -->
      <v-expansion-panels expandaccordion multiple class="b-accod">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <span class="panal-title T-size-20">Flight Managment</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <router-link
              class="a-link"
              v-for="(item, i) in menupaths_Engineer_Flight_Management"
              :key="i"
              :to="item.path"
              @click.native="$emit('click')"
              >{{ item.text }}</router-link
            >
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <!-- <v-expansion-panels expandaccordion multiple class="b-accod" v-model="panel">
          <v-expansion-panel>
            <v-expansion-panel-header>
              <span class="panal-title T-size-20">Crew Management</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <router-link
                class="a-link"
                v-for="(item,i) in menupaths_Engineer_Crew_Management"
                :key="i"
                :to="item.path" @click.native="$emit('click')"
              >{{item.text}}</router-link>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels> -->
      <!-- <v-expansion-panels
        expandaccordion
        expand
        class="b-accod"
        v-model="panel"
      > -->
      <v-expansion-panels expandaccordion multiple class="b-accod">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <span class="panal-title T-size-20">Engineering</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <router-link
              class="a-link"
              v-for="(item, i) in menupaths_Engineer_Engineering"
              :key="i"
              :to="item.path"
              @click.native="$emit('click')"
              >{{ item.text }}</router-link
            >
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <!-- <v-expansion-panels
        expandaccordion
        expand
        class="b-accod"
        v-model="panel"
      > -->
      <v-expansion-panels expandaccordion multiple class="b-accod">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <span class="panal-title T-size-20">Fuel Management</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <router-link
              class="a-link"
              v-for="(item, i) in menupaths_Engineer_Fuel_Management"
              :key="i"
              :to="item.path"
              @click.native="$emit('click')"
              >{{ item.text }}</router-link
            >
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <!-- <v-expansion-panels
        expandaccordion
        expand
        class="b-accod"
        v-model="panel"
      > -->
      <v-expansion-panels expandaccordion multiple class="b-accod">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <span class="panal-title T-size-20">Flight Monitor</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <router-link
              class="a-link"
              v-for="(item, i) in menupaths_Engineer_Flight_oMnitor"
              :key="i"
              :to="item.path"
              @click.native="$emit('click')"
              >{{ item.text }}</router-link
            >
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <!-- <v-expansion-panels
        expandaccordion
        expand
        class="b-accod"
        v-model="panel"
      > -->
      <v-expansion-panels expandaccordion multiple class="b-accod">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <span class="panal-title T-size-20">General Management</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <router-link
              class="a-link"
              v-for="(
                item, i
              ) in menupaths_Flight_Dis_Alcohol_Check_And_Time_Attendance"
              :key="i"
              :to="item.path"
              @click.native="$emit('click')"
              >{{ item.text }}</router-link
            >
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <span class="panal-title T-size-20">Engineer Management</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <router-link
              class="a-link"
              v-for="(item, i) in menupaths_Engineer_Management"
              :key="i"
              :to="item.path"
              @click.native="$emit('click')"
              >{{ item.text }}</router-link
            >
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <!-- <v-expansion-panels expandaccordion multiple class="b-accod" v-model="panel">
          <v-expansion-panel>
            <v-expansion-panel-header>
              <span class="panal-title T-size-20">Report</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <router-link
                class="a-link"
                v-for="(item,i) in menupaths_Engineer_Report"
                :key="i"
                :to="item.path" @click.native="$emit('click')"
              >{{item.text}}</router-link>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels> -->
    </div>
    <!-- Menu Check-In -->
    <div class="menu" v-if="userdata.r_id == 5">
      <!-- <v-expansion-panels
        expandaccordion
        expand
        class="b-accod"
        v-model="panel"
      > -->
      <v-expansion-panels expandaccordion multiple class="b-accod">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <span class="panal-title T-size-20">Flight Management</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <router-link
              class="a-link"
              v-for="(item, i) in menupaths_Checkin_Flight_Management"
              :key="i"
              :to="item.path"
              @click.native="$emit('click')"
              >{{ item.text }}</router-link
            >
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <!-- <v-expansion-panels
        expandaccordion
        expand
        class="b-accod"
        v-model="panel"
      > -->
      <v-expansion-panels expandaccordion multiple class="b-accod">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <span class="panal-title T-size-20">Crew Management</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <router-link
              class="a-link"
              v-for="(item, i) in menupaths_Checkin_Crew_Management"
              :key="i"
              :to="item.path"
              @click.native="$emit('click')"
              >{{ item.text }}</router-link
            >
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <!-- <v-expansion-panels
        expandaccordion
        expand
        class="b-accod"
        v-model="panel"
      > -->
      <v-expansion-panels expandaccordion multiple class="b-accod">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <span class="panal-title T-size-20">Check-In</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <router-link
              class="a-link"
              v-for="(item, i) in menupaths_Checkin_CheckIn"
              :key="i"
              :to="item.path"
              @click.native="$emit('click')"
              >{{ item.text }}</router-link
            >
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <!-- <v-expansion-panels
        expandaccordion
        expand
        class="b-accod"
        v-model="panel"
      > -->
      <v-expansion-panels expandaccordion multiple class="b-accod">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <span class="panal-title T-size-20">General Management</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <router-link
              class="a-link"
              v-for="(
                item, i
              ) in menupaths_Flight_Dis_Alcohol_Check_And_Time_Attendance"
              :key="i"
              :to="item.path"
              @click.native="$emit('click')"
              >{{ item.text }}</router-link
            >
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <!-- <v-expansion-panels expandaccordion multiple class="b-accod" v-model="panel">
          <v-expansion-panel>
            <v-expansion-panel-header>
              <span class="panal-title T-size-20">Report</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <router-link
                class="a-link"
                v-for="(item,i) in menupaths_Checkin_Report"
                :key="i"
                :to="item.path" @click.native="$emit('click')"
              >{{item.text}}</router-link>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels> -->
    </div>
    <!-- Menu Pilot -->
    <div class="menu" v-if="userdata.r_id == 12">
      <!-- <v-expansion-panels
        expandaccordion
        expand
        class="b-accod"
        v-model="panel"
      > -->
      <v-expansion-panels expandaccordion multiple class="b-accod">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <span class="panal-title T-size-20">Flight Management</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <router-link
              class="a-link"
              v-for="(item, i) in menupaths_Pilot_Flight_Management"
              :key="i"
              :to="item.path"
              @click.native="$emit('click')"
              >{{ item.text }}</router-link
            >
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <!-- <v-expansion-panels expandaccordion multiple class="b-accod" v-model="panel">
          <v-expansion-panel>
            <v-expansion-panel-header>
              <span class="panal-title T-size-20">Crew Management</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <router-link
                class="a-link"
                v-for="(item,i) in menupaths_Pilot_Crew_Management"
                :key="i"
                :to="item.path" @click.native="$emit('click')"
              >{{item.text}}</router-link>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels> -->
      <!-- <v-expansion-panels expandaccordion multiple class="b-accod" v-model="panel">
          <v-expansion-panel>
            <v-expansion-panel-header>
              <span class="panal-title T-size-20">Dispatch-Operations</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <router-link
                class="a-link"
                v-for="(item,i) in menupaths_Pilot_Dispatch_Operations"
                :key="i"
                :to="item.path" @click.native="$emit('click')"
              >{{item.text}}</router-link>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels> -->
      <!-- <v-expansion-panels expandaccordion multiple class="b-accod" v-model="panel">
          <v-expansion-panel>
            <v-expansion-panel-header>
              <span class="panal-title T-size-20">Flight Monitor</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <router-link
                class="a-link"
                v-for="(item,i) in menupaths_Pilot_Flight_Monitor"
                :key="i"
                :to="item.path" @click.native="$emit('click')"
              >{{item.text}}</router-link>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels> -->

      <!-- <v-expansion-panels
        expandaccordion
        expand
        class="b-accod"
        v-model="panel"
      > -->
      <v-expansion-panels expandaccordion multiple class="b-accod">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <span class="panal-title T-size-20">Crew Management</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <router-link
              class="a-link"
              v-for="(item, i) in menupaths_Pilot_Crew_Management"
              :key="i"
              :to="item.path"
              @click.native="$emit('click')"
              >{{ item.text }}</router-link
            >
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <!-- <v-expansion-panels
        expandaccordion
        expand
        class="b-accod"
        v-model="panel"
      > -->
      <v-expansion-panels expandaccordion multiple class="b-accod">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <span class="panal-title T-size-20">General Management</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <router-link
              class="a-link"
              v-for="(
                item, i
              ) in menupaths_Flight_Dis_Alcohol_Check_And_Time_Attendance"
              :key="i"
              :to="item.path"
              @click.native="$emit('click')"
              >{{ item.text }}</router-link
            >
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <!-- <v-expansion-panels expandaccordion multiple class="b-accod" v-model="panel">
          <v-expansion-panel>
            <v-expansion-panel-header>
              <span class="panal-title T-size-20">Report</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <router-link
                class="a-link"
                v-for="(item,i) in menupaths_Pilot_Report"
                :key="i"
                :to="item.path" @click.native="$emit('click')"
              >{{item.text}}</router-link>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels> -->
    </div>
    <!-- Menu Base Manager -->
    <div class="menu" v-if="userdata.r_id == 13">
      <!-- <v-expansion-panels
        expandaccordion
        expand
        class="b-accod"
        v-model="panel"
      > -->
      <v-expansion-panels expandaccordion multiple class="b-accod">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <span class="panal-title T-size-20">Ground Operation</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <router-link
              class="a-link"
              v-for="(item, i) in menupaths_Base_Ground_Operation"
              :key="i"
              :to="item.path"
              @click.native="$emit('click')"
              >{{ item.text }}</router-link
            >
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <!-- <v-expansion-panels
        expandaccordion
        expand
        class="b-accod"
        v-model="panel"
      > -->
      <v-expansion-panels expandaccordion multiple class="b-accod">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <span class="panal-title T-size-20">General Management</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <router-link
              class="a-link"
              v-for="(
                item, i
              ) in menupaths_Flight_Dis_Alcohol_Check_And_Time_Attendance"
              :key="i"
              :to="item.path"
              @click.native="$emit('click')"
              >{{ item.text }}</router-link
            >
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <!-- Menu Financial Accounting -->
    <div class="menu" v-if="userdata.r_id == 14">
      <!-- <v-expansion-panels
        expandaccordion
        expand
        class="b-accod"
        v-model="panel"
      > -->
      <v-expansion-panels expandaccordion multiple class="b-accod">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <span class="panal-title T-size-20">Flight Management</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <router-link
              class="a-link"
              v-for="(item, i) in menupaths_Financial_Flight_Management"
              :key="i"
              :to="item.path"
              @click.native="$emit('click')"
              >{{ item.text }}</router-link
            >
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <!-- <v-expansion-panels
        expandaccordion
        expand
        class="b-accod"
        v-model="panel"
      > -->
      <v-expansion-panels expandaccordion multiple class="b-accod">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <span class="panal-title T-size-20">Finance</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <router-link
              class="a-link"
              v-for="(item, i) in menupaths_Financial_Finance"
              :key="i"
              :to="item.path"
              @click.native="$emit('click')"
              >{{ item.text }}</router-link
            >
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <!-- <v-expansion-panels
        expandaccordion
        expand
        class="b-accod"
        v-model="panel"
      > -->
      <v-expansion-panels expandaccordion multiple class="b-accod">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <span class="panal-title T-size-20">Customer Flight Report</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <router-link
              class="a-link"
              v-for="(item, i) in menupaths_Financial_Customer_Flight_Report"
              :key="i"
              :to="item.path"
              @click.native="$emit('click')"
              >{{ item.text }}</router-link
            >
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <!-- <v-expansion-panels
        expandaccordion
        expand
        class="b-accod"
        v-model="panel"
      > -->
      <v-expansion-panels expandaccordion multiple class="b-accod">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <span class="panal-title T-size-20">General Management</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <router-link
              class="a-link"
              v-for="(
                item, i
              ) in menupaths_Flight_Dis_Alcohol_Check_And_Time_Attendance"
              :key="i"
              :to="item.path"
              @click.native="$emit('click')"
              >{{ item.text }}</router-link
            >
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>

    <!-- Menu Nurse-->
    <div class="menu" v-if="userdata.r_id == 16">
      <v-expansion-panels expandaccordion multiple class="b-accod">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <span class="panal-title T-size-20">General Management</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <router-link
              class="a-link"
              v-for="(item, i) in menupaths_nurse"
              :key="i"
              :to="item.path"
              @click.native="$emit('click')"
              >{{ item.text }}</router-link
            >
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <!-- Menu Nurse-->

    <!-- Menu Transport -->
    <div class="menu" v-if="userdata.r_id == 8">
      <!-- <v-expansion-panels
        expandaccordion
        expand
        class="b-accod"
        v-model="panel"
      > -->
      <v-expansion-panels expandaccordion multiple class="b-accod">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <span class="panal-title T-size-20">Ground Transportation</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <router-link
              class="a-link"
              v-for="(item, i) in menupaths_Shore_Ground_Transportation"
              :key="i"
              :to="item.path"
              @click.native="$emit('click')"
              >{{ item.text }}</router-link
            >
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>

    <!-- Menu Alcohol Test -->
    <div class="menu" v-if="userdata.r_id == 7">
      <!-- <v-expansion-panels
        expandaccordion
        expand
        class="b-accod"
        v-model="panel"
      > -->
      <v-expansion-panels expandaccordion multiple class="b-accod">
        <!-- <v-expansion-panels
          expandaccordion
          expand
          class="b-accod"
          v-model="panel"
        > -->
        <v-expansion-panels expandaccordion multiple class="b-accod">
          <v-expansion-panel>
            <v-expansion-panel-header>
              <span class="panal-title T-size-20">Check-In</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <router-link
                class="a-link"
                v-for="(item, i) in menupaths_Checkin_Alcohol_Test"
                :key="i"
                :to="item.path"
                @click.native="$emit('click')"
                >{{ item.text }}</router-link
              >
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-expansion-panels>
    </div>
  </div>
</template>


<script>
import feathersClientUOA from "../plugins/feathers-client-uoa";

export default {
  name: "MenuBar",
  data: () => ({
    userdata: { o_name: "", r_id: "" },
    //Manu Admin
    menupaths_Admin: [
      {
        path: "/managemicrosoftrole",
        text: "Manage Microsoft Role",
      },
      {
        path: "/manageuser",
        text: "Manage Passenger",
      },
      {
        path: "/manageowner",
        text: "Manage Customer",
      },
      {
        path: "/managecompany",
        text: "Manage Company",
      },
      {
        path: "/managerole",
        text: "Manage Role",
      },
      {
        path: "/manageterminal",
        text: "Manage Terminal",
      },
      {
        path: "/manageairport",
        text: "Manage Route",
      },
      {
        path: "/manageflightstatus",
        text: "Manage Flight Status",
      },
      {
        path: "/managealcoholstatus",
        text: "Manage Alcohol Status",
      },
      {
        path: "/managevideofile",
        text: "Manage Video File",
      },
      {
        path: "/managepilot",
        text: "Manage Pilot",
      },
    ],
    menupaths_Admin2: [
      {
        path: "/vehicle",
        text: "Manage Vehicle",
      },
      {
        path: "/driver",
        text: "Manage Driver",
      },
      {
        path: "/managetransportlocation",
        text: "Manage Location",
      },
    ],
    menupaths_Admin3: [
      {
        path: "/managefuelstation",
        text: "Manage Fuel Station",
      },
    ],
    // Manu Shore
    menupaths_Shore_Flight_Management: [
      {
        path: "/flightdashboard",
        text: "Flight Dashboard",
      },
      {
        path: "/booking",
        text: "Flight Booking",
      },
      {
        path: "/flight",
        text: "Flight Schedule",
      },
      {
        path: "/flightlog",
        text: "Flight Log",
      },
      {
        path: "/realtimeheli",
        text: "Flight Monitor",
      }
    ],

    menupaths_Shore_Passenger_Management: [
      {
        path: "/passenger",
        text: "Passenger Biodata",
      },
      {
        path: "/expired",
        text: "Passenger Certificate Validation",
      },
      {
        path: "/passengerreport",
        text: "Passenger Report",
      },
    ],
    menupaths_Flight_Ground_Transportation: [
      {
        path: "/transport",
        text: "Booking Transport",
      },
      // {
      //   path: "/realtime",
      //   text: "Van Tracking",
      // },
    ],
    menupaths_Flight_Fuel_Management: [
      {
        path: "/fuel",
        text: "Aircraft Fueling",
      },
      {
        path: "/fuelreport",
        text: "Fuel Report",
      },
    ],
    menupaths_Shore_Ground_Transportation: [
      {
        path: "/transport",
        text: "Booking Transport",
      },
      // {
      //   path: "/realtime",
      //   text: "Van Tracking",
      // },
      // {
      //   path: "/realtimeheli",
      //   text: "Realtime Aircraft Tracking",
      // },
      // {
      //   path: "/historyheli",
      //   text: "History Aircraft Tracking",
      // },
    ],
    menupaths_Shore_Fuel_Management: [
      {
        path: "/fuel",
        text: "Aircraft Fueling",
      },
    ],
    menupaths_Shore_Customer_Flight_Report: [
      {
        path: "/dailyflightreport",
        text: "Daily Flight Report",
      },
      {
        path: "/monthlyflightreport",
        text: "Monthly Flight Report",
      },
      {
        path: "/flightdelayreport",
        text: "Delay Report",
      },
      /*
      {
        path: '/draftinvoice',
        text: 'Draft Invoice',
      },
      */
      // {
      //   path: "/workinprogress",
      //   text: "Summary Report",
      // },
      // {
      //   path: "/workinprogress",
      //   text: "End of Day Report",
      // },
      // {
      //   path: "/workinprogress",
      //   text: "Flight Delay Report",
      // },
      // {
      //   path: "/workinprogress",
      //   text: "Customer Flight Report",
      // },
      // {
      //   path: "/workinprogress",
      //   text: "Dispatch Flight Report",
      // },
      // {
      //   path: "/workinprogress",
      //   text: "Cargo On Transportation Report",
      // },
      // {
      //   path: "/workinprogress",
      //   text: "Cancelled Reservation Report",
      // },
    ],

    // Manu Ground Op
    menupaths_Ground_Op_Ground_Operation: [
      {
        path: "/booking",
        text: "Flight Booking",
      },
      {
        path: "/flight",
        text: "Flight Schedule",
      },
      {
        path: "/transport",
        text: "Booking Transport",
      },

      // {
      //   path: "/realtime",
      //   text: "Realtime Vehicle",
      // },
      {
        path: "/history",
        text: "History Vehicle",
      },
      // {
      //   path: "/workinprogress",
      //   text: "Summary Data Transportation",
      // },
    ],
    menupaths_Ground_Op_Crew_Management: [
      {
        path: "/passenger",
        text: "Passenger Biodata",
      },
      {
        path: "/expired",
        text: "Passenger Certificate Validation",
      },
      // {
      //   path: "/workinprogress",
      //   text: "Crew Biography",
      // },
      // {
      //   path: "/workinprogress",
      //   text: "Crew Schedule",
      // },
      // {
      //   path: "/workinprogress",
      //   text: "Crew Duty Time",
      // },
    ],
    menupaths_Ground_Op_Flight_Monitor: [
      {
        path: "/realtimeheli",
        text: "Flight Monitor",
      },
      // {
      //   path: "/historyheli",
      //   text: "History Aircraft Tracking",
      // },
    ],
    // menupaths_Ground_Op_Report: [
    //   {
    //     path: "/workinprogress",
    //     text: "Summary Report",
    //   },
    //   {
    //     path: "/workinprogress",
    //     text: "End of Day Report",
    //   },
    //   {
    //     path: "/workinprogress",
    //     text: "Flight Delay Report",
    //   },
    //   {
    //     path: "/workinprogress",
    //     text: "Customer Flight Report",
    //   },
    //   {
    //     path: "/workinprogress",
    //     text: "Dispatch Flight Report",
    //   },
    //   {
    //     path: "/workinprogress",
    //     text: "Cargo On Transportation Report",
    //   },
    //   {
    //     path: "/workinprogress",
    //     text: "Cancelled Reservation Report",
    //   },
    // ],
    // Manu Flight Dis
    menupaths_Flight_Dis_Flight_Management: [
      {
        path: "/performancereport",
        text: "Performance Report",
      },
      {
        path: "/totalflighttimereport",
        text: "Total Flight Time Report",
      },
      {
        path: "/flightdashboard",
        text: "Flight Dashboard",
      },
      {
        path: "/booking",
        text: "Flight Booking",
      },
      {
        path: "/flight",
        text: "Flight Schedule",
      },
      {
        path: "/flightlog",
        text: "Flight Log",
      },
      {
        path: "/realtimeheli",
        text: "Flight Monitor",
      },
      {
        path: "/realtimemonitor",
        text: "RealTime Monitor",
      },
      //ย้าย
      // {
      //   path: "/transport",
      //   text: "Booking Transport",
      // },
      // {
      //   path: "/realtime",
      //   text: "Realtime Vehicle",
      // },
      // {
      //   path: "/history",
      //   text: "History Vehicle",
      // },
    ],
    menupaths_Flight_Dis_Crew_Management: [
      {
        path: "/passenger",
        text: "Passenger Biodata",
      },
      {
        path: "/expired",
        text: "Passenger Certificate Validation",
      },
      // {
      //   path: "/workinprogress",
      //   text: "Crew Biography",
      // },
      // {
      //   path: "/scheduleselect",
      //   text: "Crew Schedule",
      // },
      // {
      //   path: "/workinprogress",
      //   text: "Crew Duty Time",
      // },
    ],
    // menupaths_Flight_Dis_Dispatch_Operations: [
    //   {
    //     path: "/workinprogress",
    //     text: "Flight Payload Calculation",
    //   },
    //   {
    //     path: "/workinprogress",
    //     text: "Flight Document Acceptance",
    //   },
    // ],
    menupaths_Flight_Dis_Flight_Monitor: [
      // {
      //   path: "/historyheli",
      //   text: "History Aircraft Tracking",
      // },
    ],
    menupaths_Flight_Dis_Report: [
      {
        path: "/flightdelayreport",
        text: "Delay Report",
      },
      {
        path: "/dailyflightreport",
        text: "Daily Flight Report",
      },
      {
        path: "/monthlyflightreport",
        text: "Monthly Flight Report",
      },
      {
        path: "/flightreport1",
        text: "UOA Flight statistic report",
      },
      {
        path: "/flightreport2",
        text: "Flight report",
      },
            {
        path: "/flightreport3",
        text: "Flight Passenger report",
      },
      /*
      {
        path: '/draftinvoice',
        text: 'Draft Invoice',
      },
      */
      // {
      //   path: "/workinprogress",
      //   text: "Summary Report",
      // },
      // {
      //   path: "/endofdayreport",
      //   text: "End of Day Report",
      // },
      // {
      //   path: "/flightdelayreport",
      //   text: "Flight Delay Report",
      // },
      // {
      //   path: "/workinprogress",
      //   text: "Customer Flight Report",
      // },
      // {
      //   path: "/workinprogress",
      //   text: "Dispatch Flight Report",
      // },
      // {
      //   path: "/workinprogress",
      //   text: "Cargo On Transportation Report",
      // },
    ],
    // menupaths_Flight_Dis_Job_Schedule: [
    //   // {
    //   //   path: "/scheduleselect",
    //   //   text: "Job Schedule By Department",
    //   // },
    //   // {
    //   //   path: "/scheduleperson",
    //   //   text: "Job Schedule By Person",
    //   // },
    // ],

    // Manu Pilot
    menupaths_Pilot_Flight_Management: [
      // {
      //   path: "/booking",
      //   text: "Booking",
      // },
      {
        path: "/flightdashboard",
        text: "Flight Dashboard",
      },
      {
        path: "/flight",
        text: "Flight Schedule",
      },
      {
        path: "/flightlog",
        text: "Flight Log",
      },
      {
        path: "/realtimeheli",
        text: "Flight Monitor",
      },
      // {
      //   path: "/TrainingTrack",
      //   text: "TrainingTrack",
      // },
    ],
    menupaths_Pilot_Crew_Management: [
      {
        path: "/crewschedule",
        text: "Crew Schedule",
      },
      {
        path: "/TrainingTrack",
        text: "TrainingTrack",
      },
    ],
    // menupaths_Pilot_Dispatch_Operations: [
    //   {
    //     path: "/workinprogress",
    //     text: "Flight Payload Calculation",
    //   },
    //   {
    //     path: "/workinprogress",
    //     text: "Flight Document Acceptance",
    //   },
    // ],
    // menupaths_Pilot_Flight_Monitor: [
    //   {
    //     path: "/realtimeheli",
    //     text: "Flight Monitor",
    //   },
    //   // {
    //   //   path: "/historyheli",
    //   //   text: "History Aircraft Tracking",
    //   // },
    // ],

    menupaths_Pilot_Flight_Monitor: [
      {
        path: "/realtimeheli",
        text: "Crew Schedule",
      },
    ],

    // menupaths_Pilot_Fuel_Management:[
    //   {
    //     path: "/fuel",
    //     text: "Aircrart Fueling",
    //   },
    // ],
    // menupaths_Pilot_Report: [
    //   {
    //     path: "/workinprogress",
    //     text: "End of Day Report",
    //   },
    //   {
    //     path: "/workinprogress",
    //     text: "Dispatch Flight Report",
    //   },
    // ],
    // Manu Engineer
    menupaths_Engineer_Flight_Management: [
      {
        path: "/flightdashboard",
        text: "Flight Dashboard",
      },
      {
        path: "/booking",
        text: "Flight Booking",
      },
      {
        path: "/flight",
        text: "Flight Schedule",
      },
    ],
    //menupaths_Engineer_Crew_Management: [
    // {
    //   path: "/workinprogress",
    //   text: "Crew Biography",
    // },
    // {
    //   path: "/scheduleselect",
    //   text: "Crew Schedule",
    // },
    // {
    //   path: "/workinprogress",
    //   text: "Crew Duty Time",
    // },
    //],
    menupaths_Engineer_Engineering: [
      {
        path: "/manageaircraft/Manage/0",
        text: "Manage Aircraft",
      },
      // {
      //   path: "/workinprogress",
      //   text: "Aircraft Documents",
      // },
      // {
      //   path: "/workinprogress",
      //   text: "Aircraft Maintenance Related",
      // },
    ],
    menupaths_Engineer_Fuel_Management: [
      {
        path: "/fuel",
        text: "Aircraft Fueling",
      },
      {
        path: "/fuelreport",
        text: "Fuel Report",
      },
    ],
    menupaths_Engineer_Flight_Monitor: [
      {
        path: "/realtimeheli",
        text: "Flight Monitor",
      },
      // {
      //   path: "/historyheli",
      //   text: "History Aircraft Tracking",
      // },
    ],
    // menupaths_Engineer_Report: [
    //   {
    //     path: "/workinprogress",
    //     text: "End of Day Report",
    //   },
    // ],
    // Manu Checkin
    menupaths_Checkin_Flight_Management: [
      {
        path: "/flightdashboard",
        text: "Flight Dashboard",
      },
      {
        path: "/flight",
        text: "Flight Schedule",
      },
    ],
    menupaths_Checkin_Crew_Management: [
      {
        path: "/passenger",
        text: "Passenger Biodata",
      },
      {
        path: "/expired",
        text: "Passenger Certificate Validation",
      },
    ],
    // menupaths_Checkin_Dispatch_Operations: [
    //   {
    //     path: "/workinprogress",
    //     text: "Flight Payload Calculation",
    //   },
    //   {
    //     path: "/workinprogress",
    //     text: "Flight Document Acceptance",
    //   },
    // ],
    menupaths_Checkin_CheckIn: [
      {
        path: "/checkinactive",
        text: "Checkin Flight Opened",
      },
      {
        path: "/checkinclosed",
        text: "Checkin Flight Closed",
      },
      {
        path: "/checkintakeoff",
        text: "Checkin Flight Departed",
      },
      {
        path: "/rfidscanner",
        text: "RFID Scanner",
      },
      // {
      //   path: "/workinprogress",
      //   text: "Checkin Alcohol Test",
      // },
      // {
      //   path: "/workinprogress",
      //   text: "Catering order",
      // },
    ],
    // menupaths_Checkin_Report: [
    //   {
    //     path: "/workinprogress",
    //     text: "End of Day Report",
    //   },
    // ],
    // Manu Base Manager
    menupaths_Base_Manager_Ground_Operation: [
      {
        path: "/flightdashboard",
        text: "Flight Dashboard",
      },
      {
        path: "/booking",
        text: "Flight Booking",
      },
      {
        path: "/flight",
        text: "Flight Schedule",
      },
      {
        path: "/transport",
        text: "Booking Transport",
      },
      // {
      //   path: "/realtime",
      //   text: "Realtime Vehicle",
      // },
      {
        path: "/history",
        text: "History Vehicle",
      },
      // {
      //   path: "/workinprogress",
      //   text: "Summary Data Transportation",
      // },
    ],
    menupaths_Base_Manager_Crew_Management: [
      {
        path: "/passenger",
        text: "Passenger Biodata",
      },
      {
        path: "/expired",
        text: "Passenger Certificate Validation",
      },
      // {
      //   path: "/workinprogress",
      //   text: "Crew Biography",
      // },
      // {
      //   path: "/scheduleselect",
      //   text: "Crew Schedule",
      // },
      // {
      //   path: "/workinprogress",
      //   text: "Crew Duty Time",
      // },
    ],
    // menupaths_Base_Manager_CheckIn: [
    //   {
    //     path: "/workinprogress",
    //     text: "Checkin Active",
    //   },
    //   {
    //     path: "/workinprogress",
    //     text: "Checkin Close",
    //   },
    //   {
    //     path: "/workinprogress",
    //     text: "Checkin Takeoff",
    //   },
    //   {
    //     path: "/workinprogress",
    //     text: "Checkin Alcohol Test",
    //   },
    //   {
    //     path: "/workinprogress",
    //     text: "Catering order",
    //   },
    // ],
    // menupaths_Base_Manager_Report: [
    //   {
    //     path: "/workinprogress",
    //     text: "Summary Report",
    //   },
    //   {
    //     path: "/workinprogress",
    //     text: "End of Day Report",
    //   },
    //   {
    //     path: "/workinprogress",
    //     text: "Flight Delay Report",
    //   },
    //   {
    //     path: "/workinprogress",
    //     text: "Customer Flight Report",
    //   },
    //   {
    //     path: "/workinprogress",
    //     text: "Dispatch Flight Report",
    //   },
    //   {
    //     path: "/workinprogress",
    //     text: "Cargo On Transportation Report",
    //   },
    //   {
    //     path: "/workinprogress",
    //     text: "Cancelled Reservation Report",
    //   },
    // ],
    // Manu Client
    menupaths_Client_Ground_Operation: [
      // {
      //   path: "/realtime",
      //   text: "Realtime Vehicle",
      // },
      {
        path: "/history",
        text: "History Vehicle",
      },
      // {
      //   path: "/workinprogress",
      //   text: "Summary Data Transportation",
      // },
    ],
    menupaths_Client_Flight_Monitor: [
      {
        path: "/realtimeheli",
        text: "Flight Monitor",
      },
      // {
      //   path: "/historyheli",
      //   text: "History Aircraft Tracking",
      // },
    ],
    // menupaths_Client_Report: [
    //   {
    //     path: "/workinprogress",
    //     text: "Summary Report",
    //   },
    //   {
    //     path: "/workinprogress",
    //     text: "End of Day Report",
    //   },
    //   {
    //     path: "/workinprogress",
    //     text: "Flight Delay Report",
    //   },
    //   {
    //     path: "/workinprogress",
    //     text: "Customer Flight Report",
    //   },
    //   {
    //     path: "/workinprogress",
    //     text: "Dispatch Flight Report",
    //   },
    //   {
    //     path: "/workinprogress",
    //     text: "Cargo On Transportation Report",
    //   },
    //   {
    //     path: "/workinprogress",
    //     text: "Cancelled Reservation Report",
    //   },
    // ],
    // Manu Base Manager
    menupaths_Base_Ground_Operation: [
      {
        path: "/flightdashboard",
        text: "Flight Dashboard",
      },
      {
        path: "/flightlog",
        text: "Flight Report",
      },
    ],
    // Manu Financial Accounting
    menupaths_Financial_Flight_Management: [
      {
        path: "/flightdashboard",
        text: "Flight Dashboard",
      },
      {
        path: "/flight",
        text: "Flight Schedule",
      },
      {
        path: "/flightlog",
        text: "Flight Report",
      },
    ],
    menupaths_Financial_Finance: [
      {
        path: "/feedaily",
        text: "Fee Daily",
      },
      {
        path: "/monthlychange",
        text: "Monthly Charge",
      },
    ],
    menupaths_Financial_Customer_Flight_Report: [
      {
        path: "/dailyflightreport",
        text: "Daily Flight Report",
      },
      {
        path: "/monthlyflightreport",
        text: "Monthly Flight Report",
      },
      {
        path: "/flightdelayreport",
        text: "Delay Report",
      },
    ],

    menupaths_Flight_Dis_Alcohol_Check_And_Time_Attendance: [
      {
        path: "/alcoholcheckandtimeattendance",
        text: "Alcohol Check & Time Attendance",
      },
    ],

    menupaths_Checkin_Alcohol_Test: [
      {
        path: "/checkinactive",
        text: "Checkin Flight Opened",
      },
    ],

    menupaths_Engineer_Management: [
      {
        path: "/EngineerSchedule",
        text: "Engineer Schedule",
      },
    ],

    menupaths_nurse: [
      {
        path: "/mubadala",
        text: "Valeura Form ",
      },
    ],
  }),
  async mounted() {
    this.userdata = this.$CurrentUser.GetCurrentUser();

    // if (this.userdata.r_id == 2 && this.userdata.o_id == 4) {
    //   this.menupaths_Shore_Flight_Management.push({
    //     path: "/mubadala",
    //     text: "Mubadala Form",
    //   });
    // }
  },
  methods: {},
};
</script>

