import axios from "axios";
import { forEach } from "jszip";
let key = process.env.VUE_APP_SEQ_LOG_API_KEY;
let url = process.env.VUE_APP_SEQ_LOG + "&apiKey=" + key;
let headers = { 'X-Seq-ApiKey': key };
// let headers = { };

export const log = {
    Info: (template, property) => {
        try {
            let body = {
                "@t": new Date().toISOString(),
                "@l": "Information",
                "@mt": template,
                ...property
            };
            axios.post(url, body, { headers });
        } catch (error) {
            console.log(error)
        }
    },
    Error: (template, property) => {
        try {
            let body = {
                "@t": new Date().toISOString(),
                "@l": "Error",
                "@mt": template,
                ...property
            };
            axios.post(url, body, { headers });
        } catch (error) {
            console.log(error)
        }
    },
    Debug: (template, property) => {
        try {
            let body = {
                "@t": new Date().toISOString(),
                "@l": "Debug",
                "@mt": template,
                ...property
            };
            axios.post(url, body, { headers });
        } catch (error) {
            console.log(error)
        }
    },
}